import { HttpClient } from '@angular/common/http';
import { ServiceWorkerModule } from '@angular/service-worker';
import { TranslateModule, TranslateLoader, TranslateService } from '@ngx-translate/core';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { EffectsModule } from '@ngrx/effects';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { MonacoEditorModule } from 'ngx-monaco-editor-v2';

import { AppRoutingModule } from 'src/app/app-routing.module';
import { SharedModule } from 'src/app/_shared/shared.module';
import { AppComponent } from 'src/app/app.component';
// eslint-disable-next-line no-restricted-imports
import packageJson from '../../package.json';
import { InitProvider } from 'src/app/_shared/provider/init.provider';
import { HierarchyEffects } from 'src/app/_store/_hierarchy/effects/hierarchy.effects';
import { metaReducers, reducers } from 'src/app/_store/_reducers';
import { ChannelsEffects } from 'src/app/_store/_channels/effects/channels.effects';
import { DrivescanDashboardEffects } from 'src/app/_store/_drivescan-dashboard/effects/drivescan-dashboard.effects';
import { UserEffects } from 'src/app/_store/_user/effects/user.effects';
import { InitEffects } from 'src/app/_store/_init/effects/init.effects';
import { ChartsEffects } from 'src/app/_store/_charts/effects/charts.effects';
import { RangeSelectorEffects } from 'src/app/_store/_range-selector/effects/range-selector.effects';
import { RecommendationEffects } from 'src/app/_store/_recommendation/effects/recommendation.effects';
import { environment } from 'src/environments/environment';
import { AuthEffects } from 'src/app/_store/_auth/effects/auth.effects';
import { LayoutModule } from 'src/app/layout/layout.module';
import { MaintenanceEffects } from 'src/app/_store/_maintenance/effects/maintenance.effects';
import { DocumentModule } from 'src/app/_shared/modules/document/document.module';
import { QubescanDashboardEffects } from 'src/app/_store/_qubescan-dashboard/effects/qubescan-dashboard.effects';
import { MapEffects } from 'src/app/_store/_map/effects/map.effects';
import { NewsEffects } from 'src/app/_store/_news/effects/news.effects';
import { GlobalErrorHandler } from 'src/app/_shared/services/global-error-handler.service';
import { RolesEffects } from 'src/app/_store/_roles/effects/roles.effects';
import { UpdateService } from 'src/app/_shared/services/update.service';
import { TemporaryMaintenanceComponent } from './temporary-maintenance/temporary-maintenance.component';
import { FeaturesEffects } from 'src/app/_store/_features/effects/features.effect';

export function createTranslateLoader(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json?no-cache=' + packageJson.version);
}

export function initProviderFactory(provider: InitProvider): () => Promise<unknown> {
  return (): Promise<unknown> => provider.load();
}

@NgModule({
  declarations: [AppComponent, TemporaryMaintenanceComponent],
  imports: [
    BrowserAnimationsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    LayoutModule,
    AppRoutingModule,
    SharedModule.forRoot(),
    StoreModule.forRoot(reducers, { metaReducers }),
    StoreDevtoolsModule.instrument({
      name: 'NgRx In Site Store App',
    connectInZone: true}),
    DocumentModule,
    EffectsModule.forRoot([
      ChannelsEffects,
      DrivescanDashboardEffects,
      HierarchyEffects,
      UserEffects,
      InitEffects,
      ChartsEffects,
      RecommendationEffects,
      AuthEffects,
      MaintenanceEffects,
      QubescanDashboardEffects,
      MapEffects,
      NewsEffects,
      RolesEffects,
      RangeSelectorEffects,
      FeaturesEffects,
    ]),
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.pwa }),
    MonacoEditorModule.forRoot(),
  ],
  providers: [
    TranslateService,
    InitProvider,
    UpdateService,
    {
      provide: APP_INITIALIZER,
      useFactory: initProviderFactory,
      deps: [InitProvider],
      multi: true,
    },
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
