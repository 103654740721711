import { Constructor } from '@angular/cdk/table';
import { PipeTransform } from '@angular/core';

/**
 * class mixin to wrap a static function into a angular pipe
 */
export function WrapperPipeMixin(func: (...params: any[]) => any): Constructor<PipeTransform> {
  return class implements PipeTransform {
    transform(...params: any[]): any {
      return func(...params);
    }
  };
}
