import { AgGridModule } from 'ag-grid-angular';
import { NgModule } from '@angular/core';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { LeafletMarkerClusterModule } from '@asymmetrik/ngx-leaflet-markercluster';
import { NgxMatDatetimePickerModule, NgxMatTimepickerModule } from '@angular-material-components/datetime-picker';
import { NgxMatMomentModule } from '@angular-material-components/moment-adapter';
import { FormsModule } from '@angular/forms';

import { MatModule } from 'src/app/_shared/mat.module';
import { SiteMpDialogComponent } from 'src/app/_shared/components/site-mp-dialog/site-mp-dialog.component';
import { ReplacePqubeComponent } from 'src/app/_shared/components/site-mp-dialog/replace-pqube/replace-pqube.component';
import { MpAccessComponent } from 'src/app/_shared/components/site-mp-dialog/mp-access/mp-access.component';
import { MoveToComponent } from 'src/app/_shared/components/site-mp-dialog/move-to/move-to.component';
import { MapDialogComponent } from 'src/app/_shared/components/site-mp-dialog/map-dialog/map-dialog.component';
import { SiteMpParametersTableComponent } from 'src/app/_shared/components/site-mp-dialog/site-mp-parameters-table/site-mp-parameters-table.component';
import { SharedModule } from 'src/app/_shared/shared.module';
import { TinyEditorModule } from 'src/app/_shared/editor/editor.module';
import { DragDropZoneModule } from 'src/app/_shared/components/drag-drop-zone/drag-drop-zone.module';
import { AgExtraModule } from 'src/app/_shared/ag/ag-extra-component.module';

@NgModule({
  imports: [
    FormsModule,
    AgGridModule,
    AgExtraModule,
    MatModule,
    SharedModule,
    TinyEditorModule,
    LeafletModule,
    LeafletMarkerClusterModule,
    NgxMatDatetimePickerModule,
    NgxMatTimepickerModule,
    NgxMatMomentModule,
    DragDropZoneModule,
  ],
  exports: [
    SiteMpDialogComponent,
    ReplacePqubeComponent,
    MoveToComponent,
    MapDialogComponent,
    SiteMpParametersTableComponent,
    MpAccessComponent,
  ],
  declarations: [
    SiteMpDialogComponent,
    ReplacePqubeComponent,
    MoveToComponent,
    MapDialogComponent,
    SiteMpParametersTableComponent,
    MpAccessComponent,
  ],
  providers: [],
})
export class SiteMpDialogModule {}
