import { Store } from '@ngrx/store';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { Observable, forkJoin } from 'rxjs';
import { map, take } from 'rxjs/operators';

import * as fromStore from 'src/app/_store/_reducers';
import { SubscriptionService } from 'src/app/_shared/services/subscription.service';

@Injectable()
export class FirmwareUpdateGuard {
  constructor(
    private store: Store<fromStore.State>,
    private router: Router,
    private subscriptionService: SubscriptionService
  ) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    return forkJoin([
      this.store.select((state) => state.user.account.id).pipe(take(1)),
      this.store.select(fromStore.getFeatureFirmwareUpdate).pipe(take(1)),
    ]).pipe(
      map(([accountId, feature]) => {
        if (feature.enabled === true) {
          return true;
        } else {
          this.router.navigate(['/', 'fleet']);
          this.subscriptionService.subscribeAlert(accountId);
          return false;
        }
      })
    );
  }
}
