import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { switchMap, map, take, catchError } from 'rxjs/operators';
import { of } from 'rxjs';

import * as fromStore from 'src/app/_store/_reducers';
import { NewsService } from 'src/app/_shared/services';
import { NewsActions } from 'src/app/_store/_news/actions';
import { News } from 'src/app/_shared/interface/news.interface';
import { Store } from '@ngrx/store';

@Injectable()
export class NewsEffects {
  initNews$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NewsActions.initNews.type),
      switchMap(() => {
        return this.newsService.getNews().pipe(
          map((news) => {
            return NewsActions.setNewsSuccess({
              payload: {
                id: news.id,
                content: news.content,
              },
            });
          }),
          catchError((error) => of(NewsActions.setNewsFail({ error })))
        );
      })
    )
  );

  setNews$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NewsActions.setNews.type),
      switchMap((state) =>
        this.store.select(fromStore.getNews).pipe(
          take(1),
          map((requests) => [requests, state.payload.content])
        )
      ),
      switchMap(([requests, content]: [News, string]) => {
        const news: News = { id: requests.id, content: content };
        return this.newsService.updateNews(news).pipe(
          map((newsResult) => {
            return NewsActions.setNewsSuccess({
              payload: {
                id: newsResult.id,
                content: content,
              },
            });
          })
        );
      })
    )
  );

  addNews$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NewsActions.addNews.type),
      switchMap((action) => {
        return this.newsService.addNews(action.payload.content).pipe(
          map((news) => {
            return NewsActions.setNewsSuccess({
              payload: {
                id: news.id,
                content: action.payload.content,
              },
            });
          })
        );
      })
    )
  );

  constructor(
    private actions$: Actions<NewsActions.NewsActionsUnion>,
    private store: Store,
    private newsService: NewsService
  ) {}
}
