import * as moment from 'moment';

export function convertMomentToChartFormat(format: string): string {
  const mapReplace = {
    YYYY: '%Y',
    MMMM: '%B',
    dddd: '%A',
    DD: '%d',
    HH: '%k',
    YY: '%y',
    MM: '%m',
    D: '%e',
    H: '%I',
    h: '%l',
    A: '%p',
    mm: '%M',
    SSS: '%L',
    ss: '%S',
  };

  var re = new RegExp(Object.keys(mapReplace).join('|'), 'gi');
  return format.replace(re, function (matched) {
    return mapReplace[matched];
  });
}

export function convertMomentToChartFormatGeneric(...args: any[]): string {
  let finalFormat = '';
  for (const format of args) {
    if (format !== ' ') {
      finalFormat = finalFormat + moment().creationData().locale.longDateFormat(format);
    } else {
      finalFormat = finalFormat + format;
    }
  }

  return convertMomentToChartFormat(finalFormat);
}
