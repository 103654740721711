import { Injectable } from '@angular/core';
import { Moment } from 'moment';
import { Observable, forkJoin, BehaviorSubject, from } from 'rxjs';
import { Store } from '@ngrx/store';
import { map, switchMap, take, tap } from 'rxjs/operators';
import * as moment from 'moment';

import { DiligentApiService } from 'src/app/_shared/services/diligent-api.service';
import { MeasurementPoint } from 'src/app/_shared/classes/MeasurementPoint';
import { IMeasurementPoint } from 'src/app/_shared/classes/measurementpoint.interface';
import { SitesService } from 'src/app/_shared/services/sites.service';
import { DrivescanDashboard } from 'src/app/_shared/interface/drivescan/drivescan-dashboard.interface';
import {
  AlarmEventNoteRequest,
  AlarmEventNoteResponse,
} from 'src/app/_shared/interface/alarm/alarm-event-note-request.interface';
import { EventCount } from 'src/app/_shared/interface/events/event-count.interface';
import { DrivescanValue } from 'src/app/_shared/interface/drivescan/drivescan-value.interface';
import { DrivescanInfo } from 'src/app/_shared/interface/drivescan/drivescan-info.interface';
import { DrivescanParameters } from 'src/app/_shared/interface/drivescan/drivescan-parameters.interface';
import { Alarm } from 'src/app/_shared/interface/alarm/alarm.interface';
import { ClearAlarmReturn } from 'src/app/_shared/interface/alarm/clear-alarm-return.interface';
import { UnauthorizedService } from 'src/app/_shared/services/unauthorized.service';
import { MessageReturn } from 'src/app/_shared/interface/message-return.interface';
import { PaginatedModel } from 'src/app/_shared/interface/pagination.interface';
import { FilterTypeRequest } from 'src/app/_shared/interface/filter-type-request.interface';
import { EventFilterType } from 'src/app/_shared/interface/events/event-filter-type.interface';
import { MpParameters } from 'src/app/_shared/interface/mp-parameter';
import { DriveFaultState } from 'src/app/_shared/interface/drivescan/drive-fault-code.interface';
import * as fromUser from 'src/app/_store/_reducers';
import { AuthService } from 'src/app/_shared/services/auth.service';
import { ChannelsActions } from 'src/app/_store/_channels/actions';
import { EventFile } from 'src/app/_shared/interface/events/event-files.interface';
import {
  ComponentWithOnDestroyObservable,
  untilComponentDestroyed,
} from 'src/app/_shared/classes/component-destroy.class';
import { SocketService } from 'src/app/_shared/services/socket.service';
import { QubescanDashboardActions } from 'src/app/_store/_qubescan-dashboard/actions';
import { UserActions } from 'src/app/_store/_user/actions';
import { TrendsRequest } from 'src/app/_shared/interface/trends-request.interface';
import { Loads } from 'src/app/_shared/interface/loads.interface';
import { LoadsActions } from 'src/app/_store/_loads/actions';
import { EventViewer } from 'src/app/_shared/interface/events/event-viewer.interface';
import { MoveToList } from 'src/app/_shared/interface/move-to-list.interface';
import { ReplacementStep } from 'src/app/_shared/interface/replacement-step.interface';
import { SubscriptionData } from 'src/app/_shared/interface/subscription-data.interface';
import { MaxLoadCurrentDemand } from 'src/app/_shared/interface/max-load-current-demand';
import { Heartbeat } from 'src/app/_shared/interface/drivescan/heartbeat.interface';

@Injectable({
  providedIn: 'root',
})
export class MeasurementPointsService {
  private mSelectedMeasurementPoint: MeasurementPoint;

  private _mqttClient$ = new BehaviorSubject<any>(null);

  get mqttClient(): Observable<any> {
    return this._mqttClient$.asObservable();
  }

  get selectedMeasurementPoint(): MeasurementPoint {
    return this.mSelectedMeasurementPoint;
  }

  constructor(
    private diligentService: DiligentApiService,
    private sitesService: SitesService,
    private authService: AuthService,
    private store: Store<fromUser.State>,
    private unauthorizedService: UnauthorizedService,
    private socketService: SocketService
  ) {}

  changeSelection(
    point: any,
    redirect = '/dashboard',
    onBoarding = { isOnBoarding: false, timestamp: null }
  ): Observable<any> {
    return this.store.select(fromUser.getUserPreferences).pipe(
      take(1),
      map((userPrefs) => ({ ...userPrefs, account: point.accountId, mpId: point.measurementPointId })),
      switchMap((userPrefs) => {
        userPrefs = {
          ...userPrefs,
          onBoarding: {
            isOnBoarding: onBoarding.isOnBoarding,
            timestamp: onBoarding.timestamp,
          },
        };
        this.authService.savePreferences(userPrefs, Object.keys(userPrefs).length > 0);

        this.store.dispatch(LoadsActions.updateLoads({ payload: { loads: [0] } }));
        this.store.dispatch(QubescanDashboardActions.getKPI({ payload: { mpId: point.measurementPointId } }));
        this.store.dispatch(UserActions.setAlarmChannel({ payload: { mpId: point.measurementPointId } }));
        return forkJoin([
          this.getChannelDefinition(point.measurementPointId.toString(), 'live', point.measurementPointTypeId).pipe(
            take(1)
          ),
          this.getChannelDefinition(point.measurementPointId.toString(), 'trend', point.measurementPointTypeId).pipe(
            take(1)
          ),
        ]);
      }),
      tap(([responseMeter, responseTrends]) => {
        if (point.measurementPointTypeId === 1) {
          this.store.dispatch(
            UserActions.setQubescanTypeModel({
              payload: {
                type: responseMeter.powerConfiguration ? responseMeter.powerConfiguration : null,
                model: responseMeter.pqubeModel ? responseMeter.pqubeModel : null,
              },
            })
          );
        }
        this.store.dispatch(
          ChannelsActions.setMeterChannelsSuccess({
            payload: {
              meterChannels: responseMeter.channels,
            },
          })
        );
        this.store.dispatch(
          ChannelsActions.setTrendsChannelsSuccess({
            payload: {
              trendsChannels: responseTrends.channels,
              mp: new MeasurementPoint(point),
              returnUrl: redirect + '?account=' + point.accountId + '&mpId=' + point.measurementPointId,
            },
          })
        );
      })
    );
  }

  getMeasurementPoints(accountId: number): Observable<IMeasurementPoint[]> {
    // we do this because the old way (diligentService.getMeasurementPoints) is
    // paginated without any indication of whether there are more results, which
    // would have been a nightmare to work with. so, we use the 'getSites' function
    // which will return all sites/customers/MPs that a user has access to. we
    // then extract the MPs from those results and stuff the "measurementPointId"
    // into the "roomId" field so upstream things will continue to work.
    return from(
      new Promise(async (resolve) => {
        // get all the sites a user has access to
        const sites: any = await this.sitesService
          .getSites(accountId)
          .toPromise()
          .catch((err) => {
            this.unauthorizedService.notifyWrongIdAccount().subscribe(() => {});
          });
        let mps = [];

        if (sites.partners) {
          // pull the MPs out of the partners/customers
          for (const partner of sites.partners) {
            for (const customer of partner.customers) {
              mps = mps.concat(
                customer.measurementPoints.map((mp) => {
                  // make the `roomId` match the `measurementPointId` because a lot
                  // of other places use the `roomId` field
                  mp.roomId = mp.measurementPointId;
                  return mp;
                })
              );
            }
          }
        }

        // finally, we can hand back measurement points
        resolve(mps);
      })
    ).pipe(map((r) => r as IMeasurementPoint[]));
  }

  async getMeasurementPointEvents(
    mpId: number,
    accountId: number,
    startDate: Moment = null,
    endDate: Moment = null,
    deviceEventTypeId: any = null,
    severeOnly: boolean = false,
    includeRetired: boolean = false,
    offset: number = 0,
    count: number = 20
  ): Promise<any> {
    return this.diligentService.getMeasurementPointEvents(
      mpId,
      accountId,
      startDate,
      endDate,
      deviceEventTypeId,
      severeOnly,
      includeRetired,
      offset,
      count
    );
  }

  getAlarmsEventsNotes(
    alarmEventNoteRequest: AlarmEventNoteRequest
  ): Observable<PaginatedModel<AlarmEventNoteResponse>> {
    return this.diligentService.getAlarmsEventsNotes(alarmEventNoteRequest);
  }

  getAlarmsEventsNotesSubscription(alarmEventNoteRequest: AlarmEventNoteRequest): Observable<SubscriptionData> {
    return this.diligentService.getAlarmsEventsNotesSubscription(alarmEventNoteRequest);
  }

  getAlarmsEventsNote(nature: string, id: string): Observable<AlarmEventNoteResponse> {
    return this.diligentService.getAlarmsEventsNote(nature, id);
  }

  getEventsData(
    mpId: string,
    accountId: string,
    startDate: Moment,
    endDate: Moment,
    eventTypeId = -1,
    severeOnly?: boolean
  ): Observable<any> {
    return this.diligentService.getEventsData(mpId, accountId, startDate, endDate, eventTypeId, 10000, severeOnly);
  }

  getEventImages(id: number, ttl: number = 300): Promise<Array<string>> {
    return new Promise(async (resolve, reject) => {
      const eventImages = await this.diligentService.getEventImages(id);
      const promises: Array<string> = eventImages.map((i: any) =>
        this.diligentService.getDocumentDownload(i.documentId, ttl)
      );

      Promise.all(promises)
        .then((images) => {
          // make sure the images display with RMS first and Waveform last
          images.sort((a: string, b: string) => {
            if (/_RMS\./.test(a)) {
              return -1;
            }

            if (/_Waveform\./.test(a)) {
              return 1;
            }

            return 0;
          });
          resolve(images);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  getEventFiles(eventId: number, type: Array<string>): Observable<Array<EventFile>> {
    return this.diligentService.getEventFiles(eventId, type);
  }

  countByAccount(accountId: number): Observable<any> {
    return this.diligentService.countMeasurementPointsByAccount(accountId);
  }

  getMeasurementPoint(mpId: number): Observable<any> {
    return this.diligentService.getMeasurementPoint(mpId);
  }

  getChannelDefinition(mpId: string, type: string, mpTypeId: number, groups?: any): Observable<any> {
    return this.diligentService.getChannelDefinition(mpId, type, mpTypeId, groups);
  }

  getLastMpTrends(
    mpId: string,
    channels: Array<string>,
    table: string
  ): Observable<DrivescanValue | DrivescanDashboard> {
    return this.diligentService.getLastMpTrends(mpId, channels, table);
  }

  getEventsCount(mpId: string, eventList: Array<string>): Observable<Array<EventCount>> {
    return this.diligentService.getEventsCount(mpId, eventList);
  }

  getDrivescanInfo(mpId: string): Observable<DrivescanInfo> {
    return this.diligentService.getDrivescanInfo(mpId);
  }

  getDriveParameters(mpId: string): Observable<DrivescanParameters> {
    return this.diligentService.getDriveParameters(mpId);
  }

  getDriveFaultState(mpId: number): Observable<DriveFaultState> {
    return this.diligentService.getDriveFaultState(mpId);
  }

  getCloudAlarms(mpId: string, startDate: Moment, endDate: Moment): Observable<Array<Alarm>> {
    return this.diligentService.getCloudAlarms(mpId, startDate, endDate);
  }

  clearSingleCloudAlarm(alarmId: string, alarm): Observable<ClearAlarmReturn> {
    return this.diligentService.clearSingleCloudAlarm(alarmId, alarm);
  }

  clearAllCloudAlarm(mpId: string, alarms): Observable<ClearAlarmReturn> {
    return this.diligentService.clearAllCloudAlarm(mpId, alarms);
  }

  clearTypeCloudAlarm(mpId: string, channel: string, alarm): Observable<any> {
    return this.diligentService.clearTypeCloudAlarm(mpId, channel, alarm);
  }

  clearEvent(mpId: string, event, eventId?: string, eventTypeId?: string): Observable<MessageReturn> {
    return this.diligentService.clearEvent(mpId, event, eventId, eventTypeId);
  }

  clearAllTypeEvent(mpId: string, eventTypeId: string, event): Observable<any> {
    return this.diligentService.clearAllTypeEvent(mpId, eventTypeId, event);
  }

  getFiltersTypesPerDate(filterTypeRequest: FilterTypeRequest): Observable<EventFilterType> {
    return this.diligentService.getFiltersTypesPerDate(filterTypeRequest);
  }

  associateDeviceWithToken(measurementPointId: number, associateToken: string): Observable<void> {
    return this.diligentService.associateDeviceWithToken(measurementPointId, associateToken);
  }

  disassociateDevice(measurementPointId: number): Observable<MessageReturn> {
    return this.diligentService.disassociateDevice(measurementPointId);
  }

  getMpParameters(measurementPointId: number): Observable<MpParameters> {
    return this.diligentService.getMpParameters(measurementPointId);
  }

  saveMpParameters(parameters: MpParameters): Observable<MpParameters> {
    return this.diligentService.saveMpParameters(parameters);
  }

  updateCommissioningDate(mpId: number, date: moment.Moment): Observable<MessageReturn> {
    return this.diligentService.updateCommissioningDate(mpId, date);
  }

  getKPI(mpId: number): Observable<any> {
    return this.diligentService.getKPI(mpId);
  }

  channelDefAvailableMqtt(component: ComponentWithOnDestroyObservable, mpId: number): Observable<any> {
    return this.socketService.getClient<any>().pipe(
      switchMap((client) => {
        client.addTopic(`cloud/${mpId}/channelDefinition/available`);
        return client.observable;
      }),
      untilComponentDestroyed(component)
    );
  }

  registrationSuccess(component: ComponentWithOnDestroyObservable, mpId: number): Observable<any> {
    return this.socketService.getClient<any>().pipe(
      switchMap((client) => {
        client.addTopic(`cloud/${mpId}/registration/response`);
        this._mqttClient$.next(client);
        return client.observable;
      }),
      untilComponentDestroyed(component)
    );
  }

  retryAssociation(mpId: number): Observable<any> {
    return this.diligentService.retryAssociation(mpId);
  }

  downloadTrends(downloadForm: TrendsRequest, mpId: string): Observable<string> {
    return this.diligentService.downloadTrends(downloadForm, mpId);
  }

  deleteMp(mpId: number): Observable<MessageReturn> {
    return this.diligentService.deleteMp(mpId);
  }

  getLoads(mpId: number): Observable<Loads> {
    return this.diligentService.getLoads(mpId);
  }

  getWaveformGroup(mpId: number): Observable<any> {
    return this.diligentService.getWaveformGroups(mpId);
  }

  getWaveformData(eventId: number): Observable<{ rms: EventViewer; waveform: EventViewer }> {
    return this.diligentService.getWaveformData(eventId);
  }

  captureSagDirection(eventId: number, direction: string): Observable<MessageReturn> {
    return this.diligentService.addSagDirection(eventId, direction);
  }

  getDeviceConfig(mpId: number, section: string, parameter: string): Observable<any> {
    return this.diligentService.getDeviceConfig(mpId, section, parameter);
  }

  getHFEmissionsFiles(mpId: number, startDate?, endDate?): Observable<any> {
    return this.diligentService.getHFEmissionsFiles(mpId, startDate, endDate);
  }

  getMoveMpList(mpId: number): Observable<Array<MoveToList>> {
    return this.diligentService.getMoveMpList(mpId);
  }

  moveMp(sourceMpId: number, targetAccountId: number): Observable<IMeasurementPoint> {
    return this.diligentService.moveMp(sourceMpId, targetAccountId);
  }

  startReplacement(mpId: number): Observable<ReplacementStep> {
    return this.diligentService.startReplacement(mpId);
  }

  completeStep(replacementId: number, step: number): Observable<ReplacementStep> {
    return this.diligentService.completeStep(replacementId, step);
  }

  getReplacementStep(mpId: number): Observable<Array<ReplacementStep>> {
    return this.diligentService.getReplacementStep(mpId);
  }

  updateLabelOnEvent(
    nature: string,
    eventId: number,
    label: string,
    isClear: boolean
  ): Observable<{ labelId: number }> {
    return this.diligentService.updateLabelOnEvent(nature, eventId, label, isClear);
  }

  getAssociatedLabelToAccount(accountId): Observable<Array<{ id: number; name: string }>> {
    return this.diligentService.getAssociatedLabelToAccount(accountId);
  }

  updateCommentOnEvent(nature: string, eventId: number, comment: string): Observable<{ status: string }> {
    return this.diligentService.updateCommentOnEvent(nature, eventId, comment);
  }

  getMaxLoadCurrentDemand(mpId: number, reportStarDate?: string): Observable<MaxLoadCurrentDemand> {
    return this.diligentService.getMaxLoadCurrentDemand(mpId, reportStarDate);
  }

  getHeartbeat(mpId: number, limit?: number): Observable<Array<Heartbeat>> {
    return this.diligentService.getHeartbeat(mpId, limit);
  }
}
