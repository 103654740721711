import { Directive, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { AgGridAngular } from 'ag-grid-angular';
import { fromEvent, Subscription } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';

@Directive({
	selector: 'ag-grid-angular [appAgUnFocus]'
})
export class AgGridUnFocusDirective implements OnInit, OnDestroy {

	subMouse: Subscription;

	constructor(
		private agGrid: AgGridAngular,
		private elementRef: ElementRef<HTMLElement>,
	) {}

	ngOnInit(): void {
		this.subMouse = this.agGrid.gridReady.asObservable().pipe(
			take(1),
			switchMap(({api}) => 
				fromEvent(this.elementRef.nativeElement, 'mouseleave').pipe(
					map(() => api.clearFocusedCell())
				)
			),
		).subscribe(() => {})
	}

	ngOnDestroy(): void {
		this.subMouse?.unsubscribe();
	}

}