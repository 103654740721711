import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'channelName',
})
export class ChannelNamePipe implements PipeTransform {
  transform(value: string, ...args: any[]): string {
    if (value.length > 32) {
      return value.substring(0, 32) + '...';
    } else {
      return value;
    }
  }
}
