import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';

@Component({
  selector: 'app-drag-drop-zone',
  templateUrl: './drag-drop-zone.component.html',
  styleUrls: ['./drag-drop-zone.component.scss'],
})
export class DragDropZoneComponent {
  @Output() fileDownload = new EventEmitter<any>();
  @Input() resourceLabel: string;
  @Input() disabled: boolean;
  @Input() viewerMode: boolean;
  @ViewChild('fileInputRef') inputFile: ElementRef;

  files: Array<any> = [];

  constructor() {}

  fileBrowseHandler(files): void {
    this.prepareFilesList(files);
  }

  onFileDropped(files): void {
    this.prepareFilesList(files);
  }

  removeFiles(): void {
    this.files = [];
    this.inputFile.nativeElement.value = '';
  }

  private prepareFilesList(files: Array<any>): void {
    for (const item of files) {
      this.files.push(item);
    }
    this.fileDownload.emit(this.files);
  }
}
