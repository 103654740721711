import {
  Component,
  OnInit,
  Input,
  EventEmitter,
  Output,
  OnDestroy,
  ChangeDetectorRef,
  HostListener,
} from '@angular/core';
import { select, Store } from '@ngrx/store';
import * as moment from 'moment';

import { OnDestroyMixin, untilComponentDestroyed } from 'src/app/_shared/classes/component-destroy.class';
import { GraphManagerService } from 'src/app/_shared/services/graph-manager.service';
import { ChartsActions } from 'src/app/_store/_charts/actions';
import * as fromCharts from 'src/app/_store/_reducers';

@Component({
  selector: 'app-d-chart-navigator',
  templateUrl: './d-chart-navigator.component.html',
  styleUrls: ['./d-chart-navigator.component.scss'],
})
export class DChartNavigatorComponent extends OnDestroyMixin implements OnDestroy, OnInit {
  @Input() timezone: string;
  @Input() currentChartSetName: string;
  @Input() currentScope: moment.unitOfTime.DurationConstructor;
  @Input() absoluteStartDate: moment.Moment;
  @Input() type: string;
  @Input() isQubeScan: boolean;
  @Input() isInsite: boolean;
  @Input() haveFutur: boolean;
  @Input() havePast: boolean;
  @Input() date: moment.Moment;
  @Input() scopeDisabled: boolean;

  @Output() sendUpdateFlag = new EventEmitter();
  @Output() scopeChange = new EventEmitter();
  @Output() haveFuturChange = new EventEmitter();

  beginningOfCreation = false;
  title: string;
  smallScreen: boolean = false;

  set absoluteStartData(newValue) {
    this.checkForBeginningOfCreation();
    if (this.type === 'energy-power') {
      this.currentScope = 'days';
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(): void {
    this.smallScreen = window.innerWidth < 992;
  }

  constructor(
    private graphManager: GraphManagerService,
    private store: Store<fromCharts.State>,
    private changeDetectorRef: ChangeDetectorRef
  ) {
    super();
  }

  ngOnInit(): void {
    this.onResize();
    if (this.type !== 'dashboard') {
      this.store
        .pipe(select(fromCharts.getChartsState))
        .pipe(untilComponentDestroyed(this))
        .subscribe((charts) => {
          if (this.currentScope !== charts.scope || this.title != charts.title) {
            if (this.type === 'hf-emissions' && this.smallScreen) {
              this.title = moment(charts.startDate).format('MMM D YYYY');
            } else {
              this.title = charts.title;
            }
            this.currentScope = charts.scope;
            this.changeDetectorRef.detectChanges();
          }
        });
    }
    this.checkForBeginningOfCreation();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  checkForBeginningOfCreation(): void {
    if (this.type === 'dashboard' || this.type === 'trends') {
      if (this.graphManager.dateRangeStart.valueOf() <= this.absoluteStartDate.valueOf()) {
        this.beginningOfCreation = true;
      } else {
        this.beginningOfCreation = false;
      }
    }
  }

  changeTimeScope(newScope: moment.unitOfTime.DurationConstructor, granularity: string): void {
    if (this.currentScope === newScope) {
      return;
    } else {
      this.currentScope = newScope;
    }
    if (this.type === 'dashboard') {
      this.graphManager.updateGranularity(granularity, newScope, 1);
      this.graphManager.dateRangeEnd = this.graphManager.dateRangeEnd;

      this.checkForBeginningOfCreation();

      this.emitFullReplacementChartSet();
    } else {
      if (newScope !== 'hours') {
        this.store.dispatch(
          ChartsActions.setScope({
            payload: {
              scope: newScope,
            },
          })
        );
      }
    }
  }

  changeTimePosition(directionOfChange: string): void {
    if (this.type === 'dashboard') {
      if (directionOfChange === 'past') {
        this.graphManager.dateRangeEnd = this.graphManager.dateRangeStart;
        this.haveFutur = true;
        this.haveFuturChange.emit(this.haveFutur);
      } else if (
        this.graphManager.dateRangeEnd.clone().add(1, this.currentScope).add(1, 'minutes').valueOf() <
        moment().valueOf()
      ) {
        this.graphManager.dateRangeEnd = this.graphManager.dateRangeEnd.clone().add(1, this.currentScope);
      } else {
        this.haveFutur = false;
        this.haveFuturChange.emit(this.haveFutur);
        this.graphManager.dateRangeEnd = moment();
      }

      this.checkForBeginningOfCreation();

      this.emitFullReplacementChartSet();
    } else {
      this.store.dispatch(
        ChartsActions.changeInterval({
          payload: {
            direction: directionOfChange,
          },
        })
      );
    }
  }

  emitFullReplacementChartSet(): void {
    if (this.type === 'dashboard') {
      this.scopeChange.emit({ change: { chartReady: false, scope: this.currentScope } });
      this.store.dispatch(
        ChartsActions.setEvents({
          payload: {
            start: this.graphManager.dateRangeStart.format('L LT').toString(),
            end: this.graphManager.dateRangeEnd.format('L LT').toString(),
            natures: ['event', 'note', 'status'],
            chartName: this.currentChartSetName,
            isQubescan: this.isQubeScan,
            isInsite: this.isInsite,
            from: 'dashboard',
          },
        })
      );
    }
  }
}
