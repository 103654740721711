import { Action } from '@ngrx/store';

import { ChannelDefinition } from 'src/app/_shared/interface/channel/channel-definition.interface';
import { ChannelsActions } from 'src/app/_store/_channels/actions';

export interface State {
  meterChannels: ChannelDefinition;
  trendsChannels: ChannelDefinition;
  mpId: string;
}

const initialState: State = {
  meterChannels: null,
  trendsChannels: null,
  mpId: null,
};

export function reducer(state: State = initialState, action: Action): State {
  const specificAction = action as ChannelsActions.ChannelsActionsUnion;
  switch (specificAction.type) {
    case ChannelsActions.setMeterChannels.type:
      return {
        ...state,
        mpId: specificAction.payload.mpId,
      };

    case ChannelsActions.setTrendsChannels.type:
      return {
        ...state,
        mpId: specificAction.payload.mpId,
      };

    case ChannelsActions.setMeterChannelsSuccess.type:
      return {
        ...state,
        meterChannels: specificAction.payload.meterChannels,
      };

    case ChannelsActions.setTrendsChannelsSuccess.type:
      return {
        ...state,
        trendsChannels: specificAction.payload.trendsChannels,
      };

    default:
      return state;
  }
}

export const getTrendsChannels = (state: State): State['trendsChannels'] => state.trendsChannels;
export const getMeterChannels = (state: State): State['meterChannels'] => state.meterChannels;
