import { Component, OnInit, ViewChild, ElementRef, AfterContentInit, OnDestroy } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { takeWhile, take, catchError } from 'rxjs/operators';
import { AsyncPipe } from '@angular/common';
import { MatIcon } from '@angular/material/icon';
import { MatIconButton } from '@angular/material/button';
import { TranslateModule } from '@ngx-translate/core';
import { BrowserModule } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';

import { OnDestroyMixin, untilComponentDestroyed } from 'src/app/_shared/classes/component-destroy.class';
import * as fromStore from 'src/app/_store/_reducers';
import { FeaturesActions } from 'src/app/_store/_features/actions';
import { LayoutActions } from 'src/app/_store/_layout/actions';
import { DiligentApiService } from 'src/app/_shared/services';
import { IAccount } from 'src/app/_shared/classes/account';
import { NotificationsService } from 'src/app/_shared/modules/notifications/shared/notifications.service';

type BannerConfig = {
  id: string;
  text: string;
  linkToActionEnabled: boolean;
  linkToActionText?: string;
  text2?: string;
};

type BannerListItem = BannerConfig & {
  hidden: boolean;
};

@Component({
  selector: 'app-head-banner',
  standalone: true,
  imports: [AsyncPipe, MatIcon, MatIconButton, TranslateModule, BrowserModule],
  templateUrl: './head-banner.component.html',
  styleUrl: './head-banner.component.scss',
})
export class HeadBannerComponent extends OnDestroyMixin implements OnDestroy, OnInit, AfterContentInit {
  @ViewChild('bannerContainer', { static: false }) containerElement: ElementRef;
  // Display configuration for banners
  config: BannerConfig[] = [
    {
      id: 'showExpirationBanner',
      text: 'headBanners.subscriptionBanner',
      linkToActionEnabled: true,
      linkToActionText: 'headBanners.subscriptionBannerLink',
      text2: 'headBanners.subscriptionBanner2',
    },
    {
      id: 'showPremiumTrialBanner',
      text: 'headBanners.premiumTrialBanner',
      linkToActionEnabled: false,
    },
  ];
  primaryAccExpiry$: Observable<string>;
  // List of banner to display
  bannersList: BannerListItem[] = [];
  bannerLoaded: boolean = false;

  constructor(
    private store: Store<fromStore.State>,
    private diligentService: DiligentApiService,
    private notificationsService: NotificationsService,
    private translateService: TranslateService
  ) {
    super();
  }
  ngOnInit(): void {
    this.store
      .select(fromStore.getFeatureBanners)
      .pipe(
        takeWhile(() => this.bannerLoaded === false),
        untilComponentDestroyed(this)
      )
      .subscribe((bannersFeatureFlags) => {
        // Create the banner list with only banner enabled in the store
        for (let banner of this.config) {
          if (bannersFeatureFlags[banner.id] === true) {
            this.bannersList.push({ ...banner, hidden: false });
          }
        }
        if (this.bannersList.length > 0) {
          this.bannerLoaded = true;
        }
        setTimeout(() => this.updateHeadBannerHeight(), 50);
      });

    this.primaryAccExpiry$ = this.store.select((state) => state.user.account.subscriptionExpiry);
  }

  ngAfterContentInit(): void {
    this.updateHeadBannerHeight();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  closeBanner(id: string): void {
    const banner = this.bannersList.find((item) => item.id === id);
    if (banner) {
      // Hide banner
      banner.hidden = true;
      // Dispatch action to hide banner till next login
      this.store.dispatch(FeaturesActions.setFeatureBannerVisibility({ payload: { [id]: false } }));
      // Emit banner element height
      setTimeout(() => this.updateHeadBannerHeight(), 50);
    }
  }

  onClickAction(bannerId: string): void {
    if (bannerId == 'showExpirationBanner') {
      this.store
        .pipe(select(fromStore.getAccount))
        .pipe(take(1))
        .subscribe((userAccount: IAccount) => {
          // Send upgrade request
          return this.diligentService
            .requestToUpgradeSubscription(userAccount.id)
            .pipe(
              take(1),
              catchError((err) => of(err))
            )
            .subscribe((upgradeResponse) => {
              // Display response message to screen
              if (upgradeResponse.error) {
                this.notificationsService.alert(
                  upgradeResponse.error.message,
                  this.translateService.instant('headBanners.alertNotificationTitle')
                );
              } else {
                this.notificationsService.notify(this.translateService.instant('headBanners.upgradeRequestSent'));
              }
            });
        });
    }
  }
  /**
   * Emit active banner count.
   * Used to communicate with parent and adjust layout
   */
  private updateHeadBannerHeight(): void {
    // Set section height in store
    if (this.containerElement) {
      this.store.dispatch(
        LayoutActions.setHeadBannerHeight({
          payload: { height: parseInt(this.containerElement?.nativeElement?.offsetHeight) },
        })
      );
    }
  }
}
