import { ConnectedOverlayPositionChange } from '@angular/cdk/overlay';
import { cloneDeep } from 'lodash';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Subject } from 'rxjs';

import * as fromStore from 'src/app/_store/_reducers';
import { UserPreferences } from 'src/app/_shared/interface/user-preferences.interface';
import { AuthService } from 'src/app/_shared/services';
import { OnDestroyMixin, untilComponentDestroyed } from 'src/app/_shared/classes/component-destroy.class';

@Component({
  selector: 'app-hint-box',
  templateUrl: './hint-box.component.html',
  styleUrls: ['./hint-box.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HintBoxComponent extends OnDestroyMixin implements OnDestroy, OnInit {
  @Input() overlayOrigin: any;
  @Input() hint: string;
  @Input() set isOpenedInput(value: boolean) {
    this.isOpened = value;
  }

  @Output() closeHint = new EventEmitter<any>();
  @Output() openHint = new EventEmitter<any>();
  @ViewChild('dialog') dialog: ElementRef;

  isOpened = false;
  destroy$ = new Subject<void>();
  userPrefs: UserPreferences;
  cdkPositionX: string;
  hide = false;

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private store: Store<fromStore.State>,
    private authService: AuthService
  ) {
    super();
  }

  ngOnInit(): void {
    this.store
      .pipe(select(fromStore.getUserPreferences))
      .pipe(untilComponentDestroyed(this))
      .subscribe((userPrefs) => {
        this.userPrefs = cloneDeep(userPrefs);
      });

    this.store
      .pipe(select(fromStore.getIsNewsOpened))
      .pipe(untilComponentDestroyed(this))
      .subscribe((isOpened) => {
        this.hide = isOpened;
        this.changeDetectorRef.markForCheck();
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    super.ngOnDestroy();
  }

  onPositionChange(event: ConnectedOverlayPositionChange): void {
    this.cdkPositionX = event.connectionPair.overlayX;
  }

  connectedOverlayDetach(): void {
    this.changeState(false);
  }

  hideMsg(): void {
    if (this.userPrefs.hint) {
      this.userPrefs.hint[this.hint] = false;
    } else {
      this.userPrefs = {
        ...this.userPrefs,
        hint: {
          [this.hint]: false,
        },
      };
    }

    if (
      (this.hint === 'firmwareUpdate' && this.userPrefs.hint['notificationCenter'] === false) ||
      (this.hint === 'notificationCenter' && this.userPrefs.hint['firmwareUpdate'] === false)
    ) {
      this.userPrefs.hint['firmwareAndNotification'] = false;
    }

    if (this.hint === 'firmwareAndNotification') {
      this.userPrefs.hint['notificationCenter'] = false;
      this.userPrefs.hint['firmwareUpdate'] = false;
    }

    this.authService.savePreferences(this.userPrefs, true);
    this.closeHint.emit();
  }

  private changeState(isOpened: boolean): void {
    this.isOpened = isOpened;
    isOpened ? this.openHint.emit() : this.closeHint.emit();
    this.changeDetectorRef.markForCheck();
  }
}
