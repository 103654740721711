import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import * as moment from 'moment';
import { DiligentApiService } from 'src/app/_shared/services/diligent-api.service';
import { INote } from 'src/app/_shared/classes/note.interface';

@Injectable({
  providedIn: 'root',
})
export class NotesService {
  constructor(private diligent: DiligentApiService) {}

  public getNotes(mpId: number, startDate: moment.Moment, endDate: moment.Moment): Observable<INote[]> {
    return this.diligent.getNotes(mpId, startDate, endDate);
  }

  public submitNewNote(formData: FormData, mpId: number): Observable<{ id: number }> {
    return this.diligent.submitNewNote(formData, mpId);
  }

  public updateNote(formData: FormData, noteId: number): Observable<{ status: string }> {
    return this.diligent.updateNote(formData, noteId);
  }

  public delete(noteId: number): Observable<{ status: string }> {
    return this.diligent.deleteNote(noteId);
  }

  public getSingleNote(mpId, noteMoment: moment.Moment): Observable<INote[]> {
    return this.diligent.getNotes(mpId, noteMoment.clone().subtract(1, 'second'), noteMoment.add(1, 'second'));
  }

  public getNoteDocuments(noteId: number): Observable<any[]> {
    return this.diligent.getNoteDocuments(noteId);
  }
}
