<div mat-dialog-title class="dialog-title">
  <h3>{{ (data.warn ? 'auto-logout.header-warn' : 'auto-logout.header') | translate }}</h3>
  <!-- <button mat-button (click)="close()">
  <mat-icon svgIcon="close"></mat-icon>
</button> -->
</div>
<mat-dialog-content class="mat-typography" innerHtml="{{ (data.warn ? 'auto-logout.message-warn' : 'auto-logout.message' ) | translate }}"></mat-dialog-content>
<div mat-dialog-actions>
  <button mat-raised-button class="notifications-button" (click)="ok()" color="primary">
    {{ (data.warn ? "auto-logout.ok-warn" : "auto-logout.ok" ) | translate }}
  </button>
  @if (data.warn) {
    <button mat-raised-button class="notifications-button" (click)="cancel()" color="danger">
      {{ "auto-logout.no"| translate }}
    </button>
  }
</div>