<div class="role-information">
  <div class="mobile-role">
    <mat-divider></mat-divider>
    <b>{{ 'global.viewer' | translate }}</b>
  </div>
  <ul class="border">
    <li>{{ 'manageMembers.roleDescription.viewer.1' | translate }}</li>
    <li>{{ 'manageMembers.roleDescription.viewer.2' | translate }}</li>
    <li>{{ 'manageMembers.roleDescription.viewer.3' | translate }}</li>
    <li>{{ 'manageMembers.roleDescription.viewer.4' | translate }}</li>
    <li>{{ 'manageMembers.roleDescription.viewer.5' | translate }}</li>
    <li>{{ 'manageMembers.roleDescription.viewer.6' | translate }}</li>
    <div class="premium">
      <mat-icon svgIcon="decagram" class="prem-icon"></mat-icon>
      <span>
        {{ 'manageMembers.roleDescription.premiumSubscription' | translate }}
      </span>
    </div>
    <li>{{ 'manageMembers.roleDescription.viewer.7' | translate }}</li>
    <li>{{ 'manageMembers.roleDescription.viewer.8' | translate }}</li>
  </ul>
  <div class="mobile-role">
    <mat-divider></mat-divider>
    <b>{{ 'global.engineer' | translate }}</b>
  </div>
  <ul class="border">
    <div class="plus">{{ 'manageMembers.roleDescription.engineer.plus' | translate }}</div>
    <li>{{ 'manageMembers.roleDescription.engineer.1' | translate }}</li>
    <li>{{ 'manageMembers.roleDescription.engineer.2' | translate }}</li>
    <li>{{ 'manageMembers.roleDescription.engineer.3' | translate }}</li>
    <li>{{ 'manageMembers.roleDescription.engineer.4' | translate }}</li>
    <li>{{ 'manageMembers.roleDescription.engineer.5' | translate }}</li>
    <div class="premium">
      <mat-icon svgIcon="decagram" class="prem-icon"></mat-icon>
      <span>
        {{ 'manageMembers.roleDescription.premiumSubscription' | translate }}
      </span>
    </div>
    <li>{{ 'manageMembers.roleDescription.engineer.6' | translate }}</li>
  </ul>
  <div class="mobile-role">
    <mat-divider></mat-divider>
    <b>{{ 'global.administrator' | translate }}</b>
  </div>
  <ul>
    <div class="plus">{{ 'manageMembers.roleDescription.admin.plus' | translate }}</div>
    <li>{{ 'manageMembers.roleDescription.admin.1' | translate }}</li>
    @if (isPartner) {
    <li>{{ 'manageMembers.roleDescription.admin.2' | translate }}</li>
    <li>{{ 'manageMembers.roleDescription.admin.3' | translate }}</li>
    }
    <div class="premium">
      <mat-icon svgIcon="decagram" class="prem-icon"></mat-icon>
      <span>
        {{ 'manageMembers.roleDescription.premiumSubscription' | translate }}
      </span>
    </div>
    <li>{{ 'manageMembers.roleDescription.admin.4' | translate }}</li>
    <li>{{ 'manageMembers.roleDescription.admin.5' | translate }}</li>
    <li>{{ 'manageMembers.roleDescription.admin.6' | translate }}</li>
  </ul>
</div>
