import { Injectable } from '@angular/core';
import { ReplaySubject, Observable, of } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';

import { IJwtPayload, IJwt, IJwtHeader } from 'src/app/_shared/classes/generic.interfaces';
import { environment } from 'src/environments/environment';

const TOKEN_KEY = 'auth_token';
const META_KEY = 'token_meta';
const LOGOUT_ACTION = 'logout_action';

type LogoutAction = 'logout' | 'differentUser' | 'login' | 'sessionExpired';

@Injectable()
export class TokenService {
  private obs$ = new ReplaySubject<IJwt>(1);

  public get hash(): string {
    return localStorage.getItem(TOKEN_KEY) || null;
  }
  public set hash(value: string) {
    localStorage.setItem(TOKEN_KEY, value);
    // this.obs$;
    this.parse();
  }
  public get logoutAction(): LogoutAction | null {
    return localStorage.getItem(LOGOUT_ACTION) as LogoutAction;
  }
  public set logoutAction(action: LogoutAction) {
    localStorage.setItem(LOGOUT_ACTION, action);
  }
  public get decodedParts(): ReplaySubject<IJwt> {
    return this.obs$;
  }
  public get payload(): Observable<IJwtPayload> {
    return this.decodedParts.pipe(
      map((parts) => {
        if (parts.payload) {
          parts.payload.accountId = this.metaData.accountId;
        }
        return parts.payload;
      })
    );
  }
  public get header(): Observable<IJwtHeader> {
    return this.decodedParts.pipe(map((parts) => parts.header));
  }
  public get signature(): Observable<string> {
    return this.decodedParts.pipe(map((parts) => parts.signature));
  }

  public get metaData(): any {
    const data = localStorage.getItem(META_KEY);
    return data ? JSON.parse(atob(data)) : {};
  }
  public set metaData(value: any) {
    localStorage.setItem(META_KEY, btoa(JSON.stringify(value)));
  }

  public get lastAccessedMs(): number {
    return !!this.hash ? parseInt(this.metaData.lastAccessMs, 10) : null;
  }

  constructor() {
    this.parse();
  }

  public parse(): void {
    if (!this.hash) {
      this.expire();

      this.obs$.next({
        header: null,
        payload: null,
        signature: null,
      });
      return;
    }

    // destructure the pieces after base64-decoding and json parsing
    const [header, payload, signature] = this.hash.split('.').map((p, idx) => {
      return idx < 2 ? JSON.parse(atob(p)) : p;
    });

    this.obs$.next({
      header,
      payload,
      signature,
    });
  }

  public isExpired(): Observable<boolean> {
    return this.payload.pipe(
      // don't keep listening - one-off
      take(1),
      switchMap((p) => {
        // no payload? expired.
        if (!p) return of(true);

        const curTime = Date.now() / 1000;
        const diff = curTime - p.exp;

        const threshold = environment.psl.token_refresh_threshold;

        // difference is positive; expired
        if (diff > 0) return of(true);
        // threshold exceeded; expire
        if (threshold && Math.abs(diff) <= threshold) return of(true);

        // not expired
        return of(false);
      })
    );
  }

  public expire(): void {
    localStorage.removeItem(TOKEN_KEY);
    localStorage.removeItem(META_KEY);
    this.logoutAction = 'sessionExpired';
    this.obs$.next({
      header: null,
      payload: null,
      signature: null,
    });
    // this.obs$.complete();
  }

  public setMetaData(key: string, value: string): void {
    this.metaData = {
      ...this.metaData,
      [key]: value,
    };
  }

  public touch(): void {
    if (!this.hash) return;
    this.setMetaData('lastAccessedMs', Date.now().toString());
  }
}
