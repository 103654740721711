import { Component, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators, AbstractControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { ForgotPasswordDialogData } from 'src/app/_shared/classes/forgotPasswordDialogData';
import { AuthService } from 'src/app/_shared/services';

@Component({
  selector: 'app-forgot-password-dialog',
  templateUrl: 'forgot-password-dialog.component.html',
  styleUrls: ['./forgot-password-dialog.component.scss'],
})
export class ForgotPasswordDialogComponent {
  public requestForm: FormGroup;

  constructor(
    private authService: AuthService,
    private dialogRef: MatDialogRef<ForgotPasswordDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ForgotPasswordDialogData
  ) {
    this.requestForm = new FormGroup({
      email: new FormControl(data.email, [Validators.required, Validators.email]),
    });
  }

  public get email(): AbstractControl {
    return this.requestForm.get('email');
  }

  public onCancel(): void {
    this.dialogRef.close();
  }

  public submitForm($event: Event): void {
    $event.stopPropagation();
    this.authService.requestPasswordReset(this.email.value).then(
      () => {
        this.dialogRef.close('success');
      },
      (error) => {
        this.dialogRef.close();
      }
    );
  }
}
