<div mat-dialog-title class="dialog-title" data-cy="change-mp-popup">
  <h3>{{ 'site.mpPosition' | translate }}</h3>
</div>
<section class="dialog-container">
  <button mat-icon-button class="close-button" type="button" (click)="dialogRef.close()">
    <mat-icon class="close-button" svgIcon="close"></mat-icon>
  </button>
  <mat-dialog-content>
    <div
      class="map"
      [ngStyle]="{ height: mapHeight + 'px' }"
      leaflet
      (leafletMapReady)="onMapReady($event)"
      [leafletOptions]="options"
    ></div>
    <div class="coordinate-input">
      <span class="coordinate-label">{{ 'site.coordinates' | translate }}</span>
      <div class="coordinate-text">
        <span>{{ coordInput }}</span>
      </div>
      <button mat-flat-button color="accent" (click)="selectCoord()">{{ 'site.save' | translate }}</button>
    </div>
    <div>{{ 'site.guidance' | translate }}</div>
  </mat-dialog-content>
</section>
