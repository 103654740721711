import { Injectable } from '@angular/core';
import { Moment } from 'moment';

import { PowerQualityMeasuresService } from 'src/app/_shared/services/power-quality-measures.service';
import { MeasurementPointsService } from 'src/app/_shared/services/measurement-points.service';
import { AlarmEventNoteRequest } from 'src/app/_shared/interface/alarm/alarm-event-note-request.interface';

@Injectable()
export class DashboardService {
  constructor(private pqService: PowerQualityMeasuresService, private mpService: MeasurementPointsService) {}

  public async getDashboardInfo(
    mpId: any,
    startDate: Moment = null,
    endDate: Moment = null,
    mpTypeId: number
  ): Promise<{ measurementPointEvents: any; powerQualityMeasures: any }> {
    const isQubeScan = mpTypeId === 1;
    const eventsRequest: AlarmEventNoteRequest = {
      measurementPointId: parseInt(mpId),
      dateRangeStart: startDate.toDate(),
      dateRangeEnd: endDate.toDate(),
      includeRetired: false,
      sorting: [{ column: 'triggeredWhen', desc: true }],
      offset: 0,
      count: 100000,
    };
    if (!isQubeScan) {
      eventsRequest.severity = 1;
    }
    const mpEventsResponse = await this.mpService.getAlarmsEventsNotes(eventsRequest).toPromise();
    const mpEvents = mpEventsResponse.records;

    const pqMeasures = await this.pqService.getPowerQualityMeasures(mpId);

    return {
      measurementPointEvents: mpEvents,
      powerQualityMeasures: pqMeasures,
    };
  }
}
