import {
  Action,
  ActionReducerMap,
  createSelector,
  createFeatureSelector,
  ActionReducer,
  MetaReducer,
} from '@ngrx/store';

import * as fromHierarchy from 'src/app/_store/_hierarchy/reducers/hierarchy.reducer';
import * as fromUser from 'src/app/_store/_user/reducers/user.reducer';
import * as fromChannels from 'src/app/_store/_channels/reducers/channels.reducer';
import * as fromDrivescanDashboard from 'src/app/_store/_drivescan-dashboard/reducers/drivescan-dashboard.reducer';
import * as fromQubescanDashboard from 'src/app/_store/_qubescan-dashboard/reducers/qubescan-dashboard.reducer';
import * as fromCharts from 'src/app/_store/_charts/reducers/charts.reducer';
import * as fromRecommendation from 'src/app/_store/_recommendation/reducers/recommendation.reducer';
import * as fromMaintenance from 'src/app/_store/_maintenance/reducers/maintenance.reducer';
import * as fromMap from 'src/app/_store/_map/reducers/map.reducer';
import * as fromNews from 'src/app/_store/_news/reducers/news.reducer';
import * as fromLoads from 'src/app/_store/_loads/reducers/loads.reducer';
import * as fromRoles from 'src/app/_store/_roles/reducers/roles.reducer';
import * as fromRangeSelector from 'src/app/_store/_range-selector/reducers/range-selector.reducer';
import * as fromFeatures from 'src/app/_store/_features/reducers/features.reducer';
import * as fromLayout from 'src/app/_store/_layout/reducers/layout.reducer';
import { environment } from 'src/environments/environment';

export interface State {
  hierarchy: fromHierarchy.State;
  user: fromUser.State;
  channels: fromChannels.State;
  drivescanDashboard: fromDrivescanDashboard.State;
  qubescanDashboard: fromQubescanDashboard.State;
  charts: fromCharts.State;
  recommendations: fromRecommendation.State;
  maintenance: fromMaintenance.State;
  map: fromMap.State;
  news: fromNews.State;
  energyPower: fromLoads.State;
  roles: fromRoles.State;
  rangeSelector: fromRangeSelector.State;
  features: fromFeatures.State;
  layout: fromLayout.State;
}

export const reducers: ActionReducerMap<State> = {
  hierarchy: fromHierarchy.reducer,
  user: fromUser.reducer,
  channels: fromChannels.reducer,
  drivescanDashboard: fromDrivescanDashboard.reducer,
  qubescanDashboard: fromQubescanDashboard.reducer,
  charts: fromCharts.reducer,
  recommendations: fromRecommendation.reducer,
  maintenance: fromMaintenance.reducer,
  map: fromMap.reducer,
  news: fromNews.reducer,
  energyPower: fromLoads.reducer,
  roles: fromRoles.reducer,
  rangeSelector: fromRangeSelector.reducer,
  features: fromFeatures.reducer,
  layout: fromLayout.reducer,
};

export function logger(reducer: ActionReducer<State>): ActionReducer<State> {
  return (state, action): State => {
    const result = reducer(state, action);
    console.groupCollapsed(action.type);
    console.log('prev state', state);
    console.log('action', action);
    console.log('next state', result);
    console.groupEnd();

    return result;
  };
}

// eslint-disable-next-line @typescript-eslint/no-shadow
export function clearStateMetaReducer<State extends {}>(reducer: ActionReducer<State>): ActionReducer<State> {
  return (state: State, action: Action): State => {
    if (action.type === '[App Logout] Logout') {
      state = {} as State;
    }
    return reducer(state, action);
  };
}

/**
 * By default, @ngrx/store uses combineReducers with the reducer map to compose
 * the root meta-reducer. To add more meta-reducers, provide an array of meta-reducers
 * that will be composed to form the root meta-reducer.
 */
export const metaReducers: MetaReducer<State>[] = !environment.production
  ? [logger, clearStateMetaReducer]
  : [clearStateMetaReducer];

/**
 * Hierarchy Reducers
 */
export const getHierarchyState = createFeatureSelector<fromHierarchy.State>('hierarchy');

export const getHierarchy = createSelector(getHierarchyState, fromHierarchy.getHierarchy);

export const getPartners = createSelector(getHierarchyState, fromHierarchy.getPartners);

export const getFilterPartners = createSelector(getHierarchyState, fromHierarchy.getFilterPartners);

export const getUserState = createFeatureSelector<fromUser.State>('user');

export const getUser = createSelector(getUserState, fromUser.getUser);

export const getUserRole = createSelector(getUserState, fromUser.getUserRole);

export const getIsPartner = createSelector(getUserState, fromUser.getIsPartner);

export const getIsSysAdmin = createSelector(getUserState, fromUser.getIsSysAdmin);

export const getIsAdmin = createSelector(getUserState, fromUser.getIsAdmin);

export const getUserPreferences = createSelector(getUserState, fromUser.getUserPreferences);

export const getWelcomeMessage = createSelector(getUserState, fromUser.getWelcomeMessage);

export const getHint = createSelector(getUserState, fromUser.getHint);

export const getAccount = createSelector(getUserState, fromUser.getAccount);

export const getUserSubType = createSelector(getUserState, fromUser.getUserSubType);

export const getCount = createSelector(getUserState, fromUser.getCount);

export const getMp = createSelector(getUserState, fromUser.getMp);

export const getHeaderName = createSelector(getUserState, fromUser.getHeaderName);

export const getQubescanType = createSelector(getUserState, fromUser.getQubescanType);

export const getAlarmChannel = createSelector(getUserState, fromUser.getAlarmChannel);

export const getSerialNumber = createSelector(getUserState, fromUser.getSerialNumber);

export const getLastComm = createSelector(getUserState, fromUser.getLastComm);

export const getTimezone = createSelector(getUserState, fromUser.getTimezone);

export const getTz = createSelector(getUserState, fromUser.getTz);

export const getPreviousUrl = createSelector(getUserState, fromUser.getPreviousUrl);

export const getAccountName = createSelector(getUserState, fromUser.getAccountName);

export const getFirmwareVersion = createSelector(getUserState, fromUser.getFirmwareVersion);

export const getUserId = createSelector(getUserState, fromUser.getUserId);

export const getLayoutState = createFeatureSelector<fromLayout.State>('layout');

export const getLayoutHeadBannerHeight = createSelector(getLayoutState, fromLayout.getHeadBannerHeight);

export const getFeaturesState = createFeatureSelector<fromFeatures.State>('features');

export const getFeatureNotificationGroup = createSelector(getFeaturesState, fromFeatures.getNotificationGroup);

export const getFeatureFirmwareUpdate = createSelector(getFeaturesState, fromFeatures.getFirmwareUpdate);

export const getFeatureBanners = createSelector(getFeaturesState, fromFeatures.getBanners);

export const getChannelsState = createFeatureSelector<fromChannels.State>('channels');

export const getDrivescanDashboardState = createFeatureSelector<fromDrivescanDashboard.State>('drivescanDashboard');

export const getDrivescanValue = createSelector(getDrivescanDashboardState, fromDrivescanDashboard.getDrivescanValue);

export const getQubescanDashboardState = createFeatureSelector<fromQubescanDashboard.State>('qubescanDashboard');

export const getQubescanValue = createSelector(getQubescanDashboardState, fromQubescanDashboard.getQubescanValue);

export const getError = createSelector(getQubescanDashboardState, fromQubescanDashboard.getError);

export const getQubeError = createSelector(getQubescanValue, getError, (qubescanValue, error) => {
  return { qubescanValue: qubescanValue, error: error };
});

export const getQubescanChannel = createSelector(getQubescanDashboardState, fromQubescanDashboard.getQubescanChannel);

export const getKPIColor = createSelector(getQubescanDashboardState, fromQubescanDashboard.getKPIColor);

export const getMeterChannels = createSelector(getChannelsState, fromChannels.getMeterChannels);

export const getTrendsChannels = createSelector(getChannelsState, fromChannels.getTrendsChannels);

export const getChartsState = createFeatureSelector<fromCharts.State>('charts');

export const getStartDate = createSelector(getChartsState, fromCharts.getStartDate);

export const getEndDate = createSelector(getChartsState, fromCharts.getEndDate);

export const getScope = createSelector(getChartsState, fromCharts.getScope);

export const getTitle = createSelector(getChartsState, fromCharts.getTitle);

export const getCommissionedWhen = createSelector(getChartsState, fromCharts.getCommissionedWhen);

export const getUsePreCommissionStart = createSelector(getChartsState, fromCharts.getUsePreCommissionStart);

export const getNature = createSelector(getChartsState, fromCharts.getNature);

export const getEvents = createSelector(getChartsState, fromCharts.getEvents);

export const getHfEmissions = createSelector(getStartDate, getTitle, getScope, (startDate, title, scope) => {
  return { startDate: startDate, title: title, scope: scope };
});

export const getRecommendationState = createFeatureSelector<fromRecommendation.State>('recommendations');

export const getRecommendationCount = createSelector(getRecommendationState, fromRecommendation.getRecommendationCount);
export const getRecommendation = createSelector(getRecommendationState, fromRecommendation.getRecommendation);

export const getMaintenanceState = createFeatureSelector<fromMaintenance.State>('maintenance');

export const getMaintenanceCommands = createSelector(getMaintenanceState, fromMaintenance.getMaintenanceCommands);

export const getMaintenanceRequests = createSelector(getMaintenanceState, fromMaintenance.getMaintenanceRequests);

export const getLastRequestUpdated = createSelector(getMaintenanceState, fromMaintenance.getLastRequestUpdated);

export const getIsAvailable = createSelector(getMaintenanceState, fromMaintenance.getIsAvailable);

export const getMapState = createFeatureSelector<fromMap.State>('map');

export const getZoomFactor = createSelector(getMapState, fromMap.getZoomFactor);

export const getMapCenter = createSelector(getMapState, fromMap.getMapCenter);

export const getTabIndex = createSelector(getMapState, fromMap.getTabIndex);

export const getMapData = createSelector(getZoomFactor, getMapCenter, (zoom, center) => {
  return { zoom: zoom, center: center };
});

export const getDeviceAccessInfo = createSelector(
  getSerialNumber,
  getLastComm,
  getTimezone,
  getTz,
  (serialNumber, lastComm, timezone, tz) => {
    return {
      serialNumber: serialNumber,
      lastComm: lastComm,
      timezone: timezone,
      tz: tz,
    };
  }
);

export const getNewsState = createFeatureSelector<fromNews.State>('news');

export const getNews = createSelector(getNewsState, fromNews.getNews);

export const getNewId = createSelector(getNewsState, fromNews.getNewId);

export const getIsNewsOpened = createSelector(getNewsState, fromNews.getIsNewsOpened);

export const getRangeSelectorState = createFeatureSelector<fromRangeSelector.State>('rangeSelector');

export const getRangeSelector = createSelector(getRangeSelectorState, fromRangeSelector.getRangeSelector);

export const getRangeSelectorType = createSelector(getRangeSelectorState, fromRangeSelector.getRangeSelectorType);

export const getRangeSelectorScope = createSelector(getRangeSelectorState, fromRangeSelector.getRangeSelectorScope);

export const getRangeSelectorReportStartDatePeriod = createSelector(
  getRangeSelectorState,
  fromRangeSelector.getRangeSelectorReportStartDatePeriod
);

export const getRangeSelectorGlobalStartDatePeriod = createSelector(
  getRangeSelectorState,
  fromRangeSelector.getRangeSelectorGlobalStartDatePeriod
);

export const getRangeSelectorReportEndDatePeriod = createSelector(
  getRangeSelectorState,
  fromRangeSelector.getRangeSelectorReportEndDatePeriod
);

export const getRangeSelectorGlobalEndDatePeriod = createSelector(
  getRangeSelectorState,
  fromRangeSelector.getRangeSelectorGlobalEndDatePeriod
);

export const getRangeSelectorReportPeriod = createSelector(
  getRangeSelectorReportStartDatePeriod,
  getRangeSelectorReportEndDatePeriod,
  (startDate, endDate) => {
    return {
      startDate: startDate,
      endDate: endDate,
    };
  }
);

export const getRangeSelectorGlobalPeriod = createSelector(
  getRangeSelectorGlobalStartDatePeriod,
  getRangeSelectorGlobalEndDatePeriod,
  (startDate, endDate) => {
    return {
      startDate: startDate,
      endDate: endDate,
    };
  }
);

export const getRangeSelectorStartDateRange = createSelector(
  getRangeSelectorState,
  fromRangeSelector.getRangeSelectorStartDateRange
);

export const getRangeSelectorEndDateRange = createSelector(
  getRangeSelectorState,
  fromRangeSelector.getRangeSelectorEndDateRange
);

export const getRangeSelectorCommissionedWhen = createSelector(
  getRangeSelectorState,
  fromRangeSelector.getRangeSelectorCommissionedWhen
);

export const getChartSet = createSelector(getRangeSelector, getTrendsChannels, (charts, channel) => {
  return { charts: charts, trendChannel: channel };
});
