import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs';

import { NotificationsService } from 'src/app/_shared/modules/notifications/shared/notifications.service';
import { MeasurementPointsService } from 'src/app/_shared/services/measurement-points.service';

@Component({
  selector: 'app-fail-association-dialog',
  templateUrl: './fail-association-dialog.component.html',
  styleUrls: ['./fail-association-dialog.component.scss'],
})
export class FailAssociationDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<FailAssociationDialogComponent>,
    private mpService: MeasurementPointsService,
    @Inject(MAT_DIALOG_DATA) private data: { mpId: number },
    private notificationsService: NotificationsService,
    private translateService: TranslateService
  ) {}

  closeDialog(): void {
    this.dialogRef.close();
  }

  retryAssociation(): void {
    this.mpService
      .retryAssociation(this.data.mpId)
      .pipe(take(1))
      .subscribe({
        next: () => {
          this.notificationsService.alert(
            this.translateService.instant('manageSiteMp.associate.popupMsg'),
            this.translateService.instant('manageSiteMp.associate.popupTitle')
          );
        },
        error: (error) => {
          this.notificationsService.alert(
            error.message,
            this.translateService.instant('manageSiteMp.associate.popupError')
          );
        },
      });
  }
}
