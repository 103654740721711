@if (!isSelectAll && selectionLength !== 0) {
  <mat-icon
    class="filter-selected"
    color="accent"
    svgIcon="filter-outline"
  ></mat-icon>
}
<mat-icon
  [matMenuTriggerFor]="menu"
  class="filter-icon"
  color="accent"
  svgIcon="menu"
  (click)="openFilter($event)"
></mat-icon>
<mat-menu #menu="matMenu" [formGroup]="checkboxForm">
  <span class="filter-menu" (click)="$event.stopPropagation()">
    <span class="filter-header-icon"><mat-icon color="accent" svgIcon="filter-outline"></mat-icon></span>
    @if (choiceStartLength > 6) {
      <div class="filter-input">
        <input matInput placeholder="Filters..." (input)="onInputChange($event.target.value)" />
      </div>
    }
    <div class="filter-item-list">
      @if (choiceStartLength > 3) {
        <div class="filter-item">
          <span>
            <mat-checkbox
              [disableRipple]="true"
              [checked]="isSelectAll"
              [indeterminate]="indeterminate"
              (change)="selectAll()"
              >
              {{ 'global.selectAll' | translate }}
            </mat-checkbox>
          </span>
        </div>
      }
      @for (choice of filteredChoices; track choice) {
        <div class="filter-item">
          <span>
            <mat-checkbox [disableRipple]="true" [formControlName]="choice.title" (change)="checkboxChange()">
              {{ choice.title }}
            </mat-checkbox>
          </span>
        </div>
      }
    </div>
  </span>
</mat-menu>
