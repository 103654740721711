<h2 mat-dialog-title>
  {{ 'manageSiteMp.associate.title' | translate }}
</h2>
<mat-dialog-content>
  <mat-form-field appearance="outline" subscriptSizing="dynamic">
    <mat-label> {{ 'manageSiteMp.associate.enterToken' | translate }}</mat-label>
    <input
      class="tokenInput"
      autocomplete="off"
      matInput
      [formControl]="associateTokenControl"
      maxlength="6"
      data-cy="tokenInput"
      placeholder="123456"
      (ngModelChange)="iotTokenChange()"
      />
    @if (associateTokenControl.errors?.invalid) {
      <mat-error>
        {{ 'manageSiteMp.associate.errors.invalid' | translate }}
      </mat-error>
    }
    @if (associateTokenControl.errors?.minlength) {
      <mat-error>
        {{ 'manageSiteMp.associate.errors.minlength' | translate }}
      </mat-error>
    }
    @if (associateTokenControl.errors?.pattern) {
      <mat-error>
        {{ 'manageSiteMp.associate.errors.pattern' | translate }}
      </mat-error>
    }
    @if (associateTokenControl.errors?.required) {
      <mat-error>
        {{ 'global.errors.fieldRequired' | translate }}
      </mat-error>
    }
    @if (associateTokenControl.errors?.error) {
      <mat-error>
        {{ error.msg }}
      </mat-error>
    }
  </mat-form-field>
</mat-dialog-content>
<a href="https://help.powerside.com/s/article/qubescan-how-to-how-to-get-my-iot-token" target="_blank">{{
  'manageSiteMp.associate.howTo' | translate
}}</a>
<mat-dialog-actions align="end">
  <div class="submit">
    <button mat-flat-button type="button" mat-dialog-close>
      {{ 'global.cancel' | translate }}
    </button>
    <button
      mat-flat-button
      [disabled]="!isSubscribe"
      data-cy="add-submit"
      color="accent"
      type="submit"
      (click)="submit()"
      >
      {{
      this.hasAllDataToDissociate
      ? ('manageSiteMp.associate.actions.disassociate' | translate)
      : ('manageSiteMp.associate.actions.submit' | translate)
      }}
    </button>
  </div>
</mat-dialog-actions>
<app-loader
  [loading]="loading"
  [message]="
  isDissociationCalled
  ? ('manageSiteMp.associate.dissociateLoading' | translate)
  : ('onBoarding.waitingForChannel' | translate)
  "
></app-loader>
