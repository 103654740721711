<section>
  <div class="header">
    <h1 mat-dialog-title>Heads up!</h1>
    <button mat-icon-button type="button" (click)="closeDialog()">
      <mat-icon svgIcon="close"></mat-icon>
    </button>
  </div>
  <hr class="mt-3" />
  <div mat-dialog-content>
    <p>
      Communication issue. No reply from PQube.<br /><br />Please retry the association
      <button mat-button class="retry-button" color="accent" (click)="retryAssociation()">
        <mat-icon svgIcon="refresh"></mat-icon> Retry Association
      </button>
      <br />You can also retry the association in the Measurement Point Information menu
      <mat-icon svgIcon="information" color="accent"></mat-icon><br />
      <br />If the problem persists, run the connectivity test from the PQube screen or the PQube command page:<br /><br /><img
        src="./assets/images/test-connectivity.png"
        style="max-width: 480px"
        alt="Connectivity"
      />
    </p>
  </div>
  <div mat-dialog-actions>
    <button
      mat-raised-button
      data-cy="notifications-button"
      class="notifications-button"
      color="accent"
      (click)="closeDialog()"
    >
      {{ 'global.OK' | translate }}
    </button>
  </div>
</section>
