import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { switchMap, map } from 'rxjs/operators';
import { DiligentApiService } from 'src/app/_shared/services';
import { FeaturesActions } from 'src/app/_store/_features/actions';
import { Features } from 'src/app/_shared/classes/features';

@Injectable()
export class FeaturesEffects {
  initFeatures$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FeaturesActions.initFeatures.type),
      switchMap((_action) => {
        return this.diligentApiService.getFeatures().pipe(
          map((response: { features: Features }) =>
            FeaturesActions.getFeaturesSuccess({
              payload: response.features,
            }),
          ),
        );
      }),
    ),
  );

  constructor(
    private actions$: Actions<FeaturesActions.FeaturesActionsUnion>,
    private diligentApiService: DiligentApiService,
  ) {
  }
}
