import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'mpStatusName',
})
export class MpStatusNamePipe implements PipeTransform {
  transform(value: any, ...args: unknown[]): string {
    return value.replaceAll(/[A-Z]/g, (match) => ` ${match}`).replace(/^./, (match) => `${match.toLocaleUpperCase()}`);
  }
}
