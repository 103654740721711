import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatDialogRef } from '@angular/material/dialog';
import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';

@Component({
  selector: 'app-auto-logout',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './auto-logout.component.html',
  styleUrls: ['./auto-logout.component.scss'],
})
export class AutoLogoutComponent {
  constructor(
    public dialogRef: MatDialogRef<AutoLogoutComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { warn: false }
  ) {}

  close(): void {
    this.dialogRef.close();
  }

  ok(): void {
    this.dialogRef.close(this.data.warn);
  }

  cancel(): void {
    this.dialogRef.close(false);
  }
}
