import { Component } from '@angular/core';
import { AgRendererComponent, ICellEditorAngularComp, ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

export const checkboxTemplate = '<mat-checkbox [(ngModel)]="cellValue" [disabled]="disabled"></mat-checkbox>';

class AgMatCheckbox {
  public cellValue: boolean;
  public disabled: boolean;

  getValueToDisplay(params: any): boolean {
    return typeof params.value === 'number' ? (params.value === 1 ? true : false) : params.value;
  }
}

@Component({
  template: checkboxTemplate,
})
export class AgMatCheckboxComponent extends AgMatCheckbox implements AgRendererComponent {
  agInit(params: ICellRendererParams): void {
    this.disabled = true;
    this.cellValue = this.getValueToDisplay(params);
  }

  refresh(params: ICellRendererParams): boolean {
    this.cellValue = this.getValueToDisplay(params);
    return true;
  }
}

@Component({
  template: checkboxTemplate,
})
export class AgMatCheckboxEditorComponent extends AgMatCheckbox implements ICellEditorAngularComp {
  agInit(params: any): void {
    this.disabled = false;
    this.cellValue = this.getValueToDisplay(params);
  }

  getValue(): boolean {
    return this.cellValue;
  }

  isCancelBeforeStart(): boolean {
    return false;
  }

  isCancelAfterEnd(): boolean {
    return false;
  }
}
@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'checkbox-renderer',
  template:
    ' <input type="checkbox" [disabled]="params?.viewerMode" (click)="checkedHandler($event)" [checked]="params.value" /> ',
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class CheckboxRenderer implements ICellRendererAngularComp {
  public params: any;

  agInit(params: any): void {
    this.params = params;
  }

  checkedHandler(event): any {
    let checked = event.target.checked;
    let colId = this.params.column.colId;
    this.params.node.setDataValue(colId, checked);
  }

  refresh(params): any {
    return true;
  }
}
