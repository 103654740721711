import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'prefixCal',
})
export class PrefixCalPipe implements PipeTransform {
  transform(value: number, ...args: any[]): string {
    let valueMax = Math.abs(value);
    if ((args[1] && args[2], args[3])) {
      valueMax = Math.abs(Math.max(args[1], args[2], args[3]));
    }
    if (valueMax / 1000 < 1) {
      return args[0];
    } else if (valueMax / 1000000 < 1) {
      return 'k' + args[0];
    } else {
      return 'M' + args[0];
    }
  }
}
