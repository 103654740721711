import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

export interface AgMatTooltipParams extends ICellRendererParams {
  maxLength: number;
}

@Component({
  template:
    '<span [matTooltip]="params.value" matTooltipPosition="left" [matTooltipDisabled]="params.eGridCell.offsetWidth > $any(params.eGridCell.firstChild)?.offsetWidth">{{params.value}}</span>',
})
export class AgMatTooltipComponent implements ICellRendererAngularComp {
  public params: AgMatTooltipParams;

  agInit(params: AgMatTooltipParams): void {
    this.params = params;
  }

  refresh(params: ICellRendererParams): boolean {
    return false;
  }
}
