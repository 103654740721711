import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { DeleteMemberData } from 'src/app/_shared/classes/deleteMemberData.interface';
import { IMember } from 'src/app/_shared/classes/member.interface';
import { MeasurementPoint } from 'src/app/_shared/classes/MeasurementPoint';

@Component({
  selector: 'app-delete-member-dialog',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './delete-member-dialog.component.html',
  styleUrls: ['./delete-member-dialog.component.scss'],
})
export class DeleteMemberDialogComponent {
  accountName: string;
  member: IMember;
  mp: MeasurementPoint;
  unassociate: boolean;
  title: string;

  constructor(
    public dialogRef: MatDialogRef<DeleteMemberDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DeleteMemberData
  ) {
    if (data.member) {
      this.member = data.member;
      this.accountName = data.accountName;
      this.title = 'manageMembers.removeUser';
    } else if (data.mp) {
      this.mp = data.mp;
      this.accountName = data.accountName;
      this.unassociate = data.unassociate;
      this.unassociate ? (this.title = 'fleet.unassociateMp') : (this.title = 'fleet.deleteMp');
    }
  }

  public closeDialog(): void {
    this.dialogRef.close();
  }

  public deleteMember(): void {
    this.dialogRef.close(true);
  }
}
