import { NgModule } from '@angular/core';

import { DragDropZoneComponent } from './drag-drop-zone.component';
import { SharedModule } from 'src/app/_shared/shared.module';
import { DragDropDirective } from 'src/app/_shared/directive/drag-drop.directive';

@NgModule({
  declarations: [DragDropZoneComponent, DragDropDirective],
  imports: [SharedModule],
  exports: [DragDropZoneComponent],
})
export class DragDropZoneModule {}
