import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { DiligentApiService } from 'src/app/_shared/services/diligent-api.service';

@Injectable()
export class EnumationsService {
  constructor(private diligent: DiligentApiService) {}

  getEnumerations(enumerations?: Array<string>): Observable<any> {
    return this.diligent.getEnumerations(enumerations);
  }
}
