import { Action } from '@ngrx/store';

import { LoadsActions } from 'src/app/_store/_loads/actions';

export interface State {
  loads: Array<number>;
}

const initialState: State = {
  loads: [0],
};

export function reducer(state: State = initialState, action: Action): State {
  const specificAction = action as LoadsActions.LoadsActionsUnion;
  switch (specificAction.type) {
    case LoadsActions.updateLoads.type:
      return {
        ...state,
        loads: specificAction.payload.loads,
      };

    default:
      return state;
  }
}
