import { cloneDeep } from 'lodash';
import { Action } from '@ngrx/store';

import { MaintenanceActions } from 'src/app/_store/_maintenance/actions';
import { MaintenanceRequestState } from 'src/app/_shared/interface/maintenance';
import { CommandsList } from 'src/app/_shared/interface/maintenance/commands-list.interface';

export interface State {
  mpId: number;
  requests: Array<MaintenanceRequestState>;
  commands: CommandsList;
  lastUpdated: MaintenanceRequestState;
  isAvailable: boolean;
}

export const initialState: State = {
  mpId: null,
  requests: null,
  commands: null,
  lastUpdated: null,
  isAvailable: null,
};

export function reducer(state: State = initialState, action: Action): State {
  const specificAction = action as MaintenanceActions.MaintenanceActionsUnion;

  switch (specificAction.type) {
    case MaintenanceActions.getMaintenanceCommandSuccess.type:
      let commandsList = {};
      for (let command of specificAction.payload.commands) {
        commandsList[command.command] = command;
      }
      return { ...state, commands: commandsList };
    case MaintenanceActions.getMaintenanceRequestsSuccess.type:
      return {
        ...state,
        requests: (specificAction.payload as any).requests,
        mpId: (specificAction.payload as any).mpId,
      };
    case MaintenanceActions.createMaintenanecRequetsSuccess.type:
      let requestIdIndex = state.requests.findIndex((request) => {
        return specificAction.payload.request.id === request.id;
      });
      let newState;
      if (requestIdIndex >= 0) {
        newState = cloneDeep(state.requests);
        newState.splice(requestIdIndex, 1);
        newState = [specificAction.payload.request, ...newState];
      } else {
        newState = [specificAction.payload.request, ...state.requests];
      }
      return { ...state, requests: [...newState] };
    case MaintenanceActions.updateRequestsState.type:
      return { ...state };
    case MaintenanceActions.updateRequestsStateSuccess.type: {
      const requests = [...state.requests];
      const indexItem = requests.findIndex((item) => item.id === specificAction.payload.request.id);
      requests[indexItem] = specificAction.payload.request;
      return { ...state, requests };
    }
    case MaintenanceActions.setIsAvailable.type:
      return { ...state, isAvailable: specificAction.payload };
    default:
      return state;
  }
}

export const getMaintenanceCommands = (state: State): State['commands'] => state.commands;
export const getMaintenanceRequests = (state: State): State['requests'] => state.requests;
export const getLastRequestUpdated = (state: State): State['lastUpdated'] => state.lastUpdated;
export const getIsAvailable = (state: State): State['isAvailable'] => state.isAvailable;
