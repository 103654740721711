import { Component, OnInit, Input, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { select, Store } from '@ngrx/store';
import * as moment from 'moment';

import { OnDestroyMixin, untilComponentDestroyed } from 'src/app/_shared/classes/component-destroy.class';
import { GraphManagerService } from 'src/app/_shared/services/graph-manager.service';
import { RangeSelectorActions } from 'src/app/_store/_range-selector/actions';
import * as fromStore from 'src/app/_store/_reducers';

@Component({
  selector: 'app-period-navigator',
  templateUrl: './period-navigator.component.html',
  styleUrls: ['./period-navigator.component.scss'],
})
export class PeriodNavigatorComponent extends OnDestroyMixin implements OnDestroy, OnInit {
  @Input() currentScope: moment.unitOfTime.DurationConstructor;
  @Input() absoluteStartDate: moment.Moment;
  @Input() type: string;
  @Input() haveFutur: boolean;
  @Input() havePast: boolean;
  @Input() date: moment.Moment;
  @Input() rangeType: string;

  beginningOfCreation = false;
  title: string;

  set absoluteStartData(newValue) {
    this.checkForBeginningOfCreation();
  }

  constructor(
    private graphManager: GraphManagerService,
    private store: Store<fromStore.State>,
    private changeDetectorRef: ChangeDetectorRef
  ) {
    super();
  }

  ngOnInit(): void {
    this.store
      .pipe(select(fromStore.getRangeSelectorState))
      .pipe(untilComponentDestroyed(this))
      .subscribe((charts) => {
        if (this.currentScope !== charts[this.rangeType].scope || this.title !== charts[this.rangeType].title) {
          this.currentScope = charts[this.rangeType].scope;
          this.title = charts[this.rangeType].title;
          this.changeDetectorRef.detectChanges();
        }
      });

    this.checkForBeginningOfCreation();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  checkForBeginningOfCreation(): void {
    if (this.graphManager.dateRangeStart) {
      if (this.graphManager.dateRangeStart.valueOf() <= this.absoluteStartDate.valueOf()) {
        this.beginningOfCreation = true;
      } else {
        this.beginningOfCreation = false;
      }
    }
  }

  changeTimeScope(newScope: moment.unitOfTime.DurationConstructor): void {
    if (newScope !== 'hours') {
      this.store.dispatch(
        RangeSelectorActions.setScope({
          payload: {
            rangeType: this.rangeType,
            scope: newScope,
          },
        })
      );
    }
    if (this.currentScope !== newScope) {
      this.currentScope = newScope;
    }
  }

  changeTimePosition(directionOfChange: string): void {
    this.store.dispatch(
      RangeSelectorActions.changeInterval({
        payload: {
          rangeType: this.rangeType,
          direction: directionOfChange,
        },
      })
    );
  }
}
