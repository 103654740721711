import { HttpHeaders } from '@angular/common/http';
import { Moment } from 'moment';
import * as moment from 'moment';

export interface SubscriptionData {
  truncatedData: boolean;
  dataMonthLimit?: number;
  dateStart?: Moment;
  data: any;
  error?: string;
}

export function parseSubscriptionData(headers: HttpHeaders, body: any): SubscriptionData {
  const dataMonthLimit = headers.has('data-months-limit') ? Number(headers.get('data-months-limit')) : 3;
  return {
    truncatedData: headers.has('truncated-data') ? headers.get('truncated-data') === 'true' : false,
    dataMonthLimit: dataMonthLimit,
    dateStart: headers.has('datestart-date')
      ? moment(headers.get('datestart-date'), 'YYYY-MM-DDTHH:mm.SSSZ')
      : moment().subtract(dataMonthLimit, 'month').subtract(1, 'day'),
    data: body,
  };
}
