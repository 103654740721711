import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { DiligentApiService } from 'src/app/_shared/services/diligent-api.service';
import { ISiteMp } from 'src/app/_shared/classes/SiteMp.interface';
import { IMeasurementPoint } from 'src/app/_shared/classes/measurementpoint.interface';

@Injectable()
export class SitesService {
  constructor(private diligent: DiligentApiService) {}

  public getAdminSites(accountId: number): Promise<unknown[]> {
    return this.diligent.getAccounts(accountId).then(async (response) => {
      const partnerAccounts = response.reduce((accumulator, current) => {
        if (current.isPartner === 1) {
          return [...accumulator, current];
        }

        return accumulator;
      }, []);

      return await Promise.all(partnerAccounts.map((account) => this.getSites(account.id).toPromise()));
    });
  }

  public getSites(accountId?: number): Observable<any> {
    return this.diligent.getSites(accountId).pipe(
      map((sites) => {
        let accountSites = sites;

        if (sites && sites.customers) {
          accountSites = {
            partners: [
              {
                customers: sites.customers,
              },
            ],
          };
        }

        return accountSites;
      })
    );
  }

  public getMeasurementPointsForMap(query): Observable<Array<IMeasurementPoint>> {
    return this.diligent.getMeasurementPointsForMap(query);
  }

  public getMeasurementPointsForMapPromise(accountId?: number): Promise<Array<IMeasurementPoint>> {
    return this.diligent.getMeasurementPointsForMapPromise(accountId);
  }

  public getSite(siteId: number): Promise<any> {
    return this.diligent.getSite(siteId);
  }

  public createSiteMp(accountId: number, siteMp: ISiteMp): Observable<any> {
    return this.diligent.createsiteMp(accountId, siteMp);
  }

  public updateSiteMp(accountId: number, siteId: number, measurementPointId: number, siteMp: ISiteMp): Observable<any> {
    return this.diligent.updatesiteMp(accountId, siteId, measurementPointId, siteMp);
  }

  public updateMpStatus(mpId: number, mpStatusId: number, siteMp: ISiteMp): Observable<any> {
    return this.diligent.updateMpStatus(mpId, mpStatusId, siteMp);
  }
}
