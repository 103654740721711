import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { TrendAlarmModel, TrendAlarmModelQuery } from 'src/app/_shared/interface/trend-alarm-model.interface';
import { DiligentApiService } from './diligent-api.service';
import { TrendAlarm } from 'src/app/_shared/interface/alarm/trend-alarm.interface';

@Injectable({
  providedIn: 'root',
})
export class TrendAlarmModelService {
  constructor(private diligentService: DiligentApiService) {}

  public getTrendAlarmModels(query: TrendAlarmModelQuery): Observable<TrendAlarmModel[]> {
    return this.diligentService.getTrendAlarmModels(query);
  }

  public updateTrendAlarmModels(trendAlarmModel: TrendAlarmModel): Observable<TrendAlarmModel> {
    return this.diligentService.updateTrendAlarmModels(trendAlarmModel);
  }

  public createTrendAlarmModels(trendAlarmModel: TrendAlarmModel): Observable<TrendAlarmModel> {
    return this.diligentService.createTrendAlarmModels(trendAlarmModel);
  }

  public retireTrendAlarmModels(trendAlarmModelId: number): Observable<void> {
    return this.diligentService.retireTrendAlarmModels(trendAlarmModelId);
  }

  public getMpTrendAlarm(mpId: number, includeDisabled?: boolean): Observable<Array<TrendAlarm>> {
    return this.diligentService.getMpTrendAlarm(mpId, includeDisabled);
  }

  public getTrendAlarmChannelList(mpId: number): Observable<Array<TrendAlarmModel>> {
    return this.diligentService.getTrendAlarmChannelList(mpId);
  }
}
