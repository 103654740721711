import { Action } from '@ngrx/store';
import { LatLng } from 'leaflet';

import { MapActions } from 'src/app/_store/_map/actions';
export interface State {
  zoomFactor: number;
  center: LatLng;
  tabIndex: number;
}

export const initialState: State = {
  zoomFactor: 2,
  center: null,
  tabIndex: 0,
};

export function reducer(state: State = initialState, action: Action): State {
  const specificAction = action as MapActions.MapActionsUnion;

  switch (specificAction.type) {
    case MapActions.setZoomFactor.type:
      return { ...state, zoomFactor: specificAction.payload.zoomFactor };
    case MapActions.setMapCenter.type:
      return { ...state, center: specificAction.payload.center };
    case MapActions.initMap.type:
      return { ...state, center: specificAction.payload.center, zoomFactor: specificAction.payload.zoomFactor };
    case MapActions.setTabIndex.type:
      return { ...state, tabIndex: specificAction.payload.tabIndex };
    default:
      return state;
  }
}

export const getZoomFactor = (state: State): State['zoomFactor'] => state.zoomFactor;
export const getMapCenter = (state: State): State['center'] => state.center;
export const getTabIndex = (state: State): State['tabIndex'] => state.tabIndex;
