import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  Input,
  SimpleChanges,
  OnChanges,
  ViewChild,
  OnDestroy,
} from '@angular/core';
import * as moment from 'moment';
import { MatDatepicker } from '@angular/material/datepicker';
import { FormControl } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { take } from 'rxjs/operators';

import { DChartNavigatorComponent } from 'src/app/_shared/components/date-picker-nav/d-chart-navigator/d-chart-navigator.component';
import * as fromStore from 'src/app/_store/_reducers';
import { OnDestroyMixin, untilComponentDestroyed } from 'src/app/_shared/classes/component-destroy.class';
import { ChartsActions } from 'src/app/_store/_charts/actions';

@Component({
  selector: 'app-date-picker-nav',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './date-picker-nav.component.html',
  styleUrls: ['./date-picker-nav.component.scss'],
})
export class DatePickerNavComponent extends OnDestroyMixin implements OnChanges, OnDestroy, OnInit {
  @Input() chartSetName: string;
  @Input() absoluteStartDate: moment.Moment;
  @Input() havePast: boolean;
  @Input() scopeDisabled: boolean = false;
  @Input() type: string = 'default';

  @ViewChild(DChartNavigatorComponent) chartNavigator: DChartNavigatorComponent;

  public dateControl: FormControl;
  public startView = 'year';
  public maxDate: moment.Moment;
  public title: string;
  public haveFutur = false;
  public timezone: string;
  public scope: moment.unitOfTime.DurationConstructor;
  public date: moment.Moment;

  constructor(private store: Store<fromStore.State>) {
    super();
  }

  ngOnInit(): void {
    this.store
      .pipe(select(fromStore.getMp))
      .pipe(take(1))
      .subscribe((mp) => {
        this.timezone = mp.timezone;
      });

    this.store
      .pipe(select(fromStore.getScope))
      .pipe(untilComponentDestroyed(this))
      .subscribe((scope) => {
        this.scope = scope;
        this.setDatepickerStartView();
      });

    this.store
      .pipe(select(fromStore.getEndDate))
      .pipe(untilComponentDestroyed(this))
      .subscribe((endDate) => {
        this.dateControl = new FormControl(moment(endDate, 'L').tz(this.timezone, true));
        this.checkIfFutur();
      });

    this.maxDate = moment();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes.scope && !changes.scope.firstChange) {
      this.setDatepickerStartView();
    }
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  private setDatepickerStartView(): void {
    if (this.scope === 'days' || this.scope === 'weeks') {
      this.startView = 'month';
    } else if (this.scope === 'months') {
      this.startView = 'year';
    } else if (this.scope === 'years') {
      this.startView = 'multi-year';
    }
  }

  private checkIfPast(): void {
    const tempDate: moment.Moment = moment(this.dateControl.value)
      .tz(this.timezone)
      .subtract(1, 'minutes')
      .startOf(this.scope);

    if (this.absoluteStartDate.isAfter(tempDate)) {
      this.havePast = false;
    } else {
      this.havePast = true;
    }
  }

  private checkIfFutur(): void {
    if (moment(this.dateControl.value).add(1, 'day').isAfter(moment().tz(this.timezone))) {
      this.haveFutur = false;
    } else {
      this.haveFutur = true;
    }
  }

  public updateDate(event): void {
    this.store.dispatch(
      ChartsActions.setWeekDay({
        payload: {
          year: event.value.year(),
          month: event.value.month(),
          day: event.value.date(),
        },
      })
    );
  }

  public chosenYearHandler(normalizedYear: moment.Moment, datepicker: MatDatepicker<moment.Moment>): void {
    if (this.scope === 'years') {
      datepicker.close();
      this.store.dispatch(
        ChartsActions.setYear({
          payload: {
            year: normalizedYear.year(),
            chartSetName: this.chartSetName,
          },
        })
      );

      const ctrlValue = this.dateControl.value;
      ctrlValue.year(normalizedYear.year());
      this.dateControl.setValue(ctrlValue);
    }
  }

  public chosenMonthHandler(normalizedMonth: moment.Moment, datepicker: MatDatepicker<moment.Moment>): void {
    if (this.scope === 'months') {
      datepicker.close();
      this.store.dispatch(
        ChartsActions.setMonth({
          payload: {
            year: normalizedMonth.year(),
            month: normalizedMonth.month(),
            chartSetName: this.chartSetName,
          },
        })
      );

      const ctrlValue = this.dateControl.value;
      ctrlValue.month(normalizedMonth.month());
      this.dateControl.setValue(ctrlValue);
    }
  }
}
