<div class="parameters-table">
  <ag-grid-angular
    class="ag-theme-alpine"
    appAgUnFocus
    [gridOptions]="gridOptions"
    [rowData]="data | async"
    [columnDefs]="columnDefs"
    [defaultColDef]="defaultColDef"
    (gridReady)="gridReady($event)"
    [getRowStyle]="getRowStyle"
    [context]="context"
    (cellValueChanged)="onCellValueChanged($event)"
  >
  </ag-grid-angular>
</div>
