import { Component, Input } from '@angular/core';

import { PhasorsData } from 'src/app/_shared/interface/phasors-config.interface';

@Component({
  selector: 'app-phasors-info',
  templateUrl: './phasors-info.component.html',
  styleUrls: ['./phasors-info.component.scss'],
})
export class PhasorsInfoComponent {
  @Input() nominalVoltage: number;
  @Input() set data(value: PhasorsData) {
    this.mData = value;
    if (value && (!this.groups || this.groups.length === 0)) {
      this.groups = Object.keys(value);
    }
  }

  @Input() chartView: string;

  get data(): PhasorsData {
    return this.mData;
  }

  private mData: PhasorsData;
  groups: string[];
}
