import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-delete-note-dialog',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: 'delete-note-dialog.component.html',
  styleUrls: ['./delete-note-dialog.component.scss'],
})
export class DeleteNoteDialogComponent {
  public confirmDelete: boolean;

  constructor(public dialogRef: MatDialogRef<DeleteNoteDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {}

  onCancel(): void {
    this.dialogRef.close(false);
  }

  onConfirm(): void {
    this.dialogRef.close(true);
  }
}
