<div class="app-layout">
  <app-header
    [class.hidden]="headerHidden"
    [(mobileMenuOpen)]="mobileMenuOpen"
    (actionTrigger)="removeOnBoardingLoad()"
  ></app-header>
  <app-head-banner></app-head-banner>
  <div class="app-content">
    @if (mobileMenuOpen) {
    <div class="mobile-menu-background" (click)="mobileMenuOpen = false"></div>
    }
    <app-navigation
      [ngStyle]="{ top: dynamicMargin.navigationTop }"
      [class.mobile-open]="mobileMenuOpen"
      (navigated)="mobileMenuOpen = false"
      [mobileMenuOpen]="mobileMenuOpen"
      (click)="removeOnBoardingLoad()"
    ></app-navigation>
    <section [ngStyle]="{ 'margin-top': dynamicMargin.sectionTop }">
      <router-outlet></router-outlet>
      <app-loader [loading]="loading" [message]="'onBoarding.waitingForChannel' | translate"></app-loader>
    </section>
  </div>
  <!-- <app-footer></app-footer> -->
</div>
