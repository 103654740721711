@if (groups) {
<table
  class="phasor-groups"
  [ngClass]="{
    'single-chart': chartView === '1',
    'double-chart': chartView === '2',
    'quadruple-chart': chartView === '4',
    'sextuple-chart': chartView === '6'
  }"
>
  @for (group of groups; track group) {
  <div class="phasor-group">
    <h4>{{ group }}</h4>
    <div class="nominal-value">
      {{ data[group].nominalTitle }}:
      <span style="font-weight: bold"
        >{{
          group === 'Voltage'
            ? nominalVoltage >= 1000
              ? nominalVoltage
              : nominalVoltage.toFixed(2)
            : data[group].nominalValue
        }}
        {{ data[group].nominalSuffix }}</span
      >
    </div>
    <section>
      @for (item of data[group].data; track item) {
      <tr class="row">
        <td>{{ item.channel }}:&nbsp;</td>
        <td [style.color]="item.color" class="first-column">{{ item.value }}{{ item.suffix }}</td>
        <td>&nbsp;</td>
        <td [style.color]="item.color">{{ item.angle }}°</td>
      </tr>
      }
    </section>
  </div>
  }
</table>
}
