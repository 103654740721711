import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { latLng, Map, tileLayer } from 'leaflet';
import * as L from 'leaflet';
import 'leaflet.markercluster';
import 'leaflet.featuregroup.subgroup';
import 'leaflet-providers';

@Component({
  selector: 'app-map-dialog',
  templateUrl: './map-dialog.component.html',
  styleUrls: ['./map-dialog.component.scss'],
})
export class MapDialogComponent {
  map: Map;
  coordInput: string;
  popup: L.Marker;
  mapHeight: number;

  icon = {
    icon: L.icon({
      iconSize: [25, 41],
      iconAnchor: [13, 41],
      iconUrl: 'assets/images/mapOpenGrid/marker-icon.png',
      shadowUrl: 'assets/images/mapOpenGrid/marker-shadow.png',
    }),
  };

  options = {
    layers: [tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', { maxZoom: 18, attribution: '...' })],
    zoom: 5,
    center: latLng(46.879966, -121.726909),
    noWrap: true,
  };

  constructor(public dialogRef: MatDialogRef<MapDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
    this.coordInput = this.data.latLng;

    this.mapHeight = window.innerHeight - 270;
  }

  onMapReady(map: Map): void {
    this.map = map;
    this.map.setMaxBounds([
      [-90, -180],
      [90, 180],
    ]);

    if (this.coordInput !== '' && this.coordInput === this.data.latLng) {
      let coord = this.data.latLng.split(',');
      map.panTo(new L.LatLng(parseFloat(coord[0]), parseFloat(coord[1])));
      this.popup = new L.Marker([parseFloat(coord[0]), parseFloat(coord[1])], this.icon).addTo(map);
    }

    map.on('click', (e: L.LeafletMouseEvent) => {
      this.coordInput = e.latlng.lat.toFixed(8) + ', ' + e.latlng.lng.toFixed(8);
      if (this.popup) {
        map.removeLayer(this.popup);
      }
      this.popup = new L.Marker([e.latlng.lat, e.latlng.lng], this.icon).addTo(map);
    });
  }

  clearCoord(): void {
    this.coordInput = '';
    if (this.popup) {
      this.map.removeLayer(this.popup);
    }
  }

  selectCoord(): void {
    let coord = this.coordInput.split(',');
    if (
      parseFloat(coord[0]) <= 90 &&
      parseFloat(coord[0]) >= -90 &&
      parseFloat(coord[1]) <= 180 &&
      parseFloat(coord[1]) >= -180
    ) {
      this.dialogRef.close(this.coordInput);
    }
  }
}
