import { createAction, props } from '@ngrx/store';

import { IUser } from 'src/app/_shared/classes/user';
import { UserPreferences } from 'src/app/_shared/interface/user-preferences.interface';
import { IAccount } from 'src/app/_shared/classes/account';

export const initUser = createAction(
  '[User] Init',
  props<{
    payload: {
      accountId: number;
      getMpParams?: number;
      isLogin: boolean;
    };
  }>()
);

export const getUser = createAction('[App Init] Get User', props<{ payload: { id: number; accountId: number } }>());
export const getUserSuccess = createAction('[App Init] Get User Success', props<{ user: IUser }>());
export const getUserFail = createAction('[App Init] Get User Fail', props<{ error: any }>());
export const setUserIsPartnerSuccess = createAction(
  '[Login] Set User IsPartner Success',
  props<{ payload: { isPartner: number } }>()
);
export const setIsSysAdminSuccess = createAction(
  '[Login] Set IsSysAdmin Success',
  props<{ payload: { isSysAdmin: boolean } }>()
);
export const getUserPreferences = createAction(
  '[User] Get User Preferences',
  props<{ payload: { returnUrl: string } }>()
);
export const getUserPreferencesSuccess = createAction(
  '[App Init] Get User Preferences Success',
  props<{ payload: { userPreferences: UserPreferences } }>()
);
export const setUserPreferences = createAction(
  '[User] Set User Preferences',
  props<{ payload: { userPreferences: UserPreferences } }>()
);

export const getAccount = createAction('[User] Get Account', props<{ payload: { accountId: number } }>());

export const getAccountSuccess = createAction(
  '[User] Get Account Success',
  props<{ payload: { account: IAccount } }>()
);

export const getCountByAccount = createAction(
  '[User] Get Count by Account',
  props<{ payload: { accountId: number } }>()
);

export const getCountByAccountSuccess = createAction(
  '[User] Get Count by Account Success',
  props<{ payload: { count: number } }>()
);

export const getMp = createAction(
  '[User] Get Mp',
  props<{
    payload: { mpId: number; returnUrl: string };
  }>()
);

export const getMpSuccess = createAction(
  '[User] Get Mp Success',
  props<{
    payload: {
      mp: any;
      returnUrl?: string;
      from?: string;
    };
  }>()
);

export const setHeaderName = createAction(
  '[Header] Set Header Name',
  props<{ payload: { accountName: string; site: string; mpName: string; timezone?: string } }>()
);

export const setHeaderNameSuccess = createAction(
  '[Header] Set Header Name Success',
  props<{ payload: { headerName: string } }>()
);

export const setQubescanTypeModel = createAction(
  '[Qubescan] Set Type',
  props<{ payload: { type: string; model: string } }>()
);

export const setFirmwareVersionSuccess = createAction(
  '[User] Set Firmware Version Success',
  props<{
    payload: {
      firmwareVersion: string;
    };
  }>()
);

export const unauthorizedMp = createAction('[User] Unauthorize Mp', props<{ payload: { error: any; mpId: number } }>());

export const noMpId = createAction('[User] No Mp Id');

export const setAlarmChannel = createAction(
  '[User] Set Alarm Channel',
  props<{
    payload: {
      mpId: any;
    };
  }>()
);

export const setAlarmChannelSuccess = createAction(
  '[User] Set Alarm Channel Success',
  props<{
    payload: {
      channel: any;
    };
  }>()
);

export const updateUserPreferences = createAction(
  '[User] Update User Preferences',
  props<{
    payload: {
      property: { [keys: string]: any };
    };
  }>()
);

export const updateUserPreferencesSuccess = createAction('[User] Update User Preferences Success');

export const emptyActions = createAction('[User] No Action');

export const setPreviousUrl = createAction('[User] Set previous url', props<{ payload: { url: string } }>());

export const toogleLoginLoading = createAction(
  '[User] Toogle Login Loading',
  props<{ payload: { loginLoading: boolean } }>()
);

export const initViewerMode = createAction('[User] Init Viewer Mode');

export const setViewerMode = createAction('[User] Set Viewer Mode', props<{ payload: { viewer: boolean } }>());

export const removeMp = createAction('[User] Remove Mp');

export const startCommissionning = createAction(
  '[User] Start Mp Commissionning',
  props<{ payload: { mpId: number } }>()
);

export const setMpStatusSuccess = createAction('[User] Set Mp Status Success', props<{ payload: { mp: any } }>());

export const setUnseenReports = createAction(
  '[User] Set New Available Report',
  props<{ payload: { reportResultIds: Array<number> } }>()
);

export const addUnseenReport = createAction(
  '[User] Add New Available Report',
  props<{ payload: { reportResultId: number } }>()
);

export type UserActionsUnion = ReturnType<
  | typeof initUser
  | typeof getUser
  | typeof getUserFail
  | typeof getUserSuccess
  | typeof setUserIsPartnerSuccess
  | typeof setIsSysAdminSuccess
  | typeof getUserPreferences
  | typeof getUserPreferencesSuccess
  | typeof setUserPreferences
  | typeof getAccount
  | typeof getAccountSuccess
  | typeof getCountByAccount
  | typeof getCountByAccountSuccess
  | typeof getMp
  | typeof getMpSuccess
  | typeof setHeaderName
  | typeof setHeaderNameSuccess
  | typeof setQubescanTypeModel
  | typeof unauthorizedMp
  | typeof noMpId
  | typeof setFirmwareVersionSuccess
  | typeof setAlarmChannel
  | typeof setAlarmChannelSuccess
  | typeof updateUserPreferences
  | typeof emptyActions
  | typeof setPreviousUrl
  | typeof toogleLoginLoading
  | typeof initViewerMode
  | typeof setViewerMode
  | typeof removeMp
  | typeof startCommissionning
  | typeof setMpStatusSuccess
  | typeof setUnseenReports
  | typeof addUnseenReport
>;
