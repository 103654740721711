import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-filter-menu',
  templateUrl: './filter-menu.component.html',
  styleUrls: ['./filter-menu.component.scss'],
})
export class FilterMenuComponent implements OnInit {
  @Input() choices: Array<{ title: string }>;

  checkboxForm: FormGroup;
  isSelectAll: boolean = true;
  indeterminate: boolean = false;
  filteredChoices: Array<{ title: string }>;
  filterOn: boolean = false;
  selectionLength: number;
  choiceStartLength: number;
  originalChoice: Array<{ title: string }>;

  @Output() emitFilterChange = new EventEmitter();

  ngOnInit(): void {
    if (!this.originalChoice) {
      this.originalChoice = this.choices;
    } else {
      this.choices = this.originalChoice;
    }
    this.isSelectAll = true;
    this.indeterminate = false;
    this.filteredChoices = this.choices;
    this.choiceStartLength = this.choices.length;
    const group: any = {};

    this.choices.forEach((choice) => {
      group[choice.title] = new FormControl(true);
    });

    this.checkboxForm = new FormGroup(group);
  }

  onInputChange(value: string): void {
    if (value === '') {
      this.filterOn = false;
    } else {
      this.filterOn = true;
    }
    this.filteredChoices = this.choices.filter((choice) => {
      if (choice.title) {
        return choice.title.includes(value);
      }
      return false;
    });
    let check = false;
    let uncheck = false;
    this.filteredChoices.forEach((choice) => {
      if (!check) {
        check = this.checkboxForm.controls[choice.title].value === true;
      }
      if (!uncheck) {
        uncheck = this.checkboxForm.controls[choice.title].value === false;
      }
    });

    if (check && uncheck) {
      this.indeterminate = true;
      this.isSelectAll = false;
    } else if (check) {
      this.isSelectAll = true;
      this.indeterminate = false;
    } else if (uncheck) {
      this.isSelectAll = false;
      this.indeterminate = false;
    }
  }

  openFilter(event): void {
    event.stopPropagation();
  }

  checkboxChange(): void {
    let valueArray = Object.values(this.checkboxForm.value);
    if (valueArray.includes(false) && valueArray.includes(true)) {
      this.indeterminate = true;
      this.isSelectAll = false;
    } else if (!valueArray.includes(true)) {
      this.indeterminate = false;
      this.isSelectAll = false;
    } else {
      this.indeterminate = false;
      this.isSelectAll = true;
    }
    let keys = [];
    Object.entries(this.checkboxForm.value).forEach(([key, value]) => {
      if (value) {
        keys.push(key);
      }
    });
    this.selectionLength = keys.length;
    this.emitFilterChange.emit(keys);
  }

  selectAll(): void {
    this.indeterminate = false;
    this.isSelectAll = !this.isSelectAll;
    let newValue = false;
    if (this.isSelectAll) {
      newValue = true;
    }
    this.filteredChoices.forEach((choice) => {
      this.checkboxForm.controls[choice.title].patchValue(newValue);
    });
    this.emitFilterChange.emit([]);
  }
}
