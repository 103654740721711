import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { take } from 'rxjs';

import { MeasurementPointsService } from 'src/app/_shared/services/measurement-points.service';
import { MaintenanceService } from 'src/app/_shared/services/maintenance.service';
import { DiligentApiService } from 'src/app/_shared/services';
import { DocumentService } from 'src/app/_shared/modules/document/document.service';
import { NotificationsService } from 'src/app/_shared/modules/notifications/shared/notifications.service';
import { ReplacementStep } from 'src/app/_shared/interface/replacement-step.interface';
import { AssociateMpComponent } from 'src/app/fleet-overview/fleet/associate-mp/associate-mp.component';

@Component({
  selector: 'app-replace-pqube',
  templateUrl: './replace-pqube.component.html',
  styleUrls: ['./replace-pqube.component.scss'],
})
export class ReplacePqubeComponent {
  @Input() mpId: number;
  @Input() step: ReplacementStep;
  @Input() mp;
  @Output() disassociateEvent: EventEmitter<string> = new EventEmitter();
  @Output() associateEvent: EventEmitter<string> = new EventEmitter();
  @Output() updateStep: EventEmitter<ReplacementStep> = new EventEmitter();

  private iniFileName: string;
  private noConfig: boolean;
  iniFileContent: string;

  constructor(
    private mpService: MeasurementPointsService,
    private maintenanceService: MaintenanceService,
    private diligentService: DiligentApiService,
    private documentService: DocumentService,
    private notificationsService: NotificationsService,
    private translateService: TranslateService,
    private dialog: MatDialog,
    public changeDetection: ChangeDetectorRef
  ) {}

  startReplacement(): void {
    this.getIniFile().then((res) => {
      if (res) {
        const iniFile = new Blob([res], { type: 'text' });
        let url = window.URL.createObjectURL(iniFile);
        this.documentService.downloadURL(url, this.iniFileName);
        window.URL.revokeObjectURL(url);

        this.iniFileContent = res;
      } else {
        this.notificationsService.notify(this.translateService.instant('replacePqube.noIniFile'));
      }
    });
    if (this.step === null || this.step?.steps[0].step === 4) {
      this.mpService
        .startReplacement(this.mpId)
        .pipe(take(1))
        .subscribe((res) => {
          this.step = res;
          this.changeDetection.detectChanges();
        });
    }
  }

  disassociate(): void {
    this.completeStep(1);
    this.disassociateEvent.emit('disassociate');
  }

  confirmInstall(): void {
    this.completeStep(2);
  }

  openAssociation(): void {
    this.dialog
      .open(AssociateMpComponent, {
        data: {
          point: this.mp,
        },
      })
      .afterClosed()
      .subscribe((success) => {
        if (success) {
          this.completeStep(3);
          this.notificationsService.notify(this.translateService.instant('manageSiteMp.associate.success'));
          this.associateEvent.emit('associate');
        }
      });
  }

  private completeStep(step: number): void {
    this.mpService
      .completeStep(this.step.id, step)
      .pipe(take(1))
      .subscribe((res) => {
        this.step = res;
        this.updateStep.emit(this.step);
        this.changeDetection.detectChanges();
      });
  }

  private getIniFile(): Promise<any> {
    return this.maintenanceService
      .getSetupINI(this.mpId)
      .then((url) => {
        this.iniFileName = url.split('/')[6].substring(0, url.split('/')[6].indexOf('.gzip'));
        return this.diligentService.forceDownloadText(url);
      })
      .catch((error) => {
        this.noConfig = true;
      });
  }
}
