import { createAction, props } from '@ngrx/store';

export const startPollingValue = createAction(
  '[Qubescan-Dashboard] Start Polling Values',
  props<{ payload: { chartSetName: string; timezone: string; mpStatus: number; mpId: number } }>()
);

export const setValueSucces = createAction(
  '[Qubescan-Dashboard] Set Value Success',
  props<{ payload: { qubescanValue: any } }>()
);

export const setValueFail = createAction('[Qubescan-Dashboard] Set Value Fail', props<{ payload: { error: any } }>());

export const resetError = createAction('[Qubescan-Dashboard] Reset Error');

export const setChannelValueSuccess = createAction(
  '[Qubescan-Dashboard] Set Channel Value Success',
  props<{ payload: { qubescanChannelValue: any } }>()
);

export const keepPolling = createAction('[Qubescan-Dashboard] Keep calling API every min');

export const keepPollingValue = createAction('[Qubescan-Dashboard] Keep calling value API every min');

export const polling1Min = createAction('[Qubescan-Dashboard] Call API every minute');

export const polling1MinValue = createAction('[Qubescan-Dashboard] Call last trend API every minute');

export const polling10Min = createAction('[Qubescan-Dashboard] Call API every 10 minutes');

export const polling10MinValue = createAction('[Qubescan-Dashboard] Call value API every 10 minutes');

export const setLastCommunication = createAction(
  '[Qubescan-Dashboard] Set Last Communication',
  props<{ payload: { lastCommunication: string } }>()
);

export const destroyedDasboard = createAction('[Qubescan-Dashboard] Destroyed Dashboard');

export const setChannel = createAction(
  '[Qubescan-Dashboard] Set Channel',
  props<{ payload: { qubescanChannel: any; mpId: number } }>()
);

export const updateChannel = createAction(
  '[Qubescan-Dashboard] Update Channel',
  props<{ payload: { qubescanChannel: any; index: number } }>()
);

export const updateChannelSuccess = createAction(
  '[Qubescan-Dashboard] Update Channel Success',
  props<{ payload: { qubescanChannel: any } }>()
);

export const updateIndexSuccess = createAction(
  '[Qubescan-Dashboard] Update Index Success',
  props<{ payload: { index: number } }>()
);

export const getLastTrend = createAction('[Qubescan-Dashboard] Get Last Trend');

export const getKPI = createAction('[Qubescan-Dashboard] Get KPI', props<{ payload: { mpId: number } }>());

export const getKPISuccess = createAction('[Qubescan-Dashboard] Get KPI Success', props<{ payload: { kpi: any } }>());

export type QubescanDashboardActionsUnion = ReturnType<
  | typeof startPollingValue
  | typeof polling1Min
  | typeof polling1MinValue
  | typeof polling10Min
  | typeof polling10MinValue
  | typeof setLastCommunication
  | typeof destroyedDasboard
  | typeof setValueSucces
  | typeof setValueFail
  | typeof resetError
  | typeof setChannelValueSuccess
  | typeof setChannel
  | typeof updateChannel
  | typeof updateChannelSuccess
  | typeof getLastTrend
  | typeof getKPI
  | typeof getKPISuccess
>;
