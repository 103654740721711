import { mergeMap, switchMap, takeUntil } from 'rxjs/operators';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { timer } from 'rxjs';

import { MeasurementPointsService } from 'src/app/_shared/services/measurement-points.service';
import { DrivescanDashboard } from 'src/app/_shared/interface/drivescan/drivescan-dashboard.interface';
import { EventCount } from 'src/app/_shared/interface/events/event-count.interface';
import { DrivescanDashboardActions } from 'src/app/_store/_drivescan-dashboard/actions';
import { DrivescanValue } from 'src/app/_shared/interface/drivescan/drivescan-value.interface';
import {
  DrivescanACInputChannels,
  DrivescanEventsChannels,
  DrivescanS09Channels,
  DrivescanS12Channels,
  DrivescanS15Channels,
  DrivescanT02Channels,
  DrivescanT06Channels,
  DrivescanT08Channels,
  DrivescanLV1Channels,
} from 'src/app/_shared/config/drivescan-dashboard/drivescan-api.config';
import { DriveFaultState } from 'src/app/_shared/interface/drivescan/drive-fault-code.interface';

@Injectable()
export class DrivescanDashboardEffects {
  startPolling$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DrivescanDashboardActions.startPollingValue.type),
      mergeMap((action) => {
        this.mpId = action.payload.mpId;
        this.driveInfoModel = action.payload.driveInfoModel;
        this.changeMp = true;
        return [
          DrivescanDashboardActions.polling1MinEvents(),
          DrivescanDashboardActions.polling1Min(),
          DrivescanDashboardActions.polling1MinEventsChannels(),
          DrivescanDashboardActions.polling1MinDriveFault(),
          DrivescanDashboardActions.polling1MinHeartbeat(),
        ];
      })
    )
  );

  start1MinPolling$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DrivescanDashboardActions.polling1Min.type),
      switchMap(() => {
        return timer(1, 60000).pipe(
          switchMap(() => {
            if (this.driveInfoModel === 'T02') {
              this.channels = DrivescanACInputChannels.concat(DrivescanT02Channels);
            } else if (this.driveInfoModel === 'T06') {
              this.channels = DrivescanACInputChannels.concat(DrivescanT06Channels);
            } else if (this.driveInfoModel === 'T08') {
              this.channels = DrivescanACInputChannels.concat(DrivescanT08Channels);
            } else if (this.driveInfoModel === 'S09') {
              this.channels = DrivescanACInputChannels.concat(DrivescanS09Channels);
            } else if (this.driveInfoModel === 'S12') {
              this.channels = DrivescanACInputChannels.concat(DrivescanS12Channels);
            } else if (this.driveInfoModel === 'S15') {
              this.channels = DrivescanACInputChannels.concat(DrivescanS15Channels);
            } else if (this.driveInfoModel === 'LV01') {
              this.channels = DrivescanACInputChannels.concat(DrivescanLV1Channels);
            } else {
              this.channels = DrivescanACInputChannels;
            }
            return this.mpService.getLastMpTrends(this.mpId, this.channels, 'oneminute').pipe(
              mergeMap((drivescanValue: DrivescanDashboard) => {
                drivescanValue = this.removeSpecialCharacter(drivescanValue);
                if (this.timeStamp !== drivescanValue.time_stamp || this.changeMp) {
                  if (this.timeStamp && !this.changeMp) {
                    return [
                      DrivescanDashboardActions.setValueSucces({
                        payload: {
                          drivescanValue: { ...drivescanValue },
                        },
                      }),
                      DrivescanDashboardActions.polling10Min(),
                    ];
                  }
                  this.timeStamp = drivescanValue.time_stamp;
                  this.changeMp = false;
                  return [
                    DrivescanDashboardActions.setValueSucces({
                      payload: {
                        drivescanValue: { ...drivescanValue },
                      },
                    }),
                  ];
                } else {
                  return [DrivescanDashboardActions.keepPolling()];
                }
              })
            );
          }),
          takeUntil(
            this.actions$.pipe(
              ofType(DrivescanDashboardActions.polling10Min, DrivescanDashboardActions.destroyedDasboard)
            )
          )
        );
      })
    )
  );

  start10MinPolling$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DrivescanDashboardActions.polling10Min.type),
      switchMap((action) => {
        return timer(10 * 60000, 10 * 60000).pipe(
          switchMap(() =>
            this.mpService.getLastMpTrends(this.mpId, this.channels, 'oneminute').pipe(
              mergeMap((drivescanValue: DrivescanDashboard) => {
                drivescanValue = this.removeSpecialCharacter(drivescanValue);
                if (this.timeStamp !== drivescanValue.time_stamp) {
                  this.timeStamp = drivescanValue.time_stamp;
                  return [
                    DrivescanDashboardActions.setValueSucces({
                      payload: {
                        drivescanValue: { ...drivescanValue },
                      },
                    }),
                  ];
                } else {
                  return [DrivescanDashboardActions.polling1Min()];
                }
              })
            )
          ),
          takeUntil(
            this.actions$.pipe(
              ofType(DrivescanDashboardActions.polling1Min.type, DrivescanDashboardActions.destroyedDasboard)
            )
          )
        );
      })
    )
  );

  start1MinPollingEvents$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DrivescanDashboardActions.polling1MinEvents.type),
      switchMap(() => {
        return timer(1, 60000).pipe(
          switchMap(() => {
            return this.mpService
              .getEventsCount(this.mpId, [
                'voltageSag',
                'voltageSwell',
                'interruption',
                'highFrequencyImpulse',
                'driveFault',
              ])
              .pipe(
                mergeMap((drivescanEvents: Array<EventCount>) => {
                  const sags = drivescanEvents.find((event) => event.name === 'voltageSag').eventCount;
                  const swells = drivescanEvents.find((event) => event.name === 'voltageSwell').eventCount;
                  const interruptions = drivescanEvents.find((event) => event.name === 'interruption').eventCount;
                  const hfImpulses = drivescanEvents.find((event) => event.name === 'highFrequencyImpulse').eventCount;
                  const driveFault = drivescanEvents.find((event) => event.name === 'driveFault').eventCount;

                  return [
                    DrivescanDashboardActions.setEventsSucces({
                      payload: {
                        drivescanEvents: {
                          sags,
                          swells,
                          interruptions,
                          hfImpulses,
                          driveFault,
                        },
                      },
                    }),
                  ];
                })
              );
          }),
          takeUntil(this.actions$.pipe(ofType(DrivescanDashboardActions.destroyedDasboard)))
        );
      })
    )
  );

  start1MinPollingEventsChannels$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DrivescanDashboardActions.polling1MinEventsChannels.type),
      switchMap(() => {
        return timer(1, 60000).pipe(
          switchMap(() => {
            return this.mpService.getLastMpTrends(this.mpId, DrivescanEventsChannels, 'oneminute').pipe(
              mergeMap((drivescanEventsChannels: DrivescanValue) => {
                const removeSpecialCharacName: any = {
                  c_287_avg_p: drivescanEventsChannels['c_287_avg_%'],
                  c_288_avg_p: drivescanEventsChannels['c_288_avg_%'],
                  c_21_avg_hz: drivescanEventsChannels.c_21_avg_hz,
                  c_1681_avg_v: drivescanEventsChannels.c_1681_avg_v,
                };
                return [
                  DrivescanDashboardActions.setEventsChannelsSucces({
                    payload: {
                      drivescanEventsChannels: removeSpecialCharacName,
                    },
                  }),
                ];
              })
            );
          }),
          takeUntil(this.actions$.pipe(ofType(DrivescanDashboardActions.destroyedDasboard)))
        );
      })
    )
  );

  startPollingDriveFaultState$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DrivescanDashboardActions.polling1MinDriveFault.type),
      switchMap(() => {
        return timer(1, 60000).pipe(
          switchMap(() => {
            return this.mpService.getDriveFaultState(parseInt(this.mpId, 10)).pipe(
              mergeMap((driveFaultState: DriveFaultState) => {
                return [
                  DrivescanDashboardActions.setDriveFaultStateSucces({
                    payload: {
                      driveFaultState: driveFaultState,
                    },
                  }),
                ];
              })
            );
          }),
          takeUntil(this.actions$.pipe(ofType(DrivescanDashboardActions.destroyedDasboard)))
        );
      })
    )
  );

  startPollingHB$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DrivescanDashboardActions.polling1MinHeartbeat.type),
      switchMap(() => {
        return timer(1, 60000).pipe(
          switchMap(() => {
            return this.mpService.getHeartbeat(parseInt(this.mpId)).pipe(
              mergeMap((heartbeat) => {
                return [
                  DrivescanDashboardActions.setHeartbeatSucces({
                    payload: {
                      heartbeat: { ...heartbeat[0] },
                    },
                  }),
                ];
              })
            );
          }),
          takeUntil(this.actions$.pipe(ofType(DrivescanDashboardActions.destroyedDasboard)))
        );
      })
    )
  );

  constructor(
    private actions$: Actions<DrivescanDashboardActions.DrivescanDashboardActionsUnion>,
    private mpService: MeasurementPointsService
  ) {}

  private mpId: string;
  private timeStamp: string;
  private driveInfoModel: string;
  private channels: Array<string>;
  private changeMp: boolean;

  private removeSpecialCharacter(drivescanValue: DrivescanDashboard): DrivescanDashboard {
    const drivescanReturnValue = Object.keys(drivescanValue).map((key) => {
      let name = key;
      if (key.indexOf('%') !== -1 && key !== 'c_1231_avg_%rh') {
        name = key.slice(0, -1) + 'p';
      } else if (key === 'c_1231_avg_%rh') {
        name = 'c_1231_avg_prh';
      } else if (key.indexOf('°') !== -1) {
        name = key.slice(0, -2) + 'degc';
      }
      return { [name]: drivescanValue[key] };
    });
    return Object.assign({}, ...drivescanReturnValue);
  }
}
