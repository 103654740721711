export const popoverIcons = {
  voltageSag: 'majorSags.svg',
  voltageSwell: 'swellEvents.svg',
  interruption: 'interruptions.svg',
  highFrequencyImpulse: 'hfImpulses.svg',
  restart: 'restart.svg',
  rvc: 'rvc.svg',
  waveShapeChange: 'waveshape.svg',
  snapshot: 'circle.svg',
  Note: 'calendar.svg',
};
