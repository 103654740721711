<mat-toolbar id="header">
  <div class="menu-logo-wrapper">
    <button mat-icon-button class="mobile-menu-icon" (click)="toggleMobileMenu()">
      <mat-icon [svgIcon]="mobileMenuOpen ? 'close' : 'menu'"></mat-icon>
    </button>
    <div class="logo">
      <img src="./assets/images/psl-logo.svg" />
    </div>
  </div>
  <div class="premium-feature"
       *ngIf="((firmwareUpdateFeature$ | async).navButtonVisible || (notificationCenterFeature$ | async).navButtonVisible)">
    @if ((firmwareUpdateFeature$ | async).navButtonVisible) {
      <button
        mat-icon-button
        eventTracking="header.firmwareUpdate"
        matTooltip="{{ 'global.firmwareUpdate' | translate }}"
        [routerLink]="['/admin']"
        data-cy="systems"
        #overlayOriginFirmware="cdkOverlayOrigin"
        cdkOverlayOrigin
      >
        <mat-icon svgIcon="firmware-update"></mat-icon>
      </button>
      @defer {
        <app-hint-box
          [hint]="'firmwareUpdate'"
          [overlayOrigin]="overlayOriginFirmware"
          [isOpenedInput]="hintFirmwareIsOpened"
          (closeHint)="hintFirmwareIsOpened = false"
          (openHint)="hintFirmwareIsOpened = true"
        >
          {{ 'hint.firmwareUpdate' | translate }}
        </app-hint-box>
      }
    }
    @if ((notificationCenterFeature$ | async).navButtonVisible) {
    <button
      eventTracking="header.notificationCenter"
      mat-icon-button
      matTooltip="{{ 'global.notificationCenter' | translate }}"
      [routerLink]="['/notification']"
      data-cy="notification-center"
      #overlayOriginNotification="cdkOverlayOrigin"
      cdkOverlayOrigin
    >
      <mat-icon svgIcon="bell-cog-outline"></mat-icon>
    </button>
      @defer {
      <app-hint-box
        [hint]="'notificationCenter'"
        [overlayOrigin]="overlayOriginNotification"
        [isOpenedInput]="hintNotificationIsOpened"
        (closeHint)="hintNotificationIsOpened = false"
        (openHint)="hintNotificationIsOpened = true"
      >
        {{ 'hint.notificationCenter' | translate }}
      </app-hint-box>
      }
    }
  </div>

  @if (showMp) {
  <div class="current-selection-wrapper">
    @if ((canSelectMp | async) && mpId) {
    <button
      data-cy="customer-contact"
      [disabled]="viewerMode$ | async"
      mat-icon-button
      matTooltip="{{ 'manageCustomerAccount.tooltip.customerInfo' | translate }}"
      [color]="(viewerMode$ | async) ? '' : 'accent'"
      (click)="navigateManageMember('/manage-members/customer', mp.accountId)"
    >
      <mat-icon svgIcon="account-multiple"></mat-icon>
    </button>
    } @if (mpId) {
    <div
      data-cy="current-account"
      class="current-selection"
      [matTooltip]="headerName$ | async"
      [matTooltipPosition]="'below'"
    >
      {{ headerName$ | async }}
    </div>
    }
    <button mat-stroked-button data-cy="change-mp-button" class="change-mp-button" (click)="changeSelection()">
      {{ 'header.change-selection' | translate }}
    </button>
  </div>
  }
  <div class="buttons">
    @if ((firmwareUpdateFeature$ | async).navButtonVisible || (notificationCenterFeature$ | async).navButtonVisible) {
    <button
      mat-icon-button
      [matMenuTriggerFor]="adminMenu"
      class="admin-menu-button"
      #overlayOriginFirmwareAndNotification="cdkOverlayOrigin"
      cdkOverlayOrigin
    >
      <mat-icon color="primary" svgIcon="firmware-update"></mat-icon>
    </button>
    @defer {
    <app-hint-box
      [hint]="'firmwareAndNotification'"
      [overlayOrigin]="overlayOriginFirmwareAndNotification"
      [isOpenedInput]="hintFirmwareAndNotificationIsOpened"
      (closeHint)="hintFirmwareAndNotificationIsOpened = false"
      (openHint)="hintFirmwareAndNotificationIsOpened = true"
      >{{ 'hint.firmwareAndNotification' | translate }}</app-hint-box
    >
    } } @if (showMp) {
    <button
      mat-icon-button
      (click)="changeSelection()"
      data-cy="change-mp-button-mobile"
      class="change-mp-mobile-button"
    >
      <img src="./assets/images/change-selection-icon.png" />
    </button>
    }
    <button #menuButton mat-icon-button [matMenuTriggerFor]="userMenu" data-cy="user-menu" class="menu-button">
      <mat-icon color="primary" class="desktop-menu-button-icon" svgIcon="account"></mat-icon>
      <mat-icon class="mobile-menu-button-icon" svgIcon="account-cog"></mat-icon>
    </button>
    <mat-menu #userMenu="matMenu" xPosition="before" class="user-menu">
      <button data-cy="contacts" class="contacts-menu-button" mat-menu-item [matMenuTriggerFor]="contactMenu">
        {{ 'header.helpSupport' | translate }}
      </button>
      <button mat-menu-item [routerLink]="['/profile']" (click)="menuClick()" eventTracking="header.editMyProfile">
        {{ 'header.edit-profile' | translate }}
      </button>
      @if (isAdministrator || (isEngineer && isPartner)) {
      <button data-cy="manage-members" mat-menu-item (click)="navigateManageMember('/manage-members', userAccount?.id)">
        @if (isAdministrator) {
        <span>
          {{ 'global.manage-members' | translate }}
        </span>
        } @if (!isAdministrator) {
        <span>
          {{ 'global.view-members' | translate }}
        </span>
        }
      </button>
      }
      <mat-divider></mat-divider>
      <button data-cy="privacy-policy" mat-menu-item (click)="openPrivacyPolicy()">
        {{ 'header.privacyPolicy' | translate }}
      </button>
      <mat-divider></mat-divider>
      <button data-cy="logout" mat-menu-item (click)="logout()" eventTracking="header.logout">
        {{ 'header.logout' | translate }}
      </button>
    </mat-menu>
    <mat-menu #adminMenu="matMenu" class="user-menu">
      <button mat-menu-item [routerLink]="['/notification']" (click)="menuClick()">
        {{ 'global.notificationCenter' | translate }}
      </button>
      <button data-cy="systems" mat-menu-item [routerLink]="['/admin']" (click)="menuClick()">
        {{ 'global.firmwareUpdate' | translate }}
      </button>
    </mat-menu>
    <button
      mat-icon-button
      data-cy="help-button"
      [matTooltip]="'header.helpSupport' | translate"
      class="menu-button contacts-button"
      [matMenuTriggerFor]="contactMenu"
    >
      <mat-icon color="primary" svgIcon="help-circle"></mat-icon>
    </button>
    <mat-menu #contactMenu="matMenu" class="contacts-menu-wrapper">
      <div class="help-support">
        <mat-icon svgIcon="help-circle-outline"></mat-icon>
        <strong>{{ 'header.contact-info.help-support' | translate }}</strong>
      </div>
      <div class="menu-link menu-link-first" (click)="openWelcomeMessage()">
        <span>
          {{ 'welcome.menu' | translate }}
        </span>
        <mat-icon svgIcon="chevron-right"></mat-icon>
      </div>
      <div class="menu-link menu-link-last" (click)="openReleaseNews()">
        <span>
          {{ 'news.whats-new' | translate }}
        </span>
        <mat-icon svgIcon="chevron-right"></mat-icon>
      </div>
      <div class="contact-info">
        <div>
          <strong>{{ 'header.contact-info.contact-powerside' | translate }}:</strong>
        </div>
        <div>
          {{ 'global.email' | translate }}:
          <a href="mailto:support@powerside.com">support&#64;powerside.com</a>
        </div>
        <div>{{ 'global.phone' | translate }}: 1-877-333-8392</div>
      </div>
      <div class="version-info">
        {{ 'global.version' | translate : { version: version } }}
      </div>
    </mat-menu>
  </div>
</mat-toolbar>
<app-loader [loading]="loading" [message]="'onBoarding.waitingForChannel' | translate"></app-loader>
