<div class="move-to-table">
  <mat-table [dataSource]="dataSource" class="mat-elevation-z1">
    <mat-radio-group [(ngModel)]="checkboxAccountId">
      <ng-container matColumnDef="partner">
        <mat-header-cell *matHeaderCellDef> {{ 'global.partner' | translate }} </mat-header-cell>
        <mat-cell *matCellDef="let row"></mat-cell>
      </ng-container>
      <ng-container matColumnDef="customer">
        <mat-header-cell *matHeaderCellDef> {{ 'global.customer' | translate }} </mat-header-cell>
        <mat-cell class="customer-cell" *matCellDef="let row">
          <mat-radio-button value="{{ row['accountId'] }}">{{ row['customer'] }}</mat-radio-button>
        </mat-cell>
      </ng-container>
    </mat-radio-group>
    <mat-header-row mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
    <ng-container matColumnDef="groupHeader">
      <mat-cell class="partner-label" colspan="999" *matCellDef="let group">
        @if (group.expanded) {
        <mat-icon>expand_less</mat-icon>
        } @if (!group.expanded) {
        <mat-icon>expand_more</mat-icon>
        }
        <strong>{{ group[groupByColumns[group.level - 1]] }} ({{ group.totalCounts }})</strong>
      </mat-cell>
    </ng-container>
    <mat-row *matRowDef="let row; columns: ['groupHeader']; when: isGroup" (click)="groupHeaderClick(row)"> </mat-row>
  </mat-table>
  <div class="move-to-button">
    <button mat-flat-button color="accent" (click)="moveTo()" [disabled]="checkboxAccountId === undefined">
      {{ 'moveTo.move' | translate }}
    </button>
  </div>
</div>
@if (loading) {
<div class="loader">
  <mat-spinner diameter="48" color="primary"></mat-spinner>
</div>
}
