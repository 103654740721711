import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'maxUnit',
})
export class MaxUnitPipe implements PipeTransform {
  transform(value: number, ...args: any[]): string | number {
    if (!value || args.indexOf(true) !== -1) {
      return '-';
    } else {
      let max = Math.abs(Math.max(args[0], args[1], args[2]));
      if (max / 1000 < 1) {
        return value;
      } else if (max / 1000000 < 1) {
        return (value / 1000).toFixed(3);
      } else {
        return (value / 1000000).toFixed(3);
      }
    }
  }
}
