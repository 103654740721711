export const DrivescanEventsChannels = ['c_287_avg_%', 'c_288_avg_%', 'c_21_avg_hz', 'c_1681_avg_v'];

export const DrivescanACInputChannels = [
  'c_9_avg_w',
  'c_7_avg_v',
  'c_8_avg_v',
  'c_9_avg_v',
  'c_16_avg_a',
  'c_17_avg_a',
  'c_18_avg_a',
  'c_1230_avg_°c',
  'c_1231_avg_%rh',
  'c_10_avg_v',
  'c_11_avg_v',
  'c_12_avg_v',
  'pc_7_avg_%',
  'pc_8_avg_%',
  'c_23_avg_a',
  'c_24_avg_a',
  'c_25_avg_a',
  'c_70_avg_w',
  'c_1674_avg_v',
  'c_1675_avg_v',
  'c_1676_avg_v',
  'c_1677_avg_v',
  'c_1678_avg_v',
  'c_1679_avg_v',
  'c_1680_avg_v',
];

export const DrivescanT02Channels = ['c_40_avg_v', 'c_41_avg_v', 'c_26_avg_%', 'c_27_avg_%'];

export const DrivescanT06Channels = DrivescanT02Channels.concat([
  'c_42_avg_v',
  'c_43_avg_v',
  'c_44_avg_v',
  'c_45_avg_v',
  'c_28_avg_%',
  'c_29_avg_%',
  'c_30_avg_%',
  'c_31_avg_%',
]);

export const DrivescanT08Channels = DrivescanT06Channels.concat([
  'c_1651_avg_v',
  'c_1652_avg_v',
  'c_1642_avg_%',
  'c_1643_avg_%',
]);

export const DrivescanS09Channels = DrivescanT06Channels.concat([
  'c_1651_avg_v',
  'c_1652_avg_v',
  'c_1653_avg_v',
  'c_1642_avg_%',
  'c_1643_avg_%',
  'c_1644_avg_%',
]);

export const DrivescanS12Channels = DrivescanS09Channels.concat([
  'c_1654_avg_v',
  'c_1655_avg_v',
  'c_1656_avg_v',
  'c_1645_avg_%',
  'c_1646_avg_%',
  'c_1647_avg_%',
]);

export const DrivescanS15Channels = DrivescanS12Channels.concat([
  'c_1657_avg_v',
  'c_1658_avg_v',
  'c_1659_avg_v',
  'c_1648_avg_%',
  'c_1649_avg_%',
  'c_1650_avg_%',
]);

export const DrivescanLV1Channels = ['c_40_avg_v', 'c_26_avg_%', 'c_13_avg_v'];
