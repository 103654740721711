interface Count {
    count: number;
}

interface RecommendationCountClosed extends Count {
    month: string;
    year: string;
}

export interface ClosedRecommendationMap {[month: string]: RecommendationCountClosed; }

export interface RecommendationCount {
    active: Count;
    closed: RecommendationCountClosed[];
}

export enum RecommendationImportanceEnum {
    HIGH = 1,
    MEDIUM = 2,
    LOW = 3,
}

export enum RecommendationStatusEnum {
    PROPOSED = 1,
    POSTPONED = 2,
    REJETED = 3,
    UNDER_INVESTIGATION = 4,
    UNDER_IMPLEMENTATION = 5,
    IMPLEMENTED = 6,
}

const activeRecommendationStatus = [RecommendationStatusEnum.PROPOSED, RecommendationStatusEnum.UNDER_INVESTIGATION, RecommendationStatusEnum.UNDER_IMPLEMENTATION];


export interface SendRecommendationResponse {
    message: string;
}

export interface IRecommendation {
    id: number;
    summary: string;
    statusId: RecommendationStatusEnum;
    actionTaken: string;
    status: string;
    importanceId: RecommendationImportanceEnum;
    importance: string;
    createdWhen: Date;
    modifiedWhen: Date;
    closedWhen: Date;
}

export function isRecommendationActive(recommendation: IRecommendation): boolean {
    return activeRecommendationStatus.indexOf(recommendation.statusId) > -1;
}

export function getClosedRecommendationMap(closedRecommendations: RecommendationCountClosed[]): ClosedRecommendationMap {
    return Object.assign({}, ...(closedRecommendations.map(cr => ({[(+cr.month - 1)]: cr}))));
}

export interface Comment {
    comment: string;
}

export interface RecommendationComment extends Comment {
    id: number;
    createdById: number;
    createdByFirstName: string;
    createdByLastName: string;
    createdWhen: Date;
    modifiedById: number;
    modifiedByFirstName: string;
    modifiedByLastName: string;
    modifiedWhen: Date;
}