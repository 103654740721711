import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar, MatSnackBarConfig, MatSnackBarRef, SimpleSnackBar } from '@angular/material/snack-bar';
import { select, Store } from '@ngrx/store';
import { take, map } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { NotificationModalComponent } from 'src/app/_shared/modules/notifications/modal-dialog/notification-modal.component';
import * as fromStore from 'src/app/_store/_reducers';

interface NotificationsServiceOptions {
  dialogId?: string;
  closePermanentlyCheckbox?: boolean;
  [key: string]: any;
  showRequestUpgrade?: boolean;
  accountId?: number;
}

@Injectable()
export class NotificationsService {
  private configDefaults: MatSnackBarConfig = new MatSnackBarConfig();

  public alerts = 0;

  constructor(private snackbar: MatSnackBar, private dialog: MatDialog, private store: Store<fromStore.State>) {
    this.configDefaults.verticalPosition = 'bottom';
    this.configDefaults.duration = 4000;
  }

  notify(message: string, action?: string, config?: MatSnackBarConfig): MatSnackBarRef<SimpleSnackBar> | null {
    config = { ...this.configDefaults, ...config };
    return this.snackbar.open(message, action, config);
  }

  alert(
    message: string,
    title?: string,
    icon?: string,
    buttonCaption?: string,
    width: string = '40%',
    options?: NotificationsServiceOptions
  ): MatDialogRef<NotificationModalComponent> {
    let dialogRef: MatDialogRef<NotificationModalComponent>;
    const dataObj: any = {
      message: message,
      material_ligature: icon ? icon : '',
      buttons: [
        {
          caption: buttonCaption || 'Ok',
          callback: (): void => {
            dialogRef.close(true);
          },
          material_ligature: 'check_circle',
          color: 'accent',
        },
      ],
      ...options,
    };

    // so it's not automatically overridden
    if (title) {
      dataObj.title = title;
    }

    let defaultWidth = '40%';

    if (screen.width <= 768) {
      defaultWidth = '90%';
      if (width === '40%') {
        width = defaultWidth;
      }
    }

    dialogRef = this.dialog.open(NotificationModalComponent, {
      panelClass: 'notification',
      width: width || defaultWidth,
      data: dataObj,
    });

    this.alerts++;

    dialogRef.afterClosed().subscribe(() => {
      this.alerts--;
    });

    return dialogRef;
  }

  confirm(
    message: string,
    title: string = 'Are you sure?',
    buttons?: object[],
    width: string = '40%',
    options?: object
  ): MatDialogRef<NotificationModalComponent> {
    let dialogRef: any = {};
    const dataObj: any = {
      title: title,
      message: message,
      material_ligature: 'help',
      buttons: buttons || [
        {
          caption: 'Cancel',
          callback: (): void => {
            dialogRef.close(false);
          },
          material_ligature: 'cancel',
        },
        {
          caption: 'Yes',
          callback: (): void => {
            dialogRef.close(true);
          },
          material_ligature: 'check_circle',
          color: 'accent',
        },
      ],
      ...options,
    };

    let defaultWidth = '40%';

    if (screen.width <= 768) {
      defaultWidth = '90%';
      if (width === '40%') {
        width = defaultWidth;
      }
    }

    dialogRef = this.dialog.open(NotificationModalComponent, {
      width: width || defaultWidth,
      disableClose: true,
      panelClass: 'notification',
      data: dataObj,
    });

    return dialogRef;
  }

  isDialogPermanentlyClosed(dialogId: string): Observable<boolean> {
    if (!dialogId) {
      return of(false);
    }
    return this.store.pipe(select(fromStore.getUserPreferences)).pipe(
      take(1),
      map((userPrefs) => (userPrefs.dialogs?.[dialogId] ? userPrefs.dialogs[dialogId] : false))
    );
  }
}
