<div class="drop-zone" appDragDrop (fileDropped)="onFileDropped($event)">
  <input
    type="file"
    id="fileDropRef"
    [disabled]="disabled"
    [ngClass]="{ disabled: disabled }"
    #fileInputRef
    (change)="fileBrowseHandler($event.target.files)"
    />
  <div class="message" [ngClass]="{ disabled: disabled }">
    <h2>
      [{{ 'dragDrop.upload' | translate }}
      {{ 'dragDrop.' + resourceLabel + '.upload' | translate }}
      {{ 'dragDrop.area' | translate }}]
    </h2>
    @if (!viewerMode) {
      <h3>
        {{ 'dragDrop.browse' | translate }}
        {{ 'dragDrop.' + resourceLabel + '.file' | translate }}
        {{ 'dragDrop.browseEnd' | translate }}
      </h3>
      <h3>{{ 'dragDrop.or' | translate }}</h3>
      <h3>
        {{ 'dragDrop.fileHere' | translate }}
        {{ 'dragDrop.' + resourceLabel + '.file' | translate }}
        {{ 'dragDrop.' + resourceLabel + '.fileType' | translate }}
        {{ 'dragDrop.fileHereEnd' | translate }}
      </h3>
    }
    @if (viewerMode) {
      <h3>{{ 'dragDrop.viewerMsg' | translate }}</h3>
    }
  </div>
</div>
