/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';
import { Client } from 'paho-mqtt';

import { DiligentApiService } from 'src/app/_shared/services/diligent-api.service';

@Injectable({
  providedIn: 'root',
})
export class SocketService {
  constructor(private diligent: DiligentApiService) {}

  public getTopicPrefix(mpId: number): Observable<string> {
    return this.diligent.getSocketTopic(null, mpId).pipe(
      map((topic) => {
        const serialNumber = topic.split('/')[1];

        return `cloud/${serialNumber}`;
      })
    );
  }

  public getClient<T>() {
    return this.diligent.getSocketUrl().pipe(
      take(1),
      map((socketUrl) => {
        const client = new Client(socketUrl, 'cID' + Math.floor(Date.now().valueOf() / (Math.random() * 9)));
        return new (class {
          connected = false;
          private observableSubject: Observable<T>;
          get observable() {
            return this.observableSubject;
          }
          constructor() {
            this.observableSubject = new Observable<T>((resolver) => {
              resolver.add(() => {
                client.disconnect();
              });
              client.onMessageArrived = (message) => {
                resolver.next(JSON.parse(message.payloadString || ''));
              };
              client.onConnectionLost = () => {
                this.connected = false;
                resolver.error({});
              };
            });
          }
          connect() {
            return new Observable<this>((resolver) => {
              client.onConnected = () => {
                this.connected = true;
                resolver.next(this);
                resolver.complete();
              };
              client.connect({
                useSSL: true,
                mqttVersion: 4,
              });
            });
          }
          addTopic(topic) {
            client.subscribe(topic);
          }
          removeTopic(topic) {
            client.unsubscribe(topic);
          }
          destroy() {
            this.connected = false;
            client.disconnect();
          }
        })();
      }),
      switchMap((client) => client.connect())
    );
  }
}
