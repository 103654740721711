import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { of, timer } from 'rxjs';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { filter, map, switchMap, take, tap, takeUntil } from 'rxjs/operators';

import { AuthService, TokenService } from 'src/app/_shared/services';
import { InitActions } from 'src/app/_store/_init/actions';
import { environment } from 'src/environments/environment';
import { AutoLogoutComponent } from 'src/app/layout/auto-logout/auto-logout.component';
import { AppActions } from 'src/app/_store/_actions';

@Injectable()
export class AuthEffects {
  checkAuth$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(InitActions.init.type),
        tap(() => {
          return timer(1000, 2000)
            .pipe(
              switchMap(() => this.auth.authed.pipe(take(1))),
              switchMap((payload) => {
                // if we have a payload we're still authed, but only show expiration it we also have a token hash (previously-authed)
                return of(payload ? false : !!this.token.hash);
              }),
              map((expired) => {
                if (expired) return 1;

                const lastAccess = this.token.lastAccessedMs || Date.now();
                const isInactive = Math.floor((Date.now() - lastAccess) / 1000) > environment.psl.inactivity_timeout;
                const isAlmostInactive =
                  Math.floor((Date.now() - lastAccess) / 1000) >
                  environment.psl.inactivity_timeout - environment.psl.inactivity_warning_threshold;

                if (isInactive) return 1;
                else if (isAlmostInactive) return -1;
                else return 0;
              }),
              filter(() => {
                // don't keep emitting if there's an activeDialog that isn't the warning (which can expire)
                return (
                  !this.activeDialog ||
                  (this.activeDialog &&
                    this.activeDialog.componentInstance &&
                    this.activeDialog.componentInstance.data.warn)
                );
              }),
              takeUntil(this.actions$.pipe(ofType(AppActions.logout)))
            )
            .subscribe((expired) => {
              // session expired
              if (expired === 1) {
                // warning dialog still open; close it
                if (this.activeDialog) {
                  this.activeDialog.close();
                }
                this.token.expire();
              }
              // session *almost* expired
              else if (expired === -1) {
                if (this.activeDialog) return;

                this.activeDialog = this.dialog.open(AutoLogoutComponent, {
                  data: {
                    warn: true,
                  },
                });
                this.activeDialog.afterClosed().subscribe((result) => {
                  if (result) {
                    this.token.touch();
                  } else if (result === false) {
                    this.token.expire();
                    window.location.href = '/login';
                  }
                  this.activeDialog = null;
                });
              }
            });
        })
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions<InitActions.InitActionsUnion>,
    private auth: AuthService,
    private token: TokenService,
    private dialog: MatDialog
  ) {}

  private activeDialog: MatDialogRef<AutoLogoutComponent>;
}
