import { Component, HostListener, Input, Output, EventEmitter, ViewChild, OnDestroy, OnInit } from '@angular/core';
import { cloneDeep } from 'lodash';
import { MatExpansionPanel } from '@angular/material/expansion';
import { select, Store } from '@ngrx/store';
import { take } from 'rxjs';

import * as fromStore from 'src/app/_store/_reducers';
import { AuthService } from 'src/app/_shared/services';
import { FiltersPrefValue } from 'src/app/_shared/interface/filters-pref.interface';
import { UserPreferences } from 'src/app/_shared/interface/user-preferences.interface';
import { StateFilter } from 'src/app/_shared/interface/state-filter.interface';
import { OnDestroyMixin, untilComponentDestroyed } from 'src/app/_shared/classes/component-destroy.class';

@Component({
  selector: 'app-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss'],
})
export class FilterComponent extends OnDestroyMixin implements OnDestroy, OnInit {
  @Input() from: string;
  @Input() searchFilter: string;
  @Input() locationFilter: string;
  @Input() stateOptions: Array<StateFilter>;
  @Input() stateFilter: Array<StateFilter>;
  @Input() search: boolean;
  @Input() state: boolean;
  @Input() clearButton: boolean;
  @Input() disable: boolean;
  @Input() userPreferenceName: string;

  @Output() searchFilterChange = new EventEmitter<string>();
  @Output() stateFilterChange = new EventEmitter<Array<StateFilter>>();
  @Output() clearFilters = new EventEmitter<boolean>();

  @ViewChild('comboFilter') comboFilter: MatExpansionPanel;

  smallScreen: boolean;
  offsetFilter: number;

  @HostListener('window:resize', ['$event'])
  onResize(): void {
    this.smallScreen = window.innerWidth < 992;
  }

  constructor(private store: Store<fromStore.State>, private authService: AuthService) {
    super();
    this.onResize();
  }

  ngOnInit(): void {
    this.clearFilters.pipe(untilComponentDestroyed(this)).subscribe(() => {
      if (this.userPreferenceName) {
        this.store
          .pipe(select(fromStore.getUserPreferences))
          .pipe(take(1))
          .subscribe((userPrefs) => {
            let userPrefsClone: UserPreferences = cloneDeep(userPrefs);
            userPrefsClone.filters[this.userPreferenceName] = {};

            if (userPrefsClone !== userPrefs) {
              this.authService.savePreferences({ ...userPrefsClone }, true);
            }
          });
      }
    });
  }

  ngOnDestroy(): void {
    if (this.userPreferenceName) {
      this.store
        .pipe(select(fromStore.getUserPreferences))
        .pipe(take(1))
        .subscribe((userPrefs) => {
          let userPrefsClone: UserPreferences = cloneDeep(userPrefs);
          let filterPrefs: FiltersPrefValue = {};

          if (this.state) {
            filterPrefs.stateFilter = this.stateFilter;
          }

          filterPrefs.searchFilter = this.searchFilter;

          if (!userPrefsClone.filters) {
            userPrefsClone.filters = {};
          }
          userPrefsClone.filters[this.userPreferenceName] = filterPrefs;

          if (userPrefsClone !== userPrefs) {
            this.authService.savePreferences({ ...userPrefsClone }, true);
          }
        });
    }
    super.ngOnDestroy();
  }

  expand(event): void {
    if (window.pageYOffset <= this.comboFilter._body?.nativeElement?.offsetTop) {
      window.scroll({
        top: this.comboFilter._body.nativeElement.offsetTop,
        behavior: 'smooth',
      });
    }
  }

  compareStates(state1: StateFilter, state2: StateFilter): boolean {
    return state1.name === state2.name;
  }
}
