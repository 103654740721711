import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'powerUnit',
})
export class PowerUnitPipe implements PipeTransform {
  transform(value: number, ...args: any[]): string | number {
    if (!value || args.indexOf(true) !== -1) {
      return '-';
    } else {
      let maxValue = args[0];
      let unit = args[1];
      if (args[2] !== null) {
        maxValue = Math.max(
          parseFloat(args[0]) ? parseFloat(args[0]) : 0,
          parseFloat(args[1]) ? parseFloat(args[1]) : 0,
          parseFloat(args[2]) ? parseFloat(args[2]) : 0
        );
        unit = args[3];
      }
      if (Math.abs(maxValue) / 1000 < 1) {
        return unit ? value + ' ' + unit : value;
      } else if (Math.abs(maxValue) / 1000000 < 1) {
        return unit ? (value / 1000).toFixed(3) + ' k' + unit : (value / 1000).toFixed(3);
      } else {
        return unit ? (value / 1000000).toFixed(3) + ' M' + unit : (value / 1000000).toFixed(3);
      }
    }
  }
}
