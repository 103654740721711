import { Action, createSelector } from '@ngrx/store';
import { LayoutActions } from 'src/app/_store/_layout/actions';

export interface State {
  components: {
    headBanner: {
      height: number;
    };
  };
}

const initialState: State = {
  components: {
    headBanner: {
      height: 0,
    },
  },
};

export function reducer(state: State = initialState, action: Action): State {
  const specificAction = action as LayoutActions.LayoutActionsUnion;

  switch (specificAction.type) {
    case LayoutActions.setHeadBannerHeight.type:
      const payload = { components: { headBanner: { height: specificAction.payload.height } } };
      return {
        ...state,
        ...payload,
      };
    default:
      return state;
  }
}
export const getHeadBannerHeight = (state: State): number => state.components.headBanner.height;
