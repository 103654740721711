import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { select, Store } from '@ngrx/store';
import { take } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

import { IMeasurementPoint } from 'src/app/_shared/classes/measurementpoint.interface';
import { UserPreferences } from 'src/app/_shared/interface/user-preferences.interface';
import * as fromStore from 'src/app/_store/_reducers';
import { MeasurementPointsService } from 'src/app/_shared/services/measurement-points.service';
import { OnDestroyMixin, untilComponentDestroyed } from 'src/app/_shared/classes/component-destroy.class';
import { FailAssociationDialogComponent } from 'src/app/fleet-overview/fleet/associate-mp/fail-association-dialog/fail-association-dialog.component';
import { NotificationsService } from 'src/app/_shared/modules/notifications/shared/notifications.service';
import { DeleteMemberDialogComponent } from 'src/app/_shared/components/delete-member-dialog/delete-member-dialog.component';
import { NotificationModalComponent } from 'src/app/_shared/modules/notifications/modal-dialog/notification-modal.component';

@Component({
  selector: 'app-associate-mp',
  templateUrl: './associate-mp.component.html',
  styleUrls: ['./associate-mp.component.scss'],
})
export class AssociateMpComponent extends OnDestroyMixin implements OnDestroy, OnInit {
  loading = false;
  isSubscribe = false;
  error: { msg: string };
  private timeoutApi;
  private success = false;
  associatedMp = {};
  hasAllDataToDissociate = false;
  isDissociationCalled: boolean = false;

  public associateTokenControl = new FormControl(null, [
    Validators.required,
    Validators.minLength(6),
    Validators.maxLength(6),
    Validators.pattern(/^[0-9]\d*$/),
  ]);

  userPrefs: UserPreferences;

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: { point: IMeasurementPoint },
    private dialogRef: MatDialogRef<AssociateMpComponent>,
    private mpService: MeasurementPointsService,
    private store: Store<fromStore.State>,
    private dialog: MatDialog,
    private notificationsService: NotificationsService,
    private translateService: TranslateService
  ) {
    super();
    this.store
      .pipe(select(fromStore.getUserPreferences))
      .pipe(take(1))
      .subscribe((userPrefs) => {
        this.userPrefs = userPrefs;
      });
  }

  ngOnInit(): void {
    this.mpService.mqttClient.pipe(untilComponentDestroyed(this)).subscribe((client) => {
      if (client?.connected) {
        this.isSubscribe = true;
      }
    });

    this.mpService
      .registrationSuccess(this, this.data.point.measurementPointId)
      .pipe(untilComponentDestroyed(this))
      .subscribe({
        next: (success) => {
          if (this.timeoutApi) {
            clearTimeout(this.timeoutApi);
          }
          this.success = success.success;
          this.loading = false;
          this.dialogRef.close(true);
        },
        error: () => {
          this.dialogRef.close(false);
          this.loading = false;
          this.dialog.open(FailAssociationDialogComponent, { data: { mpId: this.data.point.measurementPointId } });
        },
      });
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  submit(): void {
    if (this.associateTokenControl.valid) {
      this.mpService
        .associateDeviceWithToken(this.data.point.measurementPointId, this.associateTokenControl.value)
        .pipe(take(1))
        .subscribe({
          next: () => {
            this.loading = true;
            if (!this.success) {
              this.timeoutApi = setTimeout(() => {
                this.dialogRef.close(false);
                this.loading = false;
                this.dialog.open(FailAssociationDialogComponent, {
                  data: { mpId: this.data.point.measurementPointId },
                });
              }, 60000);
            }
          },
          error: (error) => {
            this.error = {
              msg: error.error.message,
            };
            this.associateTokenControl.setErrors({
              error: true,
            });
            if (this.error.msg.includes('code 52')) {
              this.associatedMp['associatedMpId'] = error.error.associatedMPId;
              this.associatedMp['accountName'] = error.error.accountName;
              this.associatedMp['associatedMPName'] = error.error.associatedMPName;
              if (
                this.associatedMp['associatedMpId'] &&
                this.associatedMp['accountName'] &&
                this.associatedMp['associatedMPName']
              ) {
                this.hasAllDataToDissociate = true;
              }
            }
          },
        });
    } else if (!this.associateTokenControl.valid && this.error.msg.includes('code 52') && this.hasAllDataToDissociate) {
      this.unassociateMp();
    } else if (!this.associateTokenControl.touched) {
      this.associateTokenControl.markAllAsTouched();
    }
  }

  iotTokenChange(): void {
    //reset label as user is altering iot token
    this.associatedMp = {};
    this.hasAllDataToDissociate = false;
  }

  unassociateMp(): void {
    const mp = {
      mpId: this.associatedMp['associatedMPName'],
    };
    const deleteMemberDialog = this.dialog.open(DeleteMemberDialogComponent, {
      width: '500px',
      disableClose: false,
      data: {
        mp: mp,
        accountName: this.associatedMp['accountName'],
        unassociate: true,
      },
      autoFocus: false,
      restoreFocus: false,
    });

    deleteMemberDialog.afterClosed().subscribe((confirmDelete: boolean) => {
      if (!confirmDelete) {
        return;
      }

      this.isDissociationCalled = true;
      this.loading = true;
      this.mpService
        .disassociateDevice(this.associatedMp['associatedMpId'])
        .pipe(take(1))
        .subscribe({
          next: () => {
            this.isDissociationCalled = false;
            let dialogRef: MatDialogRef<NotificationModalComponent> = this.notificationsService.alert(
              this.translateService.instant('manageSiteMp.associate.popupMsgDisassociate'),
              this.translateService.instant('manageSiteMp.associate.popupDisassociateTitle')
            );

            dialogRef.afterClosed().subscribe((result) => {
              this.dialogRef.close(false);
            });
          },
          error: (error) => {
            this.isDissociationCalled = false;
            if (error?.error?.message) {
              this.notificationsService.notify(this.translateService.instant('manageSiteMp.unassociate.failed'));
            } else {
              this.notificationsService.notify(error.error.message);
            }
          },
        });
    });
  }
}
