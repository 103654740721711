import { Component, Input, Output, EventEmitter } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Store } from '@ngrx/store';
import { take } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

import * as fromStore from 'src/app/_store/_reducers';
import { SitesService } from 'src/app/_shared/services';
import { MeasurementPointsService } from 'src/app/_shared/services/measurement-points.service';
import { HierarchyActions } from 'src/app/_store/_hierarchy/actions';
import { UserActions } from 'src/app/_store/_user/actions';
import { NotificationsService } from 'src/app/_shared/modules/notifications/shared/notifications.service';

export class Group {
  level = 0;
  parent: Group;
  expanded = false;
  totalCounts = 0;
  get visible(): boolean {
    return !this.parent || (this.parent.visible && this.parent.expanded);
  }
}

@Component({
  selector: 'app-move-to',
  templateUrl: './move-to.component.html',
  styleUrls: ['./move-to.component.scss'],
})
export class MoveToComponent {
  @Input() mpId: number;
  @Output() closeDialog: EventEmitter<any> = new EventEmitter();
  partnersData;
  checkboxAccountId: string;
  dataSource = new MatTableDataSource<any | Group>([]);
  loading: boolean;

  _alldata: any[];
  displayedColumns: string[];
  groupByColumns: string[] = [];

  constructor(
    private mpService: MeasurementPointsService,
    private sitesService: SitesService,
    private store: Store<fromStore.State>,
    private notificationsService: NotificationsService,
    private translateService: TranslateService
  ) {
    this.displayedColumns = ['partner', 'customer'];
    this.groupByColumns = ['partner'];
  }

  init(): void {
    this.loading = true;
    this.partnersData = [];
    this.mpService
      .getMoveMpList(this.mpId)
      .pipe(take(1))
      .subscribe((mpList) => {
        mpList.forEach((partner) => {
          this.partnersData.push({
            partner: partner.accountName,
            customer: partner.accountName,
            accountId: partner.accountId,
          });
          partner?.customers?.forEach((customer) => {
            this.partnersData.push({
              partner: partner.accountName,
              customer: customer.accountName,
              accountId: customer.accountId,
            });
          });
        });
        this._alldata = this.partnersData;
        const rootGroup = new Group();
        rootGroup.expanded = true;
        this.dataSource.data = this.getSublevel(this._alldata, 0, this.groupByColumns, rootGroup);
        this.dataSource.filterPredicate = this.customFilterPredicate.bind(this);
        this.dataSource.filter = performance.now().toString();
        this.loading = false;
      });
  }

  customFilterPredicate(data: any | Group, filter: string): boolean {
    return data instanceof Group ? data.visible : this.getDataRowVisible(data);
  }

  getDataRowVisible(data: any): boolean {
    const groupRows = this.dataSource.data.filter((row) => {
      if (!(row instanceof Group)) {
        return false;
      }
      let match = true;
      this.groupByColumns.forEach((column) => {
        if (!row[column] || !data[column] || row[column] !== data[column]) {
          match = false;
        }
      });
      return match;
    });

    if (groupRows.length === 0) {
      return true;
    }
    const parent = groupRows[0] as Group;
    return parent.visible && parent.expanded;
  }

  groupHeaderClick(row): void {
    row.expanded = !row.expanded;
    this.dataSource.filter = performance.now().toString();
  }

  getSublevel(data: any[], level: number, groupByColumns: string[], parent: Group): any[] {
    if (level >= groupByColumns.length) {
      return data;
    }
    const groups = this.uniqueBy(
      data.map((row) => {
        const result = new Group();
        result.level = level + 1;
        result.parent = parent;
        for (let i = 0; i <= level; i++) {
          result[groupByColumns[i]] = row[groupByColumns[i]];
        }
        return result;
      }),
      JSON.stringify
    );

    const currentColumn = groupByColumns[level];
    let subGroups = [];
    groups.forEach((group) => {
      const rowsInGroup = data.filter((row) => group[currentColumn] === row[currentColumn]);
      group.totalCounts = rowsInGroup.length;
      const subGroup = this.getSublevel(rowsInGroup, level + 1, groupByColumns, group);
      subGroup.unshift(group);
      subGroups = subGroups.concat(subGroup);
    });
    return subGroups;
  }

  uniqueBy(a, key): any {
    const seen = {};
    return a.filter((item) => {
      const k = key(item);
      return seen.hasOwnProperty(k) ? false : (seen[k] = true);
    });
  }

  isGroup(index, item): boolean {
    return item.level;
  }

  moveTo(): void {
    this.loading = true;
    this.mpService
      .moveMp(this.mpId, parseInt(this.checkboxAccountId))
      .pipe(take(1))
      .subscribe({
        next: (msg) => {
          this.notificationsService.notify(this.translateService.instant('moveTo.successMsg'));
          this.sitesService
            .getSites()
            .pipe(take(1))
            .subscribe((sites) => {
              this.store.dispatch(HierarchyActions.getHierarchySuccess({ hierarchy: sites }));
            });
          this.store.dispatch(
            UserActions.updateUserPreferences({
              payload: {
                property: {
                  account: parseInt(this.checkboxAccountId),
                },
              },
            })
          );
          this.loading = false;
          this.closeDialog.emit();
        },
        error: (error) => {
          this.notificationsService.notify(this.translateService.instant('moveTo.failMsg'));
        },
      });
  }
}
