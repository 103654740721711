import { createAction, props } from '@ngrx/store';

import { UserRoles } from 'src/app/_shared/interface/user-roles.interface';

export const getRoles = createAction('[Roles] Get Roles');

export const getRolesSuccess = createAction(
  '[Roles] Get Roles Success',
  props<{ payload: { userRoles: UserRoles } }>()
);

export type RolesActionsUnion = ReturnType<typeof getRoles | typeof getRolesSuccess>;
