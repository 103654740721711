<div class="mp-access-container">
  <mat-table
   class="mp-access"
   data-cy="mp-access-table"
   [dataSource]="userAccessTable.dataSource"
   matSort
   [matSortActive]="userAccessTable.sortActive"
   [matSortDirection]="userAccessTable.sortDirection"
   (matSortChange)="onTableSort($event)">
    <ng-container matColumnDef="firstName">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'userAccess.name' | translate }}</th>
      <td mat-cell *matCellDef="let element">
        {{ element.firstName }} {{ element.middleName }} {{ element.lastName }}
      </td>
    </ng-container>
    <ng-container matColumnDef="email">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'userAccess.email' | translate }}</th>
      <td mat-cell *matCellDef="let element">
        {{ element.email }}
      </td>
    </ng-container>
    <ng-container matColumnDef="role">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'userAccess.role' | translate }}</th>
      <td mat-cell *matCellDef="let element">
        {{ element.role }}
      </td>
    </ng-container>
    <ng-container matColumnDef="access">
      <th mat-header-cell *matHeaderCellDef>{{ 'userAccess.access' | translate }}</th>
      <td mat-cell *matCellDef="let element">
        <mat-checkbox
                [checked]="(accessChanges && accessChanges.hasOwnProperty(element.id))? accessChanges[element.id]:!!element.hasAccess"
                (change)="onCheckboxClick(element.id, $event.checked)"
        >
        </mat-checkbox>
      </td>
    </ng-container>
    <mat-header-row *matHeaderRowDef="userAccessTable.columnsDefinition"></mat-header-row>
    <mat-row *matRowDef="let row; columns: userAccessTable.columnsDefinition;"></mat-row>
  </mat-table>

  <mat-paginator
          [length]="paginatorData.totalRecords"
          [pageSize]="paginatorData.pageSize"
          [pageSizeOptions]="paginatorData.pageSizeOptions"
          showFirstLastButtons
          (page)="onPageChange($event)"
  ></mat-paginator>
</div>