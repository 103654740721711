
<div>
	<mat-form-field>
		<mat-label>File name</mat-label>
		<input matInput [formControl]="fileNameGroup.controls.fileName">
		<span matSuffix>.{{fileNameGroup.controls.extension.value}}</span>
	</mat-form-field>

	<div>
		<button mat-flat-button color="accent" (click)="submit()">Save</button>
		<button mat-flat-button matDialogClose>Cancel</button>
	</div>
</div>
