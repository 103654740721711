import { NgModule } from '@angular/core';
import { OverlayModule } from '@angular/cdk/overlay';

import { HeaderComponent } from 'src/app/layout/header/header.component';
import { AnonymousLayoutComponent } from 'src/app/layout/anonymous-layout/anonymous-layout.component';
import { AppLayoutComponent } from 'src/app/layout/app-layout/app-layout.component';
import { HeadBannerComponent } from 'src/app/layout/app-layout/head-banner/head-banner.component';
import { ChangeSelectionComponent } from 'src/app/layout/header/change-selection/change-selection.component';
import { NavigationComponent } from 'src/app/layout/navigation/navigation.component';
import { SharedModule } from 'src/app/_shared/shared.module';
import { SharedComponentModule } from 'src/app/_shared/components/shared-component.module';
import { AutoLogoutComponent } from 'src/app/layout/auto-logout/auto-logout.component';
import { ReleaseNewsComponent } from 'src/app/layout/header/release-news/release-news.component';
import { TinyEditorModule } from 'src/app/_shared/editor/editor.module';
import { WelcomeDialogComponent } from 'src/app/layout/header/welcome-dialog/welcome-dialog.component';
import { MatModule } from 'src/app/_shared/mat.module';
import { FilterModule } from 'src/app/filters/filter.module';

@NgModule({
  declarations: [
    AnonymousLayoutComponent,
    AppLayoutComponent,
    HeaderComponent,
    ChangeSelectionComponent,
    ReleaseNewsComponent,
    WelcomeDialogComponent,
    NavigationComponent,
    AutoLogoutComponent,
  ],
  imports: [
    MatModule,
    SharedModule,
    SharedComponentModule,
    TinyEditorModule,
    FilterModule,
    OverlayModule,
    HeadBannerComponent,
  ],
})
export class LayoutModule {}
