import { Action } from '@ngrx/store';

import { QubescanDashboardActions } from 'src/app/_store/_qubescan-dashboard/actions';
import { Kpi } from 'src/app/_shared/interface/kpi.interface';

export interface State {
  qubescanValue: any;
  error: any;
  qubescanChannelValue: any;
  qubescanChannel: any;
  kpi: Kpi;
}

const initialState: State = {
  qubescanValue: null,
  error: null,
  qubescanChannelValue: null,
  qubescanChannel: ['', '', '', '', '', '', ''],
  kpi: null,
};

export function reducer(state: State = initialState, action: Action): State {
  const specificAction = action as QubescanDashboardActions.QubescanDashboardActionsUnion;
  switch (specificAction.type) {
    case QubescanDashboardActions.setValueSucces.type:
      return {
        ...state,
        qubescanValue: specificAction.payload.qubescanValue,
        error: null,
      };
    case QubescanDashboardActions.setValueFail.type:
      return {
        ...state,
        error: specificAction.payload.error,
      };
    case QubescanDashboardActions.resetError.type:
      return {
        ...state,
        error: null,
      };
    case QubescanDashboardActions.setLastCommunication.type:
      return {
        ...state,
        qubescanChannelValue: {
          ...state.qubescanChannelValue,
          lastCommunicationTime: specificAction.payload.lastCommunication,
        },
      };
    case QubescanDashboardActions.setChannel.type:
      return {
        ...state,
        qubescanChannel: specificAction.payload.qubescanChannel,
      };
    case QubescanDashboardActions.setChannelValueSuccess.type:
      return {
        ...state,
        qubescanChannelValue: specificAction.payload.qubescanChannelValue,
      };
    case QubescanDashboardActions.updateChannelSuccess.type:
      return {
        ...state,
        qubescanChannel: specificAction.payload.qubescanChannel,
      };
    case QubescanDashboardActions.getKPISuccess.type:
      return {
        ...state,
        kpi: specificAction.payload.kpi,
      };
    default:
      return state;
  }
}

export const getQubescanValue = (state: State): State['qubescanValue'] => state.qubescanValue;
export const getError = (state: State): State['qubescanValue'] => state.error;
export const getQubescanChannel = (state: State): State['qubescanChannel'] => state.qubescanChannel;
export const getKPIColor = (state: State): State['qubescanChannel'] => state.kpi.summaryKpi;
