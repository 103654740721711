import { Component, Inject } from '@angular/core';
import { FormGroup, FormControl, Validators, ValidationErrors, AbstractControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { NotificationsService } from 'src/app/_shared/modules/notifications/shared/notifications.service';
import { AuthService } from 'src/app/_shared/services';

@Component({
  selector: 'app-reset-password-dialog',
  templateUrl: 'reset-password-dialog.component.html',
  styleUrls: ['./reset-password-dialog.component.scss'],
})
export class ResetPasswordDialogComponent {
  public resetForm: FormGroup;

  public passwordPattern = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/;
  public minLength = 8;
  public maskCurrent = true;
  public maskPassword = true;
  public maskConfirm = true;
  public from: string;

  constructor(
    private dialogRef: MatDialogRef<ResetPasswordDialogComponent>,
    private authService: AuthService,
    private notificationsService: NotificationsService,
    private translateService: TranslateService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.from = data.from;
    this.resetForm = new FormGroup(
      {
        currentPassword: new FormControl(''),
        newPassword: new FormControl('', [
          Validators.required,
          Validators.pattern(this.passwordPattern),
          Validators.minLength(this.minLength),
        ]),
        confirmPassword: new FormControl('', [
          Validators.required,
          Validators.pattern(this.passwordPattern),
          Validators.minLength(this.minLength),
        ]),
      },
      {
        validators: (control: FormGroup): ValidationErrors | null => {
          const cpwd = control.get('currentPassword');
          const pwd = control.get('newPassword');
          const cfm = control.get('confirmPassword');

          const valid = (this.from !== 'profile' || cpwd) && pwd && cfm && pwd.value === cfm.value;
          const error = { passwordMismatch: true };

          let pErrors = { ...pwd.errors };
          let cErrors = { ...cfm.errors };

          if (!valid) {
            pErrors = { ...pErrors, ...error };
            cErrors = { ...cErrors, ...error };
          } else {
            if (pErrors.hasOwnProperty('passwordMismatch')) {
              delete pErrors.passwordMismatch;
            }

            if (cErrors.hasOwnProperty('passwordMismatch')) {
              delete cErrors.passwordMismatch;
            }
          }

          pwd.setErrors(Object.keys(pErrors).length > 0 ? pErrors : null);
          cfm.setErrors(Object.keys(cErrors).length > 0 ? cErrors : null);

          return valid ? null : error;
        },
      }
    );
    if (this.from === 'profile') {
      this.resetForm.get('currentPassword').setValidators(Validators.required);
    }
  }

  public get currentPassword(): AbstractControl {
    return this.resetForm.get('currentPassword');
  }

  public get newPassword(): AbstractControl {
    return this.resetForm.get('newPassword');
  }

  public get confirmPassword(): AbstractControl {
    return this.resetForm.get('confirmPassword');
  }

  public onCancel(): void {
    this.dialogRef.close(null);
  }

  public submitForm($event: Event): void {
    if (this.from === 'profile') {
      this.authService
        .resetPassword(this.currentPassword.value, this.newPassword.value)
        .pipe(take(1))
        .subscribe(
          (response) => {
            if (response) {
              this.notificationsService.notify(
                this.translateService.instant('login.reset-password.password-change-success')
              );
              $event.stopPropagation();
              this.dialogRef.close();
            } else {
              this.notificationsService.notify(
                this.translateService.instant('login.reset-password.password-change-error')
              );
            }
          },
          (error) => {
            let errorMessage = this.translateService.instant('login.reset-password.password-change-error');
            if (error.error && error.error.error) {
              errorMessage = error.error.error;
            }
            this.notificationsService.alert(errorMessage, 'Error');
          }
        );
    } else {
      $event.stopPropagation();
      this.dialogRef.close(this.newPassword.value);
    }
  }
}
