import { Action } from '@ngrx/store';

import { UserRoles } from 'src/app/_shared/interface/user-roles.interface';
import { RolesActions } from 'src/app/_store/_roles/actions';

export interface State {
  userRoles: UserRoles;
}

const initialState: State = {
  userRoles: null,
};

export function reducer(state: State = initialState, action: Action): State {
  const specificAction = action as RolesActions.RolesActionsUnion;

  switch (specificAction.type) {
    case RolesActions.getRolesSuccess.type:
      return {
        ...state,
        userRoles: specificAction.payload.userRoles,
      };
    default:
      return state;
  }
}
