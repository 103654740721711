import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatCommonModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatTooltipModule } from '@angular/material/tooltip';

import { AgGridUnFocusDirective } from 'src/app/_shared/ag/ag-grid-unfocus.directive';
import { AgMatButtonComponent } from 'src/app/_shared/ag/ag-mat-button/ag-mat-button.component';
import {
  AgMatCheckboxComponent,
  AgMatCheckboxEditorComponent,
  CheckboxRenderer,
} from 'src/app/_shared/ag/ag-mat-checkbox/ag-mat-checkbox.component';
import { AgMatTooltipComponent } from 'src/app/_shared/ag/ag-mat-tooltip/ag-mat-tooltip.component';

@NgModule({
  imports: [
    CommonModule,
    MatCommonModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatIconModule,
    ReactiveFormsModule,
    FormsModule,
    MatTooltipModule,
  ],
  declarations: [
    CheckboxRenderer,
    AgMatButtonComponent,
    AgMatCheckboxComponent,
    AgMatCheckboxEditorComponent,
    AgGridUnFocusDirective,
    AgMatTooltipComponent,
  ],
  exports: [
    CheckboxRenderer,
    AgMatCheckboxComponent,
    AgMatCheckboxEditorComponent,
    AgGridUnFocusDirective,
    AgMatButtonComponent,
  ],
})
export class AgExtraModule {}
