import { Action } from '@ngrx/store';

import { RecommendationActions } from 'src/app/_store/_recommendation/actions';
import { IRecommendation, RecommendationCount } from 'src/app/_shared/interface/recommendation.interface';

export interface State {
  count: RecommendationCount;
  countYear: number;
  recommendations: IRecommendation[];
}

export const initialState: State = {
  count: null,
  countYear: null,
  recommendations: null,
};

function replaceInArray<T>(array: T[], modifiedItem: T, id: any): T[] {
  const newArray = [...array];
  const index = newArray.findIndex((a) => a[id] === modifiedItem[id]);
  if (index > -1) {
    newArray[index] = modifiedItem;
  }
  return newArray;
}

export function reducer(state: State = initialState, action: Action): State {
  const specificAction = action as RecommendationActions.RecommendationActionsUnion;

  switch (specificAction.type) {
    case RecommendationActions.getRecommendationCount.type:
      return { ...state };
    case RecommendationActions.getRecommendationCountSuccess.type:
      return { ...state, count: specificAction.payload.count, countYear: specificAction.payload.year };
    case RecommendationActions.getRecommendationSuccess.type:
      return { ...state, recommendations: specificAction.payload.recommendations };
    case RecommendationActions.createRecommendation.type:
      return { ...state, recommendations: [...state.recommendations, specificAction.payload.recommendation] };
    case RecommendationActions.updateRecommendation.type:
      return {
        ...state,
        recommendations: replaceInArray(state.recommendations, specificAction.payload.recommendation, 'id'),
      };
    default:
      return state;
  }
}

export const getRecommendationCount = (state: State): State['count'] => state.count;
export const getRecommendation = (state: State): State['recommendations'] => state.recommendations;
