import { Component, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-editor-image-upload',
  templateUrl: './editor-image-upload.component.html',
  styleUrls: ['./editor-image-upload.component.scss'],
})
export class EditorImageUploadComponent {
  fileNameGroup: FormGroup;

  constructor(
    @Inject(MAT_DIALOG_DATA) private dialogData: { blobFileName: string },
    private dialogRef: MatDialogRef<EditorImageUploadComponent>
  ) {
    this.fileNameGroup = new FormGroup({
      fileName: new FormControl('', [Validators.required]),
      extension: new FormControl(''),
    });
    const fileParts = this.dialogData?.blobFileName?.split('.') || [];
    if (fileParts.length === 2) {
      this.fileNameGroup.setValue({
        fileName: fileParts[0],
        extension: fileParts[1],
      });
    }
  }

  submit(): void {
    if (this.fileNameGroup.valid) {
      const value = this.fileNameGroup.value;
      const fileName = `${value.fileName}.${value.extension}`;
      this.dialogRef.close(fileName);
    } else {
      this.fileNameGroup.markAllAsTouched();
    }
  }
}
