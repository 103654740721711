import { Component } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { TranslateService } from '@ngx-translate/core';
import { filter, interval, take } from 'rxjs';

import { NotificationsService } from 'src/app/_shared/modules/notifications/shared/notifications.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  constructor(
    private translate: TranslateService,
    private swUpdate: SwUpdate,
    private notificationsService: NotificationsService
  ) {
    this.translate.setDefaultLang('en_US');
    this.checkUpdate();
  }

  checkUpdate(): void {
    let dialog;
    if (this.swUpdate.isEnabled) {
      interval(600000).subscribe(async () => {
        const test = await this.swUpdate.checkForUpdate();

        this.swUpdate.versionUpdates
          .pipe(
            filter((evt): any => {
              return evt.type === 'VERSION_READY';
            })
          )
          .subscribe((evt) => {
            if (!dialog) {
              dialog = this.notificationsService
                .alert(this.translate.instant('update.body'), this.translate.instant('update.title'))
                .afterClosed()
                .pipe(take(1))
                .subscribe(() => {
                  document.location.reload();
                });
            }
          });
      });
    }
  }
}
