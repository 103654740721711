import { Observable } from 'rxjs';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngrx/store';

import { NotificationsService } from 'src/app/_shared/modules/notifications/shared/notifications.service';
import { AuthService } from 'src/app/_shared/services/auth.service';
import { ForgotPasswordDialogComponent } from 'src/app/login/forgot-password-dialog/forgot-password-dialog.component';
import { ResetPasswordDialogComponent } from 'src/app/login/reset-password-dialog/reset-password-dialog.component';
import { ILoginResponse } from 'src/app/_shared/classes/user';
import * as fromStore from 'src/app/_store/_reducers';
import { OnDestroyMixin, untilComponentDestroyed } from 'src/app/_shared/classes/component-destroy.class';
import { AppActions } from 'src/app/_store/_actions';
import { UserActions } from 'src/app/_store/_user/actions';

const LOGIN_REFRESH_KEY = 'login_refresh';

@Component({
  selector: 'app-login-box',
  templateUrl: './login-box.component.html',
  styleUrls: ['./login-box.component.scss'],
})
export class LoginBoxComponent extends OnDestroyMixin implements OnDestroy, OnInit {
  @Input() isQubescanLogin: boolean;
  loginForm: FormGroup;
  minLength = 6;
  maskPassword = true;
  loading$: Observable<boolean>;
  private returnUrl: string;
  private token: string;

  constructor(
    private authService: AuthService,
    private dialog: MatDialog,
    private notificationsService: NotificationsService,
    private translateService: TranslateService,
    public route: ActivatedRoute,
    public router: Router,
    private store: Store<fromStore.State>
  ) {
    super();
    this.loginForm = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.email]),
      password: new FormControl('', [Validators.required, Validators.minLength(this.minLength)]),
    });
    this.route.queryParams.pipe(untilComponentDestroyed(this)).subscribe((params: Params) => {
      // password reset is happening
      if (params.token) {
        this.token = params.token;
        // strip the token out of the URL
        this.router.navigate(['/login']);
        // show the reset dialog
        this.showResetPassword();
      }
      if (params.returnUrl) {
        this.returnUrl = params.returnUrl;
      }
    });
  }

  ngOnInit(): void {
    this.store.dispatch(AppActions.logout());
    this.loading$ = this.store.select((state) => state.user.loginLoading);
    const refresh = localStorage.getItem(LOGIN_REFRESH_KEY);
    if (!refresh) {
      localStorage.setItem(LOGIN_REFRESH_KEY, 'true');
      document.location.reload();
    }
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  public get email(): AbstractControl {
    return this.loginForm.get('email');
  }

  public get password(): AbstractControl {
    return this.loginForm.get('password');
  }

  login($event: Event): void {
    this.store.dispatch(UserActions.toogleLoginLoading({ payload: { loginLoading: true } }));
    if ($event) {
      $event.stopPropagation();
    } else {
      this.isQubescanLogin = false;
    }
    // wait for the login event to finish then redirect to the dashboard
    this.authService
      .login(this.email.value, this.password.value, this.returnUrl, this.isQubescanLogin)
      .then(() => {
        //once user logs in successfully and user comes back to login page from wherever, it will refresh
        localStorage.removeItem(LOGIN_REFRESH_KEY);
      })
      .catch((error) => {
        this.notificationsService.alert(
          this.translateService.instant('login.login-error'),
          this.translateService.instant('login.login-failed')
        );
        this.store.dispatch(UserActions.toogleLoginLoading({ payload: { loginLoading: false } }));
      });
  }

  toggleMasking(): void {
    this.maskPassword = !this.maskPassword;
  }

  showForgotPassword(): void {
    const forgotDialog = this.dialog.open(ForgotPasswordDialogComponent, {
      data: {
        email: this.email.value,
      },
      width: '350px',
    });

    forgotDialog.afterClosed().subscribe((result: string) => {
      if (result) {
        this.notificationsService.notify(this.translateService.instant('login.forgot-password.reset-sent'));
      }
    });
  }

  showResetPassword(): void {
    // make sure we've got the things we need to actually do something useful
    if (!this.token) {
      return;
    }

    const resetDialog = this.dialog.open(ResetPasswordDialogComponent, {
      width: '350px',
      disableClose: true,
      data: {
        from: 'reset',
      },
    });

    resetDialog.afterClosed().subscribe((password: string) => {
      if (!password) {
        return;
      }

      this.authService.resetPasswordWithToken(password, this.token).then(
        async (response: ILoginResponse) => {
          if (response) {
            this.notificationsService.notify(
              this.translateService.instant('login.reset-password.password-change-success')
            );
            this.loginForm.setValue({
              email: response.email,
              password: password,
            });
            this.login(null);
          } else {
            this.showResetPassword();
            this.notificationsService.alert(
              this.translateService.instant('login.reset-password.password-change-error')
            );
          }
        },
        () => {
          this.showResetPassword();
          this.notificationsService.alert(this.translateService.instant('login.reset-password.password-change-error'));
        }
      );
    });
  }
}
