import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'significant3Number',
})
export class Significant3NumberPipe implements PipeTransform {
  constructor() {}

  transform(strValue: string, ...args: any[]): string {
    let value = +strValue;
    if (value !== null && value !== undefined) {
      if (value > 0 && value < 1) {
        return value.toFixed(2);
      } else if (value === 0 || value < 100) {
        return value.toPrecision(3);
      } else {
        return parseFloat(value.toFixed(0)).toLocaleString();
      }
    }
  }
}
