import { Injectable } from '@angular/core';

import { DiligentApiService } from 'src/app/_shared/services/diligent-api.service';
import {
  IRecommendation,
  RecommendationCount,
  SendRecommendationResponse,
  Comment,
  RecommendationComment,
} from 'src/app/_shared/interface/recommendation.interface';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class RecommendationsService {
  constructor(private diligentService: DiligentApiService) {}

  count(mpId: number, year = new Date().getFullYear()): Observable<RecommendationCount> {
    return this.diligentService.getRecommendationCount(mpId.toString(), year.toString());
  }

  getRecomendation(mpId: number): Observable<IRecommendation[]> {
    return this.diligentService.getRecommendation(mpId.toString());
  }

  createRecommendationComment(recommendationId: number, comment: Comment): Observable<RecommendationComment> {
    return this.diligentService.createRecommendationComment(recommendationId, comment);
  }

  updateRecommendationComment(
    recommendationId: number,
    commentId: number,
    comment: Comment
  ): Observable<RecommendationComment> {
    return this.diligentService.updateRecommendationComment(recommendationId, commentId, comment);
  }

  getRecommendationComments(recommendationId: number): Observable<RecommendationComment[]> {
    return this.diligentService.getRecommendationComments(recommendationId);
  }

  createRecommendation(recommendation: IRecommendation): Observable<IRecommendation> {
    return this.diligentService.createRecommendation(recommendation);
  }

  updateRecommendation(recommendation: IRecommendation): Observable<IRecommendation> {
    return this.diligentService.updateRecommendation(recommendation);
  }

  sendRecommendation(recommendationId: number): Observable<SendRecommendationResponse> {
    return this.diligentService.sendRecommendation(recommendationId);
  }
}
