import { A11yModule } from '@angular/cdk/a11y';
import { OverlayModule } from '@angular/cdk/overlay';
import { PortalModule } from '@angular/cdk/portal';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CdkScrollableModule } from '@angular/cdk/scrolling';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MAT_DATEPICKER_SCROLL_STRATEGY_FACTORY_PROVIDER, MatDatepickerIntl } from '@angular/material/datepicker';

import { DatetimeRangePickerComponent } from './datetime-range-picker.component';
import { RangePickerComponent } from 'src/app/_shared/components/range-picker/range-picker.component';
import { MatModule } from 'src/app/_shared/mat.module';

@NgModule({
  imports: [
    CommonModule,
    OverlayModule,
    A11yModule,
    PortalModule,
    MatModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  exports: [CdkScrollableModule, DatetimeRangePickerComponent, RangePickerComponent],
  declarations: [DatetimeRangePickerComponent, RangePickerComponent],
  providers: [MatDatepickerIntl, MAT_DATEPICKER_SCROLL_STRATEGY_FACTORY_PROVIDER],
})
export class DatetimeRangePickerModule {}
