<div class="datetime-range-box" [matMenuTriggerFor]="contactMenu">
  <span class="datetime-range-display">
    <span class="datetime-range-start">
      {{ dateRange ? (dateRange.start | date : 'YYYY-MM-dd (HH:mm)') : 'Start date' }}</span
    >
    <span>></span>
    <span class="datetime-range-end">{{ dateRange ? (dateRange.end | date : 'YYYY-MM-dd (HH:mm)') : 'End date' }}</span>
  </span>
  <button class="datetime-range-button" mat-icon-button (click)="$event.preventDefault()">
    <mat-icon color="primary" svgIcon="calendar"></mat-icon>
  </button>
</div>

<mat-menu #contactMenu="matMenu">
  <app-range-picker
    [selectedDateRange]="dateRange"
    [rangeType]="'create-report'"
    (applyDateRange)="setRange($event)"
  ></app-range-picker>
</mat-menu>
