@if (type === 'basic') {
<button
  [disabled]="beginningOfCreation || !havePast"
  (click)="changeTimePosition('past')"
  mat-flat-button
  data-cy="past"
  id="past"
>
  <
</button>
}
<ng-container>
  <button
    (click)="changeTimeScope('years')"
    id="year"
    data-cy="year"
    mat-flat-button
    [ngClass]="currentScope === 'years' ? 'clicked' : ''"
  >
    y
  </button>
  <button
    (click)="changeTimeScope('months')"
    id="month"
    data-cy="month"
    mat-flat-button
    [ngClass]="currentScope === 'months' ? 'clicked' : ''"
  >
    m
  </button>
  <button
    (click)="changeTimeScope('weeks')"
    id="week"
    data-cy="week"
    mat-flat-button
    [ngClass]="currentScope === 'weeks' ? 'clicked' : ''"
  >
    w
  </button>
  <button
    (click)="changeTimeScope('days')"
    id="day"
    data-cy="day"
    mat-flat-button
    [ngClass]="currentScope === 'days' ? 'clicked' : ''"
  >
    d
  </button>
</ng-container>
@if (type === 'basic') {
<button (click)="changeTimePosition('future')" mat-flat-button data-cy="future" id="right" [disabled]="!haveFutur">
  >
</button>
}
