import { createAction, props } from '@ngrx/store';

export const getHierarchyUser = createAction('[App Init] Init hierarchy - user');
export const addAccountToHierarchy = createAction(
  '[App Init] Add Account To Hierarchy',
  props<{ payload: { account: any } }>()
);
export const addMpToHierarchy = createAction('[Hierarchy] Add Mp To Hierarchy', props<{ payload: { mp: any } }>());
export const removeAccount = createAction(
  '[Hierarchy] remove account',
  props<{ payload: { partnerId: number; accountId: number } }>()
);
export const getHierarchySuccess = createAction('[App Init] Get Hierarchy Success', props<{ hierarchy: any }>());
export const filterHierarchySuccess = createAction('[Hierarchy] Filter Hierarchy Success', props<{ hierarchy: any }>());
export const getHierarchyFail = createAction('[App Init] Get Hierarchy Fail', props<{ error: any }>());
export const logout = createAction('[App Logout] Logout');

export type HierarchyActionsUnion = ReturnType<
  | typeof getHierarchyUser
  | typeof getHierarchyFail
  | typeof addMpToHierarchy
  | typeof removeAccount
  | typeof getHierarchySuccess
  | typeof filterHierarchySuccess
  | typeof addAccountToHierarchy
  | typeof logout
>;
