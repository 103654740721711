import { Directive, HostListener, Input, Optional } from '@angular/core';
import { EventTrackingService } from './event-tracking.service';

@Directive({
  selector: '[eventTracking]',
})
export class EventTrackingDirective {
  @Input('eventTracking') trackingValue: string;
  @Input() trackingParams: object;
  constructor(private eventTrackingService: EventTrackingService) {}

  @HostListener('click') onClick(): void {
    this.eventTrackingService.trackEvent(this.trackingValue, this.trackingParams);
  }
}
