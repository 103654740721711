import { Granularity } from 'src/app/_shared/interface/range-selector/granularity-interface';

export class ModuleUtil {
  static calculateGranularityPeriod(scope: string): Granularity {
    let granularity;
    let newInterval;
    if (scope === 'years') {
      granularity = 'day';
      newInterval = 1;
    } else if (scope === 'months') {
      granularity = 'minute';
      newInterval = 10;
    } else if (scope === 'weeks') {
      granularity = 'minute';
      newInterval = 10;
    } else if (scope === 'days') {
      granularity = 'minute';
      newInterval = 1;
    }
    return { granularity: granularity, interval: newInterval };
  }

  static calculateGranularityRange(dateDiff: number): Granularity {
    let granularity;
    let newInterval;
    if (dateDiff > 180) {
      granularity = 'day';
      newInterval = 1;
    } else if (dateDiff > 31) {
      granularity = 'hour';
      newInterval = 1;
    } else if (dateDiff > 3) {
      granularity = 'minute';
      newInterval = 10;
    } else {
      granularity = 'minute';
      newInterval = 1;
    }
    return { granularity: granularity, interval: newInterval };
  }
}
