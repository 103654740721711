import { Action } from '@ngrx/store';

import { DrivescanDashboardActions } from 'src/app/_store/_drivescan-dashboard/actions';
import { DrivescanDashboard } from 'src/app/_shared/interface/drivescan/drivescan-dashboard.interface';
import { EventCountReducer } from 'src/app/_shared/interface/events/event-count-reducer.interface';
import { DriveFaultState } from 'src/app/_shared/interface/drivescan/drive-fault-code.interface';
import { Heartbeat } from 'src/app/_shared/interface/drivescan/heartbeat.interface';

export interface State {
  drivescanValue: DrivescanDashboard;
  drivescanEventsChannels: DrivescanDashboard;
  drivescanEvents: EventCountReducer;
  driveFaultState: DriveFaultState;
  heartbeat: Heartbeat;
}

const initialState: State = {
  drivescanValue: null,
  drivescanEventsChannels: null,
  drivescanEvents: {
    sags: null,
    swells: null,
    interruptions: null,
    hfImpulses: null,
    driveFault: null,
  },
  driveFaultState: null,
  heartbeat: null,
};

export function reducer(state: State = initialState, action: Action): State {
  const specificAction = action as DrivescanDashboardActions.DrivescanDashboardActionsUnion;
  switch (specificAction.type) {
    case DrivescanDashboardActions.startPollingValue.type:
      return {
        ...state,
      };

    case DrivescanDashboardActions.setValueSucces.type:
      return {
        ...state,
        drivescanValue: specificAction.payload.drivescanValue,
      };

    case DrivescanDashboardActions.setEventsSucces.type:
      return {
        ...state,
        drivescanEvents: {
          sags: specificAction.payload.drivescanEvents.sags,
          swells: specificAction.payload.drivescanEvents.swells,
          interruptions: specificAction.payload.drivescanEvents.interruptions,
          hfImpulses: specificAction.payload.drivescanEvents.hfImpulses,
          driveFault: specificAction.payload.drivescanEvents.driveFault,
        },
      };

    case DrivescanDashboardActions.setEventsChannelsSucces.type:
      return {
        ...state,
        drivescanEventsChannels: specificAction.payload.drivescanEventsChannels,
      };

    case DrivescanDashboardActions.setDriveFaultStateSucces.type:
      return {
        ...state,
        driveFaultState: specificAction.payload.driveFaultState,
      };

    case DrivescanDashboardActions.setHeartbeatSucces.type:
      return {
        ...state,
        heartbeat: specificAction.payload.heartbeat,
      };

    default:
      return state;
  }
}

export const getDrivescanValue = (state: State): State['drivescanValue'] => state.drivescanValue;
export const getDrivescanEvents = (state: State): State['drivescanEvents'] => state.drivescanEvents;
export const getDrivescanEventsChannels = (state: State): State['drivescanEventsChannels'] =>
  state.drivescanEventsChannels;
export const getDriveFaultState = (state: State): State['driveFaultState'] => state.driveFaultState;
