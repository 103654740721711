import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'superBowl',
})
export class SuperBowlPipe implements PipeTransform {
  transform(value: Array<any>, ...args: any[]): string {
    if (!value) {
      return 'N/A';
    } else {
      let unit = args[0].substring(args[0].length - 1);
      let test = value.find((channel) => !!channel[args[0]]);
      return test[args[0]] + ' ' + unit.toUpperCase();
    }
  }
}
