import { take } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import * as fromStore from 'src/app/_store/_reducers';
import { AuthService } from 'src/app/_shared/services';
import { InitActions } from 'src/app/_store/_init/actions';
import { Maintenance } from 'src/environments/temporary-maintenance';
import { NewsActions } from 'src/app/_store/_news/actions';

@Injectable()
export class InitProvider {
  constructor(private store: Store<fromStore.State>, private authService: AuthService) {}

  load(): Promise<boolean> {
    if (!Maintenance) {
      const url = new URL(window.location.href);
      const mpId = url.searchParams.get('mpId');
      const account = url.searchParams.get('account');
      const pathName = url.pathname;
      return new Promise((resolve, reject) => {
        this.authService.authed.pipe(take(1)).subscribe((payload) => {
          if (payload) {
            this.store.dispatch(NewsActions.initNews());
            this.store.dispatch(
              InitActions.init({
                payload: {
                  id: payload.id,
                  accountId: payload.accountId,
                  returnUrl: pathName,
                  isLogin: true,
                  urlParams: { mpId: +mpId, account: +account },
                },
              })
            );

            setTimeout(() => {
              resolve(true);
            }, 3500);
          } else {
            resolve(true);
          }
        });
      });
    }
  }
}
