import { createAction, props } from '@ngrx/store';
import { LatLng } from 'leaflet';

export const setZoomFactor = createAction('[Map] set ZoomFactor', props<{ payload: { zoomFactor: number } }>());

export const setMapCenter = createAction('[Map] set map center', props<{ payload: { center: LatLng } }>());

export const initMap = createAction('[Map] init map', props<{ payload: { center: LatLng; zoomFactor: number } }>());

export const setTabIndex = createAction('[Map] set tab index', props<{ payload: { tabIndex: number } }>());

export type MapActionsUnion = ReturnType<
  typeof setZoomFactor | typeof setMapCenter | typeof initMap | typeof setTabIndex
>;
