import { Action } from '@ngrx/store';

import { NewsActions } from 'src/app/_store/_news/actions';

export interface State {
  id: number;
  isOpened?: boolean;
  content?: string;
}

const initialState: State = {
  id: 0,
  isOpened: false,
  content: '',
};

export function reducer(state: State = initialState, action: Action): State {
  const specificAction = action as NewsActions.NewsActionsUnion;

  switch (specificAction.type) {
    case NewsActions.setNewsSuccess.type:
      return {
        ...state,
        id: specificAction.payload.id,
        content: specificAction.payload.content,
      };

    case NewsActions.openNews.type:
      return {
        ...state,
        isOpened: true,
      };

    case NewsActions.closeNews.type:
      return {
        ...state,
        isOpened: false,
      };
    default:
      return state;
  }
}

export const getNews = (state: State): State => state;
export const getNewId = (state: State): number => state.id;
export const getIsNewsOpened = (state: State): boolean => state.isOpened;
