import { ChangeDetectorRef, Pipe, PipeTransform } from '@angular/core';
import { TranslatePipe, TranslateService } from '@ngx-translate/core';

/**
 * Wrapper arround translate pipe from ngx-translate
 * to use another fallback key if the input one cannot
 * be resolve.
 */
@Pipe({name: 'translateFallback'})
export class TranslateFallbackPipe extends TranslatePipe implements PipeTransform {

	constructor(translate: TranslateService, cd: ChangeDetectorRef) {
		super(translate, cd);
	}

	transform(key: string, fallback: string, ...arg: any[]): string  {
		 const d = super.transform(key, ...arg);
		 if (d === key) {
			 return super.transform(fallback, ...arg)
		 }
		 return d;
	}
}