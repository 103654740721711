import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DiligentApiService } from 'src/app/_shared/services/diligent-api.service';
import { News } from 'src/app/_shared/interface/news.interface';

@Injectable({
  providedIn: 'root',
})
export class NewsService {
  constructor(private diligent: DiligentApiService) {}

  getNews(): Observable<News> {
    return this.diligent.getNews();
  }

  updateNews(news: News): Observable<News> {
    return this.diligent.updateNews(news);
  }

  addNews(content: string): Observable<News> {
    return this.diligent.addNews(content);
  }
}
