import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';

import { MatSnackBarModule } from '@angular/material/snack-bar';

import { NotificationsService } from 'src/app/_shared/modules/notifications/shared/notifications.service';
import { NotificationModalComponent } from 'src/app/_shared/modules/notifications/modal-dialog/notification-modal.component';
import { MatCheckbox } from '@angular/material/checkbox';

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    MatButtonModule,
    MatDialogModule,
    MatSnackBarModule,
    MatIconModule,
    MatCheckbox,
    TranslateModule,
  ],
  declarations: [NotificationModalComponent],
  providers: [NotificationsService],
})
export class NotificationsModule {}
