@if (loading$ | async) {
  <div class="loading">
    <mat-spinner diameter="48" color="primary"></mat-spinner>
  </div>
}
<form (submit)="login($event)" [formGroup]="loginForm">
  <mat-form-field appearance="fill" data-cy="email">
    <mat-label>{{ 'login.email' | translate }}</mat-label>
    <input type="email" matInput formControlName="email" />
    @if (email.hasError('email')) {
      <mat-error>{{ 'login.errors.email.invalid' | translate }}</mat-error>
    }
    @if (email.hasError('required')) {
      <mat-error>{{ 'global.errors.fieldRequired' | translate }}</mat-error>
    }
  </mat-form-field>
  <mat-form-field class="password-input" appearance="fill" data-cy="password">
    <mat-label>{{ 'login.password' | translate }}</mat-label>
    <input [type]="maskPassword ? 'password' : 'text'" matInput formControlName="password" />
    <mat-icon
      class="mask-toggle"
      matSuffix
      color="accent"
      (click)="toggleMasking()"
      [svgIcon]="maskPassword ? 'eye' : 'eye-off'"
    ></mat-icon>
    @if (password.hasError('minLength')) {
      <mat-error>{{
        'login.errors.password.minLength' | translate : { minLength: minLength }
      }}</mat-error>
    }
    @if (password.hasError('required')) {
      <mat-error>{{ 'global.errors.fieldRequired' | translate }}</mat-error>
    }
  </mat-form-field>
  <a
    href="javascript:void(0);"
    (click)="showForgotPassword()"
    data-cy="forgot-password"
    class="mat-small forgot-password"
    >{{ 'login.forgot-password.title' | translate }}</a
    >
    @if (!isQubescanLogin) {
      <a
        href="https://help.powerside.com/s/article/Firmware-and-Software"
        target="_blank"
        class="update-message"
        >
        Important : upgrade to firmware 3.10.5 or later required.
      </a>
    }
    <button
      type="submit"
      mat-flat-button
      color="accent"
      [disabled]="loginForm.invalid || (loading$ | async)"
      data-cy="login_button"
      >
      {{ 'login.login' | translate }}
    </button>
  </form>
  