import { select, Store } from '@ngrx/store';
import { Directive, Injectable, OnDestroy, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router, UrlTree } from '@angular/router';
import { Observable, of } from 'rxjs';
import { switchMap, take, tap } from 'rxjs/operators';

import { AuthService } from 'src/app/_shared/services';
import * as fromUser from 'src/app/_store/_reducers';
import { BaseStructuralDirective } from 'src/app/_shared/directive/base-structure.directive';

function validationObservable(
  authService: AuthService,
  router: Router,
  store: Store<fromUser.State>
): Observable<boolean | UrlTree> {
  return authService.renewToken().pipe(
    take(1),
    // switch the stream to the auth observable
    switchMap(() =>
      store.pipe(select(fromUser.getUser)).pipe(
        // if we have a token at this point, allow navigation; otherwise return a UrlTree to the login page
        switchMap((payload) => {
          return payload?.role.name === 'Administrator' ? of(true) : of(router.parseUrl('/profile'));
        })
      )
    )
  );
}

@Directive({
  selector: '[appIsAdmin]',
})
export class AdministrationDirective extends BaseStructuralDirective implements OnInit, OnDestroy {
  constructor(
    templateRef: TemplateRef<any>,
    viewContainer: ViewContainerRef,
    private authService: AuthService,
    private router: Router,
    private store: Store<fromUser.State>
  ) {
    super(templateRef, viewContainer);
  }

  validationCondition(): Observable<boolean | UrlTree> {
    return validationObservable(this.authService, this.router, this.store);
  }
}

@Injectable({
  providedIn: 'root',
})
export class AdministratorGuard  {
  constructor(private authService: AuthService, private router: Router, private store: Store<fromUser.State>) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
    return validationObservable(this.authService, this.router, this.store).pipe(
      // re-parse the token to kick off a fresh call to user/account api
      tap((p) => (p === true ? this.authService.reload() : null))
    );
  }
}
