import { Component } from '@angular/core';

export interface AgMatButtonParams {
  text?: string;
  icon?: string;
  viewerMode?: boolean;
  click: () => void;
  textOnly?: boolean;
}

@Component({
  template: `
    @if (!params.textOnly) {
    <button
      mat-mini-fab
      style="height: 30px; width: 30px; margin-top: 5px;"
      color="accent"
      [disabled]="params?.viewerMode"
      (click)="params.click()"
    >
      @if (params.icon) {
      <mat-icon [svgIcon]="params.icon"></mat-icon>
      }
      {{ params.text }}
    </button>
    } @else {
    <span>{{ params.text }}</span>
    }
  `,
})
export class AgMatButtonComponent {
  public params: AgMatButtonParams;

  agInit(params: any): void {
    this.params = params.resolve(params.value) || {};
  }

  btnClickedHandler(event): void {
    this.params.click();
  }
}
