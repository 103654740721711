import { NgModule } from '@angular/core';

import { DocumentDownloadDirective } from 'src/app/_shared/modules/document/document.directive';
import { DocumentService } from 'src/app/_shared/modules/document/document.service';

@NgModule({
  imports: [],
  declarations: [DocumentDownloadDirective],
  providers: [DocumentService],
  exports: [DocumentDownloadDirective],
})
export class DocumentModule {}
