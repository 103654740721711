import { createAction, props } from '@ngrx/store';

export const openNews = createAction('[News] Open News Communications');
export const closeNews = createAction('[News] Close News Communications');
export const initNews = createAction('[News] Init News Communications');
export const addNews = createAction('[News] Create News Communications', props<{ payload: { content: string } }>());
export const setNews = createAction('[News] Set News Communications', props<{ payload: { content: string } }>());
export const setNewsSuccess = createAction(
  '[News] Set News Communications Success',
  props<{ payload: { id: number; content: string } }>()
);
export const setNewsFail = createAction('[News] Set News Fail', props<{ error: any }>());

export type NewsActionsUnion = ReturnType<
  | typeof openNews
  | typeof closeNews
  | typeof addNews
  | typeof setNewsFail
  | typeof setNews
  | typeof initNews
  | typeof setNewsSuccess
>;
