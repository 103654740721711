import { createAction, props } from '@ngrx/store';

import { MaintenanceCommand, MaintenanceRequestState } from 'src/app/_shared/interface/maintenance/maintenance';

export const getMaintenanceCommand = createAction('[Maintenance] get maintenance commands');

export const getMaintenanceRequests = createAction(
  '[Maintenance] get maintenance requests',
  props<{
    payload: { mpId: number };
  }>()
);

export const getMaintenanceCommandSuccess = createAction(
  '[Maintenance] get maintenance commands success',
  props<{
    payload: { commands: MaintenanceCommand[] };
  }>()
);

export const getMaintenanceRequestsSuccess = createAction(
  '[Maintenance] get maintenance requests success',
  props<{
    payload: { mpId: number; requests: MaintenanceRequestState[] };
  }>()
);

export const createMaintenanecRequetsSuccess = createAction(
  '[Maintenance] create maintenance requests success',
  props<{ payload: { request: MaintenanceRequestState } }>()
);

export const updateRequestsState = createAction(
  '[Maintenance] update maintenance request status',
  props<{ payload: { request: MaintenanceRequestState } }>()
);

export const updateRequestsStateSuccess = createAction(
  '[Maintenance] update maintenance request status success',
  props<{ payload: { request: MaintenanceRequestState } }>()
);

export const setIsAvailable = createAction('[Maintenance] set is available', props<{ payload: boolean }>());

export type MaintenanceActionsUnion = ReturnType<
  | typeof getMaintenanceCommand
  | typeof getMaintenanceRequests
  | typeof getMaintenanceCommandSuccess
  | typeof getMaintenanceRequestsSuccess
  | typeof createMaintenanecRequetsSuccess
  | typeof updateRequestsState
  | typeof updateRequestsStateSuccess
  | typeof setIsAvailable
>;
