import { SortDirection } from '@angular/material/sort';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { IUserUpdateParams, IUserUpdateResponse } from 'src/app/_shared/classes/userUpdateParams.interface';
import { DiligentApiService } from 'src/app/_shared/services/diligent-api.service';
import { IAccount } from 'src/app/_shared/classes/account';
import { IMember, IMemberQuery } from 'src/app/_shared/classes/member.interface';
import { MessageReturn } from 'src/app/_shared/interface/message-return.interface';
import { PaginatedModel } from 'src/app/_shared/interface/pagination.interface';

@Injectable({
  providedIn: 'root',
})
export class AccountsService {
  accountName: string;

  constructor(private diligentService: DiligentApiService) {}

  getAccount(id: number): Observable<IAccount> {
    return this.diligentService.getAccount(id);
  }

  updateAccount(id: number, account: IAccount): Observable<{}> {
    return this.diligentService.updateAccount(id, account);
  }

  tempGetAccountUsersList(
    accountId: string,
    count: number,
    offset: number,
    sortActive: string,
    sortDirection: SortDirection,
    includePartner?: boolean,
    exceptUserRoles?: Array<string>,
    mpIds?: Array<string | number>
  ): Observable<PaginatedModel<IMember>> {
    return this.diligentService.tempGetAccountUsersList(
      accountId,
      count,
      offset,
      sortActive,
      sortDirection,
      includePartner,
      exceptUserRoles,
      mpIds
    );
  }

  getAccountUsersList(query: IMemberQuery): Observable<PaginatedModel<IMember>> {
    return this.diligentService.getAccountUsersList(query);
  }

  createAccount(account: IAccount): Observable<{ message: string }> {
    return this.diligentService.createAccount(account);
  }

  addUser(addUserParams: IUserUpdateParams): Observable<{ id: number }> {
    return this.diligentService.addUser(addUserParams);
  }

  updateUser(userUpdateParams: IUserUpdateParams, userId: number): Observable<IUserUpdateResponse> {
    return this.diligentService.updateUser(userUpdateParams, userId);
  }

  disassociateUserAccount(userId: number, accountId: number): Observable<{}> {
    return this.diligentService.disassociateUserAccount(userId, accountId);
  }

  resendActivationEmail(userId: number, accountId: number): Observable<{ id: number }> {
    return this.diligentService.resendActivationEmail(userId, accountId);
  }

  deleteAccount(accountId: number): Observable<MessageReturn> {
    return this.diligentService.deleteAccount(accountId);
  }
  makeAccountPrimary(accountId: number, userId: number, isPrimary: boolean): Observable<{}> {
    return this.diligentService.makeAccountPrimary(accountId, userId, isPrimary);
  }

  updatePartner(accountId: number, partnerId: number): Observable<[]> {
    return this.diligentService.updatePartner(accountId, partnerId);
  }
}
