import { Directive, Input, OnDestroy, OnInit } from '@angular/core';
import * as tinymce from '@tinymce/tinymce-angular';
import { Observable, of, Subscription, throwError } from 'rxjs';
import { catchError, switchMap, take, tap } from 'rxjs/operators';

import { environment } from 'src/environments/environment';

@Directive({
  selector: '[appEditor]',
})
export class EditorDirective implements OnInit, OnDestroy {
  private plugins = [
    'fullscreen advcode image casechange formatpainter autolink lists checklist media mediaembed pageembed permanentpen powerpaste table advtable',
  ];

  private toolbar = 'formatselect | bold italic underline strikethrough | bullist numlist | link image | fullscreen';

  private menubar = 'edit view insert format table tools help';

  /**
   * to pass additional property to the init config of the editor
   */
  @Input() initEditor: any = {};

  /**
   * if provide use this callback to upload a file and return
   * the url of the uploaded file.
   * the formData contains the file
   */
  @Input() fileUploadGetNameCallback: (blobFileName: string) => Observable<string>;
  @Input() fileUploadCallback: (formData: FormData) => Observable<string>;
  @Input() filePickerCallback: (formData: FormData) => Observable<any>;
  @Input() imagesList?: () => Observable<any>;

  /**
   * if enable the editor is in readonly mode.
   * The readonly mode is to be disabled and in inline mode
   */
  @Input() readonly: boolean;

  private baseInit = {
    height: 300,
    plugins: this.plugins,
    toolbar: this.toolbar,
    menubar: this.menubar,
    branding: false,
    setup: (editor): void => {
      this.editorApi = editor;
    },
    image_list: [],
    image_dimensions: false,
    image_caption: false,
    image_description: false,
    image_title: false,
    image_uploadtab: false,
    content_style: 'img {max-width: 75%; max-height: auto; border:  2px solid rgb(0, 82, 204); border-radius: 5px;}',
    resize: true,
  };

  private editorApi: any;

  private subImageList: Subscription;

  constructor(private editor: tinymce.EditorComponent) {}

  ngOnInit(): void {
    this.editor.init = Object.assign(this.baseInit, this.initEditor ?? {});

    if (this.imagesList) {
      this.editor.init.image_list = (sucess): void => {
        this.subImageList?.unsubscribe();
        this.subImageList = this.imagesList().subscribe((items) => {
          sucess(items);
        });
      };
    }

    if (this.readonly) {
      this.editor.disabled = true;
      this.editor.init.menubar = '';
      this.editor.init.toolbar = [];
    }

    this.editor.plugins = this.plugins.join(' ');
    this.editor.apiKey = environment.tinymce;

    if (this.filePickerCallback) {
      this.editor.init.file_picker_callback = (callback, value, meta): void => {
        interface HTMLInputEvent extends Event {
          target: HTMLInputElement & EventTarget;
        }

        const input: HTMLInputElement = document.createElement('input');

        input.setAttribute('type', 'file');
        input.setAttribute('accept', 'image/*');

        input.onchange = (e?: HTMLInputEvent): void => {
          const file: File = input.files[0];
          const fileName: string = file.name;
          const reader: FileReader = new FileReader();
          reader.onload = (event: ProgressEvent<FileReader>): void => {
            let fileInfo = {
              blob: file,
              name: fileName,
            };

            of(fileInfo)
              .pipe(
                switchMap((fileInfo) => {
                  if (!fileInfo.name || fileInfo.name === '') {
                    const error = 'operation cancel, remove image from document';
                    this.editorApi.undoManager.undo();
                    return throwError(error);
                  }

                  const formData: FormData = new FormData();

                  formData.append('file', fileInfo.blob, fileInfo.name);

                  return this.filePickerCallback(formData).pipe(
                    take(1),
                    catchError((error) => {
                      this.editorApi.undoManager.undo();
                      return throwError(
                        error?.error?.code === 'ER_DUP_ENTRY'
                          ? `image with name ${fileInfo.name} already exists`
                          : 'failed to uploaded to backed'
                      );
                    })
                  );
                }),
                catchError((error) => {
                  console.error(error);
                  return of();
                })
              )
              .subscribe((cb) => {
                callback(cb);
              });
          };
          reader.readAsDataURL(file);
        };
        input.click();
      };

      if (this.fileUploadCallback) {
        this.editor.init.images_upload_handler = (blobInfo, sucess, failure): void => {
          const resolveFileName = this.fileUploadGetNameCallback
            ? this.fileUploadGetNameCallback
            : (): Observable<string> => of(blobInfo.filename());
          resolveFileName(blobInfo.filename())
            .pipe(
              switchMap((fileName: string) => {
                if (!fileName || fileName === '') {
                  const error = 'operation cancel, remove image from document';
                  this.editorApi.undoManager.undo();
                  return throwError(error);
                }
                const formData: FormData = new FormData();
                formData.append('file', blobInfo.blob(), fileName);
                return this.fileUploadCallback(formData).pipe(
                  take(1),
                  tap(sucess),
                  catchError((error) => {
                    this.editorApi.undoManager.undo();
                    return throwError(
                      error?.error?.code === 'ER_DUP_ENTRY'
                        ? `image with name ${fileName} already exists`
                        : 'failed to uploaded to backed'
                    );
                  })
                );
              }),
              catchError((error) => {
                failure(error);
                return of();
              })
            )
            .subscribe(sucess);
        };
      }
    } else {
      this.editor.init.file_picker_callback = (cb, value, meta): void => {
        interface HTMLInputEvent extends Event {
          target: HTMLInputElement & EventTarget;
        }

        const input: HTMLInputElement = document.createElement('input');

        input.setAttribute('type', 'file');
        input.setAttribute('accept', 'image/*');

        input.onchange = (e?: HTMLInputEvent): void => {
          const file: File = input.files[0];
          const reader: FileReader = new FileReader();

          reader.onload = (event: ProgressEvent<FileReader>): void => {
            const id = 'blobid' + new Date().getTime();
            const blobCache = this.editorApi.editorUpload.blobCache;
            const imgPreview = reader.result as string;
            const base64 = imgPreview.split(',')[1];
            const blobInfo = blobCache.create(id, file, base64);
            blobCache.add(blobInfo);

            /* call the callback and populate the Title field with the file name */
            cb(blobInfo.blobUri(), { title: file.name });
          };
          reader.readAsDataURL(file);
        };
        input.click();
      };
    }
  }

  ngOnDestroy(): void {
    this.subImageList?.unsubscribe();
  }
}
