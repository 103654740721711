import { select, Store } from '@ngrx/store';
import { Component, OnDestroy, Output, EventEmitter, HostListener, Input } from '@angular/core';
import { Router, ActivatedRoute, Params, NavigationEnd } from '@angular/router';
import { forkJoin, Observable } from 'rxjs';
import { filter, take } from 'rxjs/operators';

import { IAccount } from 'src/app/_shared/classes/account';
import * as fromUser from 'src/app/_store/_reducers';
import { OnDestroyMixin, untilComponentDestroyed } from 'src/app/_shared/classes/component-destroy.class';
import { RecommendationCount } from 'src/app/_shared/interface/recommendation.interface';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss'],
})
export class NavigationComponent extends OnDestroyMixin implements OnDestroy {
  @Input() mobileMenuOpen: boolean;
  @Output() navigated = new EventEmitter();

  @HostListener('window:resize', ['$event'])
  onResize(event?): void {
    this.screenWidth = window.innerWidth;
    if (this.screenWidth < 992) {
      this.smallScreen = true;
    } else {
      this.smallScreen = false;
    }
  }

  current: any = null;
  account: IAccount;
  queryParams: any = {};
  isSysAdmin = false;
  isCustomerAdmin = false;
  isInsite = false;
  maintenanceAccess = false;
  recommendationCount$: Observable<RecommendationCount>;
  unseenReportResults$: Observable<Array<number>>;
  qubeScanModel$: Observable<string>;
  measurementPointTypeId: number;
  showHint: boolean;
  hintIsOpened: boolean = true;

  private screenWidth: number;
  smallScreen: boolean;

  constructor(private router: Router, private route: ActivatedRoute, private store: Store<fromUser.State>) {
    super();
    this.router.events
      .pipe(
        untilComponentDestroyed(this),
        filter((event) => event instanceof NavigationEnd)
      )
      .subscribe((u: NavigationEnd) => {
        if (u.url) {
          this.current = u.url.split('?').shift();
        }
      });

    this.recommendationCount$ = this.store.pipe(select(fromUser.getRecommendationCount));
    this.unseenReportResults$ = this.store.select((state) => state.user.unseenReportResults);
    this.qubeScanModel$ = this.store.select((state) => state.user.qubeScanModel);

    this.route.queryParamMap.pipe(untilComponentDestroyed(this)).subscribe((params: Params) => {
      forkJoin([
        this.store.pipe(select(fromUser.getMp)).pipe(take(1)),
        this.store.pipe(select(fromUser.getAccount)).pipe(take(1)),
        this.store.pipe(select(fromUser.getUser)).pipe(take(1)),
        this.store.pipe(select(fromUser.getIsSysAdmin)).pipe(take(1)),
      ]).subscribe(([mp, account, user, isSysAdmin]) => {
        this.queryParams = params;
        this.isSysAdmin = isSysAdmin;
        if (mp) {
          this.measurementPointTypeId = mp.measurementPointTypeId;
          this.maintenanceAccess =
            mp.measurementPointTypeId === 1 ||
            (mp.measurementPointTypeId === 3 && account.isPartner === 1) ||
            isSysAdmin;
        }
        if (mp && (mp.measurementPointTypeId === 1 || mp.measurementPointTypeId === 3)) {
          this.isInsite = false;
        } else {
          this.isInsite = true;
        }
        this.account = account;
        if (user) {
          this.isCustomerAdmin = user.role.name === 'Administrator';
        }
      });
    });

    this.store
      .select((state) => state.user.userPreferences.hint?.reportWriter)
      .pipe(untilComponentDestroyed(this))
      .subscribe((showHint) => {
        this.showHint = showHint === undefined ? true : showHint;
      });

    this.store
      .pipe(select(fromUser.getHint))
      .pipe(untilComponentDestroyed(this))
      .subscribe((hint) => {
        this.hintIsOpened =
          hint?.firmwareUpdate ||
          hint?.notificationCenter ||
          hint?.firmwareAndNotification ||
          hint?.createMp ||
          hint?.associate
            ? false
            : true;
      });

    this.onResize();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  public navigate(path): void {
    this.router
      .navigate(path, {
        queryParams: {
          account: this.queryParams.params.account,
          mpId: this.queryParams.params.mpId,
        },
      })
      .then((value) => {
        if (value) {
          this.navigated.emit();
        }
      });
  }
}
