import { Injectable } from '@angular/core';

import { DiligentApiService } from 'src/app/_shared/services';

@Injectable()
export class DocumentService {
  constructor(private diligentService: DiligentApiService) {}

  public getDocumentURL(documentId: number, ttl: number = 3000): Promise<string> {
    return this.diligentService.getDocumentDownload(documentId, ttl);
  }

  public downloadDocument(documentId: number): Promise<void> {
    return this.getDocumentURL(documentId).then((url: string) => {
      this.downloadURL(url);
    });
  }

  public downloadURL(url: string, filename?: string): void {
    const link = document.createElement('a');
    link.setAttribute('type', 'hidden');
    link.href = url;
    if (filename) {
      link.download = filename;
    }
    document.body.appendChild(link);
    link.click();
    link.remove();
  }
}
