import { Component, Input, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import * as moment from 'moment';
import { TranslateService } from '@ngx-translate/core';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarRef } from '@angular/material/snack-bar';

import { GraphManagerService } from 'src/app/_shared/services/graph-manager.service';
import { OnDestroyMixin, untilComponentDestroyed } from 'src/app/_shared/classes/component-destroy.class';
import { NotificationsService } from 'src/app/_shared/modules/notifications/shared/notifications.service';
import { NotesService } from 'src/app/_shared/services/notes.service';
import { PopupEventService } from 'src/app/_shared/services/popup-event.service';
import { DSTPipe } from 'src/app/_shared/pipes/dst.pipe';
import { popoverIcons } from 'src/app/_shared/classes/EventPopoverIcons';

@Component({
  selector: 'app-mat-info-data',
  templateUrl: './mat-info-data.component.html',
  styleUrls: ['./mat-info-data.component.scss'],
  providers: [DSTPipe],
})
export class MatInfoDataComponent extends OnDestroyMixin implements OnInit, OnDestroy {
  @ViewChild('infoDrawer') infoDrawerTemplate: TemplateRef<any>;
  @ViewChild('trendAlarmList') trendAlarmListTemplate: TemplateRef<any>;
  @Input() mpId: number;
  @Input() mpTimezone: string;
  @Input() mpTypeId: number;
  @Input() accountId: string;
  @Input() isPartner: boolean;
  @Input() isSysAdmin: boolean;

  infoIcons = popoverIcons;
  loadingInfoDrawer = false;
  infoEvent: any = {};
  infoData: any = {};
  eventTypeId: number;
  onDismiss: Boolean = false;

  private eventsSubscription: Subscription;
  private snackBarRef: MatSnackBarRef<any>;

  constructor(
    private graphManager: GraphManagerService,
    private matSnackBar: MatSnackBar,
    private popupEvent: PopupEventService,
    private notificationsService: NotificationsService,
    private translateService: TranslateService,
    private notes: NotesService
  ) {
    super();
  }

  // Initialize the subscription on the PopupEventService Observable
  ngOnInit(): void {
    this.eventsSubscription = this.popupEvent.getPopupEvent().subscribe((event) => {
      if (event) {
        this.openDrawer(event);
      } else {
        this.closeDrawer();
      }
    });
  }

  ngOnDestroy(): void {
    this.eventsSubscription.unsubscribe();
    super.ngOnDestroy();
  }

  openDrawer(event): void {
    if (event.records) {
      if (event.records.length === 1) {
        event = event.records[0];
      } else {
        event.records = event.records.sort((a, b) => b.severity - a.severity);
      }
    }

    this.infoEvent = event;
    this.loadingInfoDrawer = true;

    const halfWidth = window.innerWidth / 2;
    let position: MatSnackBarHorizontalPosition = 'start';
    if (event.clientX > halfWidth - 100 && event.clientX < halfWidth + 100) {
      position = 'center';
    } else if (event.clientX > halfWidth - 100) {
      position = 'right';
    } else if (event.clientX < halfWidth + 100) {
      position = 'left';
    }

    const openSnackBar = (): void => {
      if (!this.snackBarRef) {
        this.snackBarRef = this.matSnackBar.openFromTemplate(this.infoDrawerTemplate, {
          horizontalPosition: position,
          panelClass: 'snackbar-no-padding',
        });

        this.snackBarRef.afterDismissed().subscribe(() => {
          if (this.onDismiss) {
            this.infoData = {};
            this.infoEvent = {};
            this.onDismiss = false;
          }
        });

        document.onclick = (event: MouseEvent): void => {
          const target = event.target as HTMLElement;
          if (!target.closest('snack-bar-container')) {
            if (this.snackBarRef) {
              this.snackBarRef.dismiss();
              this.snackBarRef = null;
            }
            document.onclick = undefined;
          }
        };
      }
    };

    if (event?.point?.series?.userOptions?.eventTypeId) {
      let startX = event.point.x;
      if (event.point.series.userOptions.name === 'Others' || event.point.series.userOptions.name === 'Restart') {
        this.eventTypeId = -1;
      } else {
        this.eventTypeId = event.point.series.userOptions.eventTypeId;
      }
      if (event.point.series.userOptions.name === 'Interruption') {
        startX = event.point.series.userOptions.data[0][0];
      }
      this.graphManager
        .getSingleEventData(this.eventTypeId, moment(startX))
        .pipe(untilComponentDestroyed(this))
        .subscribe((oneEvent) => {
          if (oneEvent.records[0]) {
            this.loadingInfoDrawer = false;

            if (this.infoData.documentId && this.infoData.documentId === oneEvent.records[0].documentId) {
              openSnackBar();
              return;
            }

            this.infoData = oneEvent.records[0];
            this.infoData.originalTriggeredWhen = this.infoData.triggeredWhen;
            const triggerMoment = moment(this.infoData.triggeredWhen, 'YYYY-MM-DDTHH:mm:SSSZ').tz(this.mpTimezone);
            this.infoData.tzShiftedDate = triggerMoment.format('LL');
            this.infoData.tzShiftedTime = triggerMoment.format('LT');
            openSnackBar();
          } else {
            this.notificationsService.notify(this.translateService.instant('events.notAbleToFetch'));
          }
        });
    } else if (event?.nature === 'alarm') {
      this.loadingInfoDrawer = false;
      this.snackBarRef = this.matSnackBar.openFromTemplate(this.infoDrawerTemplate, {
        horizontalPosition: position,
        panelClass: 'snackbar-no-padding',
      });
    } else if (event.records) {
      this.snackBarRef = this.matSnackBar.openFromTemplate(this.trendAlarmListTemplate, {
        horizontalPosition: position,
        panelClass: 'snackbar-no-padding',
      });
    } else {
      this.notes
        .getSingleNote(this.mpId, moment(event.point.x))
        .pipe(untilComponentDestroyed(this))
        .subscribe((oneNote) => {
          this.loadingInfoDrawer = false;

          if (this.infoData.id && this.infoData.id === oneNote[0].id) {
            this.closeDrawer();
            return;
          }

          this.infoData = oneNote[0];
          this.infoData.tzShiftedDate = moment(this.infoData.startDateTime).tz(this.mpTimezone).format('LL');
          this.infoData.tzShiftedTime = moment(this.infoData.startDateTime)
            .tz(this.mpTimezone)
            .format(`${moment().creationData().locale.longDateFormat('LT')} z`);
          openSnackBar();
        });
    }
  }

  closeDrawer(): void {
    if (this.snackBarRef) {
      this.onDismiss = true;
      this.snackBarRef.dismiss();
      this.snackBarRef = null;
    }
  }

  selectAlarm(alarm): void {
    this.closeDrawer();
    this.popupEvent.toggleInfoDrawer(alarm);
  }
}
