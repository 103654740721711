import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
import * as humanizer from 'humanize-duration';

@Pipe({
  name: 'eventDuration'
})
export class EventDurationPipe implements PipeTransform {


  transform(value: any, ...args: any[]): string {
    if (!value) {
      return value;
    }
    const duration = moment.duration(value);
    const ms = duration.milliseconds();
    const secs = duration.seconds();
    const mins = duration.minutes();
    const hrs = duration.hours();
    const days = duration.days();
    let options = {
      units: ['d', 'h', 'm', 's'],
      round: false,
      maxDecimalPoints: 2,
    };
    if (ms > 0 && days === 0 && hrs === 0 && mins === 0 && secs === 0) {
        options.units = ['ms'];
        const millisecondsFormatConfig = {
          language: 'shortEn',
          serialComma: false,
          languages: {
            shortEn: {
              ms: (): string => 'ms',
              decimal: '.'
            },
          },
        };
        options = {...options, ...millisecondsFormatConfig };
    }
    if (secs > 0 && days === 0 && hrs === 0 && mins === 0) {
       options.units = ['s'];
       options.maxDecimalPoints = 3; 
    }
    if (days > 0) {
      options.units = ['d', 'h'];
      options.maxDecimalPoints = 1;
    }
    return this.getHumanizeFormat(value, options);
    
  }

  getHumanizeFormat(value: any, options): any {
    return humanizer(value, {
      round: false,
      ...options
    });

  }

}