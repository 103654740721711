import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dashboardChannelFilter',
})
export class DashboardChannelFilterPipe implements PipeTransform {
  transform(value: Array<Array<string>>, ...args: any[]): boolean {
    if (
      value.findIndex((channel) => channel.includes(args[0].channelName)) === -1 ||
      value.findIndex((channel) => channel.includes(args[0].channelName)) === args[1]
    ) {
      return true;
    } else {
      return false;
    }
  }
}
