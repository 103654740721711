import { Pipe, PipeTransform } from '@angular/core';
import { environment } from 'src/environments/environment';



@Pipe({name: 'envKey'})
export class EnvironmentKeyPipe implements PipeTransform {
    transform(key: string): string | any {
        return environment[key];
    }
}