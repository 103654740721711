import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
import { TranslateService } from '@ngx-translate/core';

const MS_IN_DAY = 24*60*60*1000;

@Pipe({
  name: 'flexibleDateFormat'
})
export class FlexibleDateFormatPipe implements PipeTransform {

  constructor(private translateService: TranslateService) {}

  transform(value: string, timezone: any = null): string {
    const currentDate = new Date();
    const valueDate = new Date(value);
    const difference = this.getFullDaysDifference(currentDate, valueDate, timezone);

    if (difference > 5) {
      return moment(value).tz(timezone).format('M/D/YYYY');
    } else if (difference > 1) {
      return moment(value).tz(timezone).format('dddd');
    } else if (difference > 0) {
      return this.translateService.instant('notes.yesterday');
    } else if (difference === 0) {
      return moment(value).tz(timezone).format('hh:mm A');
    }

    return moment(value).tz(timezone).format('M/D/YYYY h:mm A z');

  }

  getFullDaysDifference(date1: Date, date2: Date, timezone: any = null): number {
    const correctedDate1 = moment(date1).tz(timezone);
    const correctedDate2 = moment(date2).tz(timezone);

    const fullDay1 = new Date(correctedDate1.year(), correctedDate1.month(), correctedDate1.date());
    const fullDay2 = new Date(correctedDate2.year(), correctedDate2.month(), correctedDate2.date());

    return (fullDay1.getTime() - fullDay2.getTime()) / MS_IN_DAY;
  }
}
