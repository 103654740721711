<button
  [disabled]="beginningOfCreation || !havePast"
  (click)="changeTimePosition('past')"
  mat-flat-button
  data-cy="past"
  id="past"
>
  <
</button>
@if (!scopeDisabled) {
<button
  (click)="changeTimeScope('years', 'day')"
  id="year"
  data-cy="year"
  mat-flat-button
  [ngClass]="currentScope === 'years' ? 'clicked' : ''"
>
  y
</button>
<button
  (click)="changeTimeScope('months', 'hour')"
  id="month"
  data-cy="month"
  mat-flat-button
  [ngClass]="currentScope === 'months' ? 'clicked' : ''"
>
  m
</button>
<button
  (click)="changeTimeScope('weeks', 'hour')"
  id="week"
  data-cy="week"
  mat-flat-button
  [ngClass]="currentScope === 'weeks' ? 'clicked' : ''"
>
  w
</button>
<button
  (click)="changeTimeScope('days', 'minute')"
  id="day"
  data-cy="day"
  mat-flat-button
  [ngClass]="currentScope === 'days' ? 'clicked' : ''"
>
  d
</button>
@if (type === 'dashboard') {
<button
  (click)="changeTimeScope('hours', 'minute')"
  id="hour"
  data-cy="hour"
  mat-flat-button
  [ngClass]="currentScope === 'hours' ? 'clicked' : ''"
>
  h
</button>
} } @else {
<div class="title-only">{{ title }}</div>
}
<button (click)="changeTimePosition('future')" mat-flat-button data-cy="future" id="right" [disabled]="!haveFutur">
  >
</button>
