import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { Observable, take } from 'rxjs';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';

import * as fromNews from 'src/app/_store/_reducers';
import { OnDestroyMixin } from 'src/app/_shared/classes/component-destroy.class';
import { News } from 'src/app/_shared/interface/news.interface';
import { NewsActions } from 'src/app/_store/_news/actions';

@Component({
  selector: 'app-release-news',
  templateUrl: './release-news.component.html',
  styleUrls: ['./release-news.component.scss'],
})
export class ReleaseNewsComponent extends OnDestroyMixin implements OnDestroy, OnInit {
  isSysAdmin: boolean;
  releaseText: string;
  editorConfig = {
    height: 300,
    menubar: false,
    image_uploadtab: true,
    image_list: null,
    file_picker_types: 'image',
    image_dimensions: true,
  };
  news$: Observable<News>;
  newsForm: FormGroup;
  editNews: boolean = false;
  addNews: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<ReleaseNewsComponent>,
    private store: Store<fromNews.State>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    super();
    this.isSysAdmin = data.isSysAdmin;
    this.news$ = this.store.select<News>(fromNews.getNews);
  }

  ngOnInit(): void {
    const newsInformation = this.releaseText || '';
    this.newsForm = new FormGroup({
      newsInformation: new FormControl(newsInformation, [Validators.required]),
    });
  }

  public get newsInformation(): AbstractControl {
    return this.newsForm.get('newsInformation');
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  onClose(): void {
    this.store.dispatch(NewsActions.closeNews());
    this.dialogRef.close();
  }

  closeDialog(): void {
    this.store.dispatch(NewsActions.closeNews());
    this.dialogRef.close();
  }

  onEdit(addNews: boolean): void {
    this.editNews = true;
    this.addNews = addNews;
    this.news$.pipe(take(1)).subscribe((news) => {
      this.newsInformation.setValue(news.content);
      this.newsForm.markAsPristine();
    });
  }

  onCancel(): void {
    this.editNews = false;
  }

  onSubmit(): void {
    if (this.addNews) {
      this.store.dispatch(NewsActions.addNews({ payload: { content: this.newsInformation.value } }));
    } else {
      this.store.dispatch(NewsActions.setNews({ payload: { content: this.newsInformation.value } }));
    }
    this.editNews = false;
  }
}
