import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';

import { NotificationsService } from 'src/app/_shared/modules/notifications/shared/notifications.service';
import { DiligentApiService } from '.';

@Injectable({
  providedIn: 'root',
})
export class SubscriptionService {
  constructor(
    private translateService: TranslateService,
    private notificationsService: NotificationsService,
    private diligentService: DiligentApiService
  ) {}

  subscribeAlert(accountId: number = null): void {
    this.notificationsService.alert(
      this.translateService.instant('subscription.subscribeToQubescan.content'),
      this.translateService.instant('subscription.subscribeToQubescan.title'),
      'decagram',
      'Close',
      null,
      { showRequestUpgrade: true, accountId: accountId }
    );
  }

  requestToUpgradeSubscription(accountId: number): Observable<{ message: string }> {
    return this.diligentService.requestToUpgradeSubscription(accountId);
  }
}
