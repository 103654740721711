import { createAction, props } from '@ngrx/store';

export const initRangeSelectorReport = createAction(
  '[Range-Selector] Init Report',
  props<{
    payload: {
      timezone: string;
      commissionedWhen: string;
      usePreCommissionStart: boolean;
    };
  }>()
);
export const initRangeSelectorGlobal = createAction(
  '[Range-Selector] Init Global',
  props<{
    payload: {
      timezone: string;
      commissionedWhen: string;
      usePreCommissionStart: boolean;
    };
  }>()
);
export const setRangeSelector = createAction(
  '[Range-Selector] Set',
  props<{
    payload: {
      rangeType: string;
      type: string;
      startDatePeriod?: string;
      endDatePeriod?: string;
      startDateRange?: string;
      endDateRange?: string;
      title: string;
    };
  }>()
);
export const setRange = createAction(
  '[Range-Selector] Set Range',
  props<{
    payload: {
      rangeType: string;
      startDate: moment.Moment;
      endDate: moment.Moment;
      type?: string;
    };
  }>()
);
export const setScope = createAction(
  '[Range-Selector] Set Scope',
  props<{
    payload: {
      rangeType: string;
      scope: string;
    };
  }>()
);
export const changeType = createAction(
  '[Range-Selector] change type',
  props<{
    payload: {
      rangeType: string;
      type: string;
      timezone: string;
    };
  }>()
);
export const setIntervalSuccess = createAction(
  '[Range-Selector] Set Interval Success',
  props<{ payload: { rangeType: string; startDate: string; endDate: string } }>()
);
export const setTitleSuccess = createAction(
  '[Range-Selector] Set Title Success',
  props<{ payload: { rangeType: string; title: string } }>()
);
export const setPrecommissionStart = createAction(
  '[Range-Selector] Set Precommissioning Start',
  props<{ payload: { rangeType: string; usePreCommissionStart: boolean } }>()
);
export const setWeekDay = createAction(
  '[Range-Selector] Set Week/Day',
  props<{ payload: { rangeType: string; year: number; month: number; day: number } }>()
);
export const setYear = createAction(
  '[Range-Selector] Set Year',
  props<{ payload: { rangeType: string; year: number } }>()
);
export const setMonth = createAction(
  '[Range-Selector] Set Month',
  props<{ payload: { rangeType: string; year: number; month: number } }>()
);
export const setInterval = createAction('[Range-Selector] Set Interval', props<{ payload: { rangeType: string } }>());

export const changeInterval = createAction(
  '[Range-Selector] Change Interval',
  props<{ payload: { rangeType: string; direction: string } }>()
);

export type RangeSelectorActionsUnion = ReturnType<
  | typeof initRangeSelectorReport
  | typeof initRangeSelectorGlobal
  | typeof setRangeSelector
  | typeof setRange
  | typeof setScope
  | typeof changeType
  | typeof setIntervalSuccess
  | typeof setTitleSuccess
  | typeof setPrecommissionStart
  | typeof setWeekDay
  | typeof setMonth
  | typeof changeInterval
  | typeof setInterval
  | typeof setYear
>;
