import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-role-description',
  templateUrl: './role-description.component.html',
  styleUrls: ['./role-description.component.scss'],
})
export class RoleDescriptionComponent {
  @Input() isPartner: boolean;
}
