import { ChangeDetectionStrategy, Component, OnInit, ViewChild, Inject } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { take } from 'rxjs/operators';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import * as moment from 'moment';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';

import { NotesService } from 'src/app/_shared/services/notes.service';
import { DisplayImageComponent } from 'src/app/_shared/components/display-image/display-image.component';
import * as fromUser from 'src/app/_store/_reducers';
import { NotificationsService } from 'src/app/_shared/modules/notifications/shared/notifications.service';
import { MAT_DATE_FORMATS } from '@angular/material/core';

const DATE_FORMAT = {
  parse: {
    dateInput: ['L', 'LL'],
  },
  display: {
    dateInput: 'L',
    monthYearLabel: 'MM yyyy',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-new-note',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './new-note.component.html',
  styleUrls: ['./new-note.component.scss'],
  providers: [{ provide: MAT_DATE_FORMATS, useValue: DATE_FORMAT }],
})
export class NewNoteComponent implements OnInit {
  public note: {
    summary: string;
    date: moment.Moment;
    time: string;
    details: string;
  } = {
    summary: null,
    date: null,
    time: null,
    details: null,
  };

  noteForm: FormGroup;
  fileReferences: string[] = [];
  files: File[] = [];
  editSummary = true;
  editTime = true;
  editDate = true;
  mpTimezone: string;
  measurementPointId: number;
  accountName: string;
  mpCity: string;
  mpState: string;
  mpName: string;
  tlaTimezone: string;
  maxDate = new Date();

  @ViewChild('fileInput', { static: false }) fileInput;

  get editMode(): boolean {
    return this.data.existingNote;
  }

  get allowAttachments(): boolean {
    return !this.data.existingNote;
  }

  get date(): string {
    return moment(this.data.note.startDateTime).tz(this.mpTimezone).format('LL');
  }

  get time(): string {
    return moment(this.data.note.startDateTime).tz(this.mpTimezone).format('LT');
  }

  private get noteId(): number {
    return this.data.note.id;
  }

  constructor(
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<NewNoteComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private noteService: NotesService,
    private notificationsService: NotificationsService,
    private translateService: TranslateService,
    private store: Store<fromUser.State>
  ) {
    this.store
      .pipe(select(fromUser.getMp))
      .pipe(take(1))
      .subscribe((mp) => {
        this.mpTimezone = mp.timezone;
        this.measurementPointId = mp.measurementPointId;
        this.accountName = mp.accountName;
        this.mpCity = mp.city;
        this.mpState = mp.state;
        this.mpName = mp.mpId;
        this.tlaTimezone = mp.tz;
      }),
      (this.editSummary = !this.data.existingNote);
    this.editTime = !this.data.existingNote;
    this.editDate = !this.data.existingNote;

    if (!this.data.existingNote) {
      this.note.time = moment.tz(this.mpTimezone).format('HH:mm');
      this.note.date = moment.tz(this.mpTimezone);

      this.noteForm = new FormGroup({
        summary: new FormControl('', [Validators.required]),
        date: new FormControl(moment.tz(this.mpTimezone), [Validators.required]),
        time: new FormControl(moment.tz(this.mpTimezone).format('HH:mm'), [Validators.required]),
        details: new FormControl(''),
      });
    } else {
      this.noteForm = new FormGroup({
        summary: new FormControl(this.data.note.summary, [Validators.required]),
        date: new FormControl(moment.tz(this.data.note.startDateTime, this.mpTimezone), [Validators.required]),
        time: new FormControl(moment.tz(this.data.note.startDateTime, this.mpTimezone).format('HH:mm'), [
          Validators.required,
        ]),
        details: new FormControl(this.data.note.details),
      });
    }
  }

  ngOnInit(): void {
    if (this.data.existingNote === true) {
      this.noteService
        .getNoteDocuments(this.noteId)
        .pipe(take(1))
        .subscribe((documents) => {
          this.fileReferences = documents;
        });
    }
  }

  private composeDateTime(date: moment.Moment, time: string): string {
    const compositeDateTime = moment.tz(`${date.format('YYYY-MM-DD')} ${time}`, this.mpTimezone);

    return compositeDateTime.toISOString();
  }

  openFileUpload(): void {
    this.fileInput.nativeElement.click();
  }

  cancelNote(saved = false): void {
    this.dialogRef.close(saved);
  }

  addFiles(): void {
    const newFiles = this.fileInput.nativeElement.files;
    for (const key in newFiles) {
      if (!isNaN(parseInt(key, 10))) {
        this.files.push(newFiles[key]);
      }
    }
  }

  onSubmit(formData): void {
    const details = formData.details ? formData.details : this.data.note ? this.data.note.details : null;
    const startTime = formData.date
      ? this.composeDateTime(formData.date.clone(), formData.time)
      : this.data.note.startTime;
    const endTime = formData.endDate ? formData.endTime : null;
    const summary = formData.summary ? formData.summary : this.data.note.summary;

    const finalFormData = new FormData();
    if (details) {
      finalFormData.append('details', details);
    }
    finalFormData.append('startTime', startTime);
    finalFormData.append('endTime', null);
    finalFormData.append('summary', formData.summary);

    if (this.files.length > 0) {
      this.files.forEach((file, index) => {
        finalFormData.append(`note${index}`, file, file.name);
      });
    }

    if (this.data.existingNote) {
      this.noteService.updateNote(finalFormData, this.data.note.id).subscribe(
        (response) => {
          const updatedNote = {
            date: finalFormData.get('startTime'),
            summary: finalFormData.get('summary'),
            details: finalFormData.get('details'),
          };
          this.dialogRef.close(updatedNote);
          this.notificationsService.notify(this.translateService.instant('notes.success.updated'));
        },
        (err) => {
          this.notificationsService.notify(this.translateService.instant('notes.fail.updated'));
        }
      );
    } else {
      this.noteService.submitNewNote(finalFormData, this.measurementPointId).subscribe(
        (response) => {
          this.dialogRef.close(true);
          this.notificationsService.notify(this.translateService.instant('notes.success.created'));
        },
        (err) => {
          this.notificationsService.notify(this.translateService.instant('notes.fail.created'));
        }
      );
    }
  }

  isFirstFocus(event): void {
    if (!this.noteForm.get(event.target.name).touched) {
      this.noteForm.get(event.target.name).setValue('');
    }
  }

  removeFileFromSelected(index: number): void {
    this.files = this.files.filter((file, i) => i !== index);
  }

  displayFile(url: string): void {
    this.dialog.open(DisplayImageComponent, {
      data: {
        url,
        autoFocus: false,
      },
    });
  }

  transformAndDisplayFile(file: File): void {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (): void => {
      const imgURL = reader.result as string;
      this.displayFile(imgURL);
    };
  }
}
