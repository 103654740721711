import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PopupEventService {
  private dataSource: Subject<any> = new Subject<any>();

  toggleInfoDrawer(data): void {
    this.dataSource.next(data);
  }

  hideInfoDrawer(): void {
    this.dataSource.next(null);
  }

  getPopupEvent(): Observable<any> {
    return this.dataSource.asObservable();
  }
}
