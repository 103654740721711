import { Store } from '@ngrx/store';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { forkJoin, Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

import * as fromStore from 'src/app/_store/_reducers';

@Injectable()
export class PermissionGuard {
  constructor(private store: Store<fromStore.State>, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    return forkJoin([
      this.store.select(fromStore.getMp).pipe(take(1)),
      this.store.select(fromStore.getIsPartner).pipe(take(1)),
      this.store.select(fromStore.getIsSysAdmin).pipe(take(1)),
      this.store.select(fromStore.getIsAdmin).pipe(take(1)),
    ]).pipe(
      map(([mp, isPartner, isSysAdmin, isAdmin]) => {
        for (const role in route.data.roles) {
          if (route.data.roles[role].sysAdmin && isSysAdmin) {
            return true;
          } else if (route.data.roles[role].admin && isAdmin) {
            return true;
          } else if (route.data.roles[role].mpType && route.data.roles[role].mpType === mp.mMeasurementPointTypeId) {
            if (route.data.roles[role].isPartner) {
              if (route.data.roles[role].isPartner === isPartner) {
                return true;
              }
            } else {
              return true;
            }
          }
        }
        this.router.navigate(['/', 'fleet']);
        return false;
      })
    );
  }
}
