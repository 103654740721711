import { Action } from '@ngrx/store';
import * as moment from 'moment';
import 'moment-timezone';

import { ModuleUtil } from 'src/app/_shared/utils/module.util';
import { RangeSelectorActions } from 'src/app/_store/_range-selector/actions';

export interface State {
  [key: string]: {
    type: string;
    startDatePeriod: any;
    endDatePeriod: any;
    startDateRange: any;
    endDateRange: any;
    scope: any;
    title: string;
    commissionedWhen: string;
    usePreCommissionStart: boolean;
    granularity?: string;
    interval?: number;
    refreshTrend?: boolean;
  };
}

const initialState: State = {
  report: {
    type: null,
    startDatePeriod: null,
    endDatePeriod: null,
    startDateRange: null,
    endDateRange: null,
    scope: null,
    title: null,
    commissionedWhen: null,
    usePreCommissionStart: null,
  },
  global: {
    type: null,
    startDatePeriod: null,
    endDatePeriod: null,
    startDateRange: null,
    endDateRange: null,
    scope: null,
    title: null,
    commissionedWhen: null,
    usePreCommissionStart: null,
    granularity: null,
    interval: null,
    refreshTrend: true,
  },
};

export function reducer(state: State = initialState, action: Action): State {
  const specificAction = action as RangeSelectorActions.RangeSelectorActionsUnion;
  switch (specificAction.type) {
    case RangeSelectorActions.initRangeSelectorReport.type:
      if (JSON.stringify(state['report']) === JSON.stringify(initialState['report'])) {
        //only initialize, not allowed to change range once set
        let startDate, endDate, title;

        startDate = moment.tz(specificAction.payload.timezone).subtract(3, 'month').startOf('days').format('L LT');
        endDate = moment().tz(specificAction.payload.timezone).endOf('days').format('L LT');
        title = startDate + ' > ' + endDate;
        return {
          ...state,
          report: {
            ...state.report,
            type: 'range',
            startDateRange: startDate,
            endDateRange: endDate,
            scope: 'days',
            title: title,
            commissionedWhen: specificAction.payload.commissionedWhen,
            usePreCommissionStart: specificAction.payload.usePreCommissionStart,
          },
        };
      } else {
        return state;
      }
    case RangeSelectorActions.initRangeSelectorGlobal.type:
      if (JSON.stringify(state['global']) === JSON.stringify(initialState['global'])) {
        //only initialize, not allowed to change range once set
        let startDate, endDate, title, startDateRange, endDateRange;

        startDate = moment().tz(specificAction.payload.timezone).startOf('days').format('L');
        endDate = moment().tz(specificAction.payload.timezone).endOf('days').format('L');
        title = moment().tz(specificAction.payload.timezone).format('dddd LL');
        startDateRange = moment.tz(specificAction.payload.timezone).subtract(3, 'month').startOf('days').format('L LT');
        endDateRange = moment().tz(specificAction.payload.timezone).endOf('days').format('L LT');

        return {
          ...state,
          global: {
            ...state.global,
            type: 'period',
            startDatePeriod: startDate,
            endDatePeriod: endDate,
            startDateRange: startDateRange,
            endDateRange: endDateRange,
            scope: 'days',
            title: title,
            commissionedWhen: specificAction.payload.commissionedWhen,
            usePreCommissionStart: specificAction.payload.usePreCommissionStart,
            granularity: 'minute',
            interval: 1,
          },
        };
      } else {
        return state;
      }

    case RangeSelectorActions.setScope.type:
      let newScopeState;
      if (specificAction.payload.rangeType === 'report') {
        newScopeState = {
          ...state,
          [specificAction.payload.rangeType]: {
            ...state[specificAction.payload.rangeType],
            scope: specificAction.payload.scope,
          },
        };
      } else if (specificAction.payload.rangeType === 'global') {
        let granularity: string;
        let newInterval: number;

        const newGranularity = ModuleUtil.calculateGranularityPeriod(specificAction.payload.scope);
        granularity = newGranularity.granularity;
        newInterval = newGranularity.interval;

        newScopeState = {
          ...state,
          [specificAction.payload.rangeType]: {
            ...state[specificAction.payload.rangeType],
            scope: specificAction.payload.scope,
            granularity: granularity,
            interval: newInterval,
          },
        };
      }
      return { ...newScopeState };

    case RangeSelectorActions.setRange.type:
      let newRangeState;
      if (specificAction.payload.rangeType === 'report') {
        newRangeState = {
          ...state,
          [specificAction.payload.rangeType]: {
            ...state[specificAction.payload.rangeType],
            startDateRange: specificAction.payload.startDate,
            endDateRange: specificAction.payload.endDate,
            type: specificAction.payload.type
              ? specificAction.payload.type
              : state[specificAction.payload.rangeType].type,
            title:
              specificAction.payload.startDate.format('L LT') + ' > ' + specificAction.payload.endDate.format('L LT'),
          },
        };
      } else if (specificAction.payload.rangeType === 'global') {
        let granularity: string;
        let newInterval: number;
        let dateDiff = moment(specificAction.payload.endDate, 'L LT').diff(
          moment(specificAction.payload.startDate, 'L LT'),
          'days'
        );

        const newGranularity = ModuleUtil.calculateGranularityRange(dateDiff);
        granularity = newGranularity.granularity;
        newInterval = newGranularity.interval;

        newRangeState = {
          ...state,
          [specificAction.payload.rangeType]: {
            ...state[specificAction.payload.rangeType],
            startDateRange: specificAction.payload.startDate,
            endDateRange: specificAction.payload.endDate,
            granularity: granularity,
            interval: newInterval,
            type: specificAction.payload.type
              ? specificAction.payload.type
              : state[specificAction.payload.rangeType].type,
            title:
              specificAction.payload.startDate.format('L LT') + ' > ' + specificAction.payload.endDate.format('L LT'),
            refreshTrend: true,
          },
        };
      }
      return { ...newRangeState };

    case RangeSelectorActions.setIntervalSuccess.type:
      return {
        ...state,
        [specificAction.payload.rangeType]: {
          ...state[specificAction.payload.rangeType],
          startDatePeriod: specificAction.payload.startDate,
          endDatePeriod: specificAction.payload.endDate,
        },
      };
    case RangeSelectorActions.setTitleSuccess.type:
      return {
        ...state,
        [specificAction.payload.rangeType]: {
          ...state[specificAction.payload.rangeType],
          title: specificAction.payload.title,
        },
      };
    case RangeSelectorActions.setPrecommissionStart.type:
      return {
        ...state,
        [specificAction.payload.rangeType]: {
          ...state[specificAction.payload.rangeType],
          usePreCommissionStart: specificAction.payload.usePreCommissionStart,
        },
      };
    case RangeSelectorActions.setRangeSelector.type:
      let setRangeState;
      if (specificAction.payload.rangeType === 'report') {
        setRangeState = {
          ...state,
          [specificAction.payload.rangeType]: {
            ...state[specificAction.payload.rangeType],
            type: specificAction.payload.type,
            startDatePeriod: specificAction.payload.startDatePeriod
              ? specificAction.payload.startDatePeriod
              : state[specificAction.payload.rangeType].startDatePeriod,
            endDatePeriod: specificAction.payload.endDatePeriod
              ? specificAction.payload.endDatePeriod
              : state[specificAction.payload.rangeType].endDatePeriod,
            startDateRange: specificAction.payload.startDateRange
              ? specificAction.payload.startDateRange
              : state[specificAction.payload.rangeType].startDateRange,
            endDateRange: specificAction.payload.endDateRange
              ? specificAction.payload.endDateRange
              : state[specificAction.payload.rangeType].endDateRange,
            title: specificAction.payload.title,
          },
        };
      } else if (specificAction.payload.rangeType === 'global') {
        let granularity: string;
        let newInterval: number;
        let refreshTrend: boolean;
        if (specificAction.payload.type === 'period') {
          refreshTrend = true;

          const newGranularity = ModuleUtil.calculateGranularityPeriod(state[specificAction.payload.rangeType].scope);
          granularity = newGranularity.granularity;
          newInterval = newGranularity.interval;
        } else if (specificAction.payload.type === 'range') {
          refreshTrend = false;
          let dateDiff = moment(state[specificAction.payload.rangeType].endDateRange, 'L LT').diff(
            moment(state[specificAction.payload.rangeType].startDateRange, 'L LT'),
            'days'
          );

          const newGranularity = ModuleUtil.calculateGranularityRange(dateDiff);
          granularity = newGranularity.granularity;
          newInterval = newGranularity.interval;
        }

        setRangeState = {
          ...state,
          [specificAction.payload.rangeType]: {
            ...state[specificAction.payload.rangeType],
            type: specificAction.payload.type,
            startDatePeriod: specificAction.payload.startDatePeriod
              ? specificAction.payload.startDatePeriod
              : state[specificAction.payload.rangeType].startDatePeriod,
            endDatePeriod: specificAction.payload.endDatePeriod
              ? specificAction.payload.endDatePeriod
              : state[specificAction.payload.rangeType].endDatePeriod,
            startDateRange: specificAction.payload.startDateRange
              ? specificAction.payload.startDateRange
              : state[specificAction.payload.rangeType].startDateRange,
            endDateRange: specificAction.payload.endDateRange
              ? specificAction.payload.endDateRange
              : state[specificAction.payload.rangeType].endDateRange,
            title: specificAction.payload.title,
            granularity: granularity ? granularity : null,
            interval: newInterval ? newInterval : null,
            refreshTrend: refreshTrend,
          },
        };
      }

      return {
        ...setRangeState,
      };

    default:
      return state;
  }
}

export const getRangeSelector = (state: State): State => state;
export const getRangeSelectorType = (state: State): State['type'] => state.type;
export const getRangeSelectorScope = (state: State): State['scope'] => state.scope;
export const getRangeSelectorReportStartDatePeriod = (state: State): State['report']['startDatePeriod'] =>
  state.report.startDatePeriod;
export const getRangeSelectorGlobalStartDatePeriod = (state: State): State['global']['startDatePeriod'] =>
  state.global.startDatePeriod;
export const getRangeSelectorReportEndDatePeriod = (state: State): State['report']['endDatePeriod'] =>
  state.report.endDatePeriod;
export const getRangeSelectorGlobalEndDatePeriod = (state: State): State['global']['endDatePeriod'] =>
  state.global.endDatePeriod;
export const getRangeSelectorStartDateRange = (state: State): State['startDateRange'] => state.startDateRange;
export const getRangeSelectorEndDateRange = (state: State): State['endDateRange'] => state.endDateRange;
export const getRangeSelectorCommissionedWhen = (state: State): State['commissionedWhen'] => state.commissionedWhen;
