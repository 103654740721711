import { Store } from '@ngrx/store';
import { Injectable } from '@angular/core';
import { of, Observable } from 'rxjs';
import { switchMap, map, take } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';

import { IUser, ILoginResponse } from 'src/app/_shared/classes/user';
import { DiligentApiService } from 'src/app/_shared/services/diligent-api.service';
import { TokenService } from 'src/app/_shared/services/token.service';
import { IJwtPayload } from 'src/app/_shared/classes/generic.interfaces';
import { IAccount } from 'src/app/_shared/classes/account';
import * as fromStore from 'src/app/_store/_reducers';
import { NotificationsService } from 'src/app/_shared/modules/notifications/shared/notifications.service';
import { UserPreferences } from 'src/app/_shared/interface/user-preferences.interface';
import { InitActions } from 'src/app/_store/_init/actions';
import { UserActions } from 'src/app/_store/_user/actions';
import { MatDialog } from '@angular/material/dialog';
import { NewsActions } from 'src/app/_store/_news/actions';

@Injectable()
export class AuthService {
  public hasPref = false;

  public get authed(): Observable<IJwtPayload> {
    return this.token.payload.pipe(
      // check if the token has expired
      switchMap(() => this.token.isExpired()),
      switchMap((expired) => {
        this.store
          .select(fromStore.getUser)
          .pipe(take(1))
          .subscribe((user) => {
            if (user && user.id !== null && user.id !== parseInt(this.token.metaData.id, 10)) {
              this.logout(this.token.logoutAction);
              if (
                this.token.logoutAction === 'differentUser' ||
                this.token.logoutAction === 'logout' ||
                this.token.logoutAction === 'sessionExpired'
              ) {
                this.notificationsService.alert(
                  this.translateService.instant(`logoutAction.${this.token.logoutAction}`),
                  this.translateService.instant('logoutAction.title')
                );
              }
            }
          });
        // only send out the payload if its not expired
        return expired ? of(null) : this.token.payload;
      })
    );
  }

  public get user(): Observable<IUser> {
    return this.authed.pipe(
      switchMap((payload) => {
        return payload ? this.diligent.getUserById(payload.id, payload.accountId) : of(null);
      })
    );
  }

  public getAccountById(accountId: number): Observable<any> {
    return this.diligent.getAccount(accountId);
  }

  constructor(
    private diligent: DiligentApiService,
    private token: TokenService,
    private store: Store<fromStore.State>,
    private notificationsService: NotificationsService,
    private translateService: TranslateService,
    private router: Router,
    private dialogRef: MatDialog
  ) {}

  public login(username: string, password: string, returnUrl: string, isQubescanLogin: boolean): Promise<any> {
    return this.diligent.login(username, password).then((user) => {
      if (user.retired === 1) {
        this.notificationsService.alert(
          this.translateService.instant('login.retired-user-error'),
          this.translateService.instant('login.login-failed')
        );
        this.store.dispatch(UserActions.toogleLoginLoading({ payload: { loginLoading: false } }));
        return;
      }
      // this only gets sent in the login call and is required to get the user data
      this.token.setMetaData('accountId', user.accountId.toString());
      this.token.setMetaData('guid', user.guid ? user.guid.toString() : '');
      this.token.setMetaData('id', user.id.toString());
      this.token.setMetaData('isQubescanLogin', isQubescanLogin.toString());
      if (this.token.logoutAction === 'login') {
        this.token.logoutAction = 'differentUser';
      } else {
        this.token.logoutAction = 'login';
      }

      // setting this emits all observers
      this.token.hash = user.token;
      this.store.dispatch(
        UserActions.setIsSysAdminSuccess({
          payload: { isSysAdmin: user.isSystemAdministrator === 1 },
        })
      );

      this.token.payload.pipe(take(1)).subscribe((payload) => {
        this.store.dispatch(NewsActions.initNews());
        this.store.dispatch(
          InitActions.init({
            payload: {
              id: payload.id,
              accountId: payload.accountId,
              returnUrl: returnUrl,
              isLogin: false,
            },
          })
        );
      });
    });
  }

  public activate(token: string, password: string): Observable<any> {
    this.token.hash = token;
    return this.diligent.activate(token, password).pipe(
      switchMap((activationResponse) => this.token.payload),
      switchMap((payload: { email: string }) => {
        return this.diligent.login(payload.email, password);
      }),
      map((user) => {
        // this only gets sent in the login call and is required to get the user data
        this.token.setMetaData('accountId', user.accountId.toString());
        this.token.setMetaData('guid', user.guid ? user.guid.toString() : '');
        this.token.setMetaData('id', user.id.toString());
        this.token.setMetaData('isQubescanLogin', 'true');
        return user;
      })
    );
  }

  public logout(logoutAction?: string): void {
    let loginUrl = '/drivescan';
    if (this.token.metaData.isQubescanLogin === 'true') {
      loginUrl = '/login';
    }
    this.hasPref = false;
    this.dialogRef.closeAll();
    if (!logoutAction) {
      this.token.expire();
      this.token.logoutAction = 'logout';
    }
    this.router.navigate([loginUrl]);
  }

  public renewToken(returnUrl?: string): Observable<boolean> {
    return this.diligent.renewToken(returnUrl);
  }

  public reload(): void {
    this.token.parse();
  }

  public resetPassword(currentPassword: string, newPassword: string): Observable<any> {
    return this.diligent.resetPassword(currentPassword, newPassword);
  }

  public getUserPreferences(): Observable<UserPreferences> {
    return this.diligent.getUserPreferences();
  }

  public getAccountStore(accountId: number): Observable<IAccount> {
    return this.diligent.getAccount(accountId);
  }

  public async savePreferences(prefs, update = false): Promise<any> {
    this.store.dispatch(
      UserActions.getUserPreferencesSuccess({
        payload: { userPreferences: prefs },
      })
    );
    const saved = await this.diligent.saveUserPreferences(prefs, update);
    return saved;
  }

  public requestPasswordReset(email: string): Promise<any> {
    return this.diligent.requestPasswordReset(email);
  }

  public resetPasswordWithToken(password: string, token: string): Promise<ILoginResponse> {
    return this.diligent.resetPasswordWithToken(password, token);
  }

  public registerUserAccount(userAccount): Observable<any> {
    return this.diligent.registerUserAccount(userAccount);
  }
}
