import { Action } from '@ngrx/store';

import { IUser } from 'src/app/_shared/classes/user';
import { UserPreferences } from 'src/app/_shared/interface/user-preferences.interface';
import { UserActions } from 'src/app/_store/_user/actions';
import { IAccount } from 'src/app/_shared/classes/account';
import { MeasurementPoint } from 'src/app/_shared/classes/MeasurementPoint';

export interface State {
  user: IUser;
  isPartner: number;
  isSysAdmin: boolean;
  userPreferences: UserPreferences;
  account: IAccount;
  count: number;
  mp: any;
  mpType: number;
  qubeScanType: string;
  qubeScanModel: string;
  firmwareVersion: string;
  alarmChannel: any;
  previousUrl: string;
  headerName: string;
  loginLoading: boolean;
  viewerMode: boolean;
  unseenReportResults: Array<number>;
}

const initialState: State = {
  user: {
    role: {
      name: null,
      id: null,
    },
    accountId: null,
    addressId: null,
    addressTypeId: null,
    isSystemAdministrator: null,
    retired: null,
    userRoleId: null,
    email: null,
    firstName: null,
    guid: null,
    homePhone: null,
    id: null,
    lastName: null,
    middleName: null,
    mobilePhone: null,
    preferredLocaleId: null,
    workPhone: null,
  },
  isPartner: null,
  isSysAdmin: null,
  userPreferences: null,
  account: null,
  count: null,
  mp: null,
  mpType: null,
  qubeScanType: null,
  qubeScanModel: null,
  firmwareVersion: null,
  alarmChannel: null,
  previousUrl: null,
  headerName: null,
  loginLoading: false,
  viewerMode: null,
  unseenReportResults: null,
};

export function reducer(state: State = initialState, action: Action): State {
  const specificAction = action as UserActions.UserActionsUnion;

  switch (specificAction.type) {
    case UserActions.getUser.type:
      return {
        ...state,
      };

    case UserActions.getUserSuccess.type:
      return {
        ...state,
        user: specificAction.user,
        isPartner: state.isPartner,
      };
    case UserActions.setUserIsPartnerSuccess.type:
      return {
        ...state,
        isPartner: specificAction.payload.isPartner,
      };
    case UserActions.setIsSysAdminSuccess.type:
      return {
        ...state,
        isSysAdmin: specificAction.payload.isSysAdmin,
      };
    case UserActions.getUserPreferences.type:
      return {
        ...state,
      };
    case UserActions.getUserPreferencesSuccess.type:
      return {
        ...state,
        userPreferences: specificAction.payload.userPreferences,
      };
    case UserActions.setUserPreferences.type:
      return {
        ...state,
        userPreferences: specificAction.payload.userPreferences,
      };
    case UserActions.getAccount.type:
      return {
        ...state,
      };
    case UserActions.getAccountSuccess.type:
      return {
        ...state,
        account: specificAction.payload.account,
      };
    case UserActions.getCountByAccount.type:
      return {
        ...state,
      };
    case UserActions.getCountByAccountSuccess.type:
      return {
        ...state,
        count: specificAction.payload.count,
      };
    case UserActions.getMp.type:
      return {
        ...state,
      };
    case UserActions.getMpSuccess.type:
      return {
        ...state,
        mp: specificAction.payload.mp,
        mpType: specificAction.payload.mp.measurementPointTypeId,
      };
    case UserActions.setHeaderNameSuccess.type:
      return {
        ...state,
        headerName: specificAction.payload.headerName,
      };
    case UserActions.setQubescanTypeModel.type:
      return {
        ...state,
        qubeScanType: specificAction.payload.type,
        qubeScanModel: specificAction.payload.model,
      };
    case UserActions.setFirmwareVersionSuccess.type:
      return {
        ...state,
        firmwareVersion: specificAction.payload.firmwareVersion,
      };
    case UserActions.setAlarmChannelSuccess.type:
      return {
        ...state,
        alarmChannel: specificAction.payload.channel,
      };
    case UserActions.setPreviousUrl.type:
      return {
        ...state,
        previousUrl: specificAction.payload.url,
      };
    case UserActions.toogleLoginLoading.type:
      return {
        ...state,
        loginLoading: specificAction.payload.loginLoading,
      };
    case UserActions.setViewerMode.type:
      return {
        ...state,
        viewerMode: specificAction.payload.viewer,
      };
    case UserActions.removeMp.type:
      return {
        ...state,
        mp: null,
        qubeScanType: null,
        firmwareVersion: null,
        headerName: null,
        alarmChannel: null,
        userPreferences: {
          ...state.userPreferences,
          mpId: null,
          account: null,
        },
      };
    case UserActions.setMpStatusSuccess.type:
      return {
        ...state,
        mp: new MeasurementPoint(specificAction.payload.mp),
      };
    case UserActions.setUnseenReports.type:
      return {
        ...state,
        unseenReportResults: specificAction.payload.reportResultIds,
      };
    case UserActions.addUnseenReport.type:
      let unseenReports = state.unseenReportResults;
      if (unseenReports) {
        unseenReports = [...unseenReports, specificAction.payload.reportResultId];
      } else {
        unseenReports = [specificAction.payload.reportResultId];
      }
      return {
        ...state,
        unseenReportResults: unseenReports,
      };
    default:
      return state;
  }
}

export const getUser = (state: State): State['user'] => state.user;
export const getUserRole = (state: State): string => state.user.role.name;
export const getIsPartner = (state: State): State['isPartner'] => state.isPartner;
export const getIsSysAdmin = (state: State): State['isSysAdmin'] => state.isSysAdmin;
export const getIsAdmin = (state: State): boolean => state.user.role.name === 'Administrator';
export const getUserPreferences = (state: State): State['userPreferences'] => state.userPreferences;
export const getWelcomeMessage = (state: State): State['userPreferences']['welcomeMessage'] =>
  state.userPreferences.welcomeMessage;
export const getHint = (state: State): State['userPreferences']['hint'] => state.userPreferences.hint;
export const getAccount = (state: State): State['account'] => state.account;
export const getUserSubType = (state: State): string => state.account.subscriptionType;
export const getCount = (state: State): State['count'] => state.count;
export const getMp = (state: State): State['mp'] => state.mp;
export const getHeaderName = (state: State): State['headerName'] => state.headerName;
export const getQubescanType = (state: State): State['qubeScanType'] => state.qubeScanType;
export const getFirmwareVersion = (state: State): State['firmwareVersion'] => state.firmwareVersion;
export const getAlarmChannel = (state: State): State['alarmChannel'] => state.alarmChannel;
export const getSerialNumber = (state: State): State['mp'] => state.mp.serialNumber;
export const getLastComm = (state: State): State['mp'] => state.mp.lastCommunicationTime;
export const getTimezone = (state: State): State['mp'] => state.mp.timezone;
export const getTz = (state: State): State['mp'] => state.mp.tz;
export const getPreviousUrl = (state: State): State['previousUrl'] => state.previousUrl;
export const getAccountName = (state: State): State['mp'] => state.mp.accountName;
export const getUserId = (state: State): number => state.user.id;
