import { cloneDeep } from 'lodash';
import { Component, HostListener, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';

import * as fromStore from 'src/app/_store/_reducers';
import { OnDestroyMixin, untilComponentDestroyed } from 'src/app/_shared/classes/component-destroy.class';
import { MeasurementPointsService } from 'src/app/_shared/services/measurement-points.service';
import { ChartsActions } from 'src/app/_store/_charts/actions';
import { UserActions } from 'src/app/_store/_user/actions';

@Component({
  selector: 'app-app-layout',
  templateUrl: './app-layout.component.html',
  styleUrls: ['./app-layout.component.scss'],
})
export class AppLayoutComponent extends OnDestroyMixin implements OnInit {
  mobileMenuOpen = false;
  headerHidden = false;
  currentPosition = window.pageYOffset;
  smallScreen = window.innerWidth < 992;
  loading = false;
  dynamicMargin = { sectionTop: this.smallScreen ? '89px' : '67px', navigationTop: this.smallScreen ? '89px' : '67px' };
  private mp;
  private userPrefs;

  @HostListener('window:resize', ['$event'])
  onResize(): void {
    this.smallScreen = window.innerWidth < 992;
    if (this.smallScreen) {
      this.dynamicMargin.navigationTop = '87px';
    }
  }

  @HostListener('window:scroll', ['$event'])
  onScroll(): void {
    if (this.smallScreen) {
      this.headerHidden = window.pageYOffset > this.currentPosition && window.pageYOffset > 80;
      this.currentPosition = window.pageYOffset;
    }
  }

  constructor(private store: Store<fromStore.State>, private mpService: MeasurementPointsService) {
    super();
  }

  ngOnInit(): void {
    this.onResize();
    this.store
      .pipe(select(fromStore.getMp))
      .pipe(untilComponentDestroyed(this))
      .subscribe((mp) => {
        this.mp = mp;
      });

    this.store
      .pipe(select(fromStore.getUserPreferences))
      .pipe(untilComponentDestroyed(this))
      .subscribe((userPreferences) => {
        this.userPrefs = cloneDeep(userPreferences);
        if (this.userPrefs.onBoarding?.isOnBoarding) {
          this.loading = true;
          this.mpService
            .channelDefAvailableMqtt(this, this.userPrefs.mpId)
            .pipe(untilComponentDestroyed(this))
            .subscribe((isAvailable) => {
              if (isAvailable) {
                const mpId = this.mp.roomId ? this.mp.roomId : this.mp.measurementPointId;
                this.mpService
                  .getMeasurementPoint(mpId)
                  .pipe(take(1))
                  .subscribe((mp) => {
                    mp.measurementPointId = mp.roomId;
                    this.mpService.changeSelection(mp, '/dashboard').subscribe(() => {
                      this.loading = false;
                      this.store.dispatch(
                        ChartsActions.initCharts({
                          payload: {
                            startDate: null,
                            scope: 'month',
                            timezone: this.mp.timezone,
                            granularity: 'minute',
                            interval: 10,
                            commissionedWhen: this.mp.commissionedWhen,
                            usePreCommissionStart: this.userPrefs.usePreCommissionStart
                              ? this.userPrefs.usePreCommissionStart
                              : false,
                          },
                        })
                      );
                    });
                  });
              }
            });
        } else {
          this.loading = false;
        }
      });
    // Monitor height of HeadBanner component for layout adjustment
    this.store
      .select(fromStore.getLayoutHeadBannerHeight)
      .pipe(untilComponentDestroyed(this))
      .subscribe((height) => {
        this.setDynamicMargin(height);
      });
  }

  removeOnBoardingLoad(): void {
    if (this.userPrefs.onBoarding?.isOnBoarding) {
      this.store.dispatch(
        UserActions.updateUserPreferences({
          payload: {
            property: {
              onBoarding: {
                isOnBoarding: false,
                timestamp: null,
              },
            },
          },
        })
      );
    }
  }

  private setDynamicMargin(headBannerHeight: number): void {
    this.dynamicMargin = {
      navigationTop: this.smallScreen ? 87 + headBannerHeight + 'px' : 67 + headBannerHeight + 'px',
      sectionTop: this.smallScreen ? 87 + headBannerHeight + 'px' : 67 + headBannerHeight + 'px',
    };
  }
}
