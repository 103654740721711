import { Directive, HostListener, Input } from '@angular/core';

import { DocumentService } from 'src/app/_shared/modules/document/document.service';

@Directive({
  selector: '[appDocument]',
})
export class DocumentDownloadDirective {
  @Input('appDocument') documentId: number;

  @HostListener('click') onClick(): void {
    this.documentService.downloadDocument(this.documentId);
  }

  constructor(private documentService: DocumentService) {}
}
