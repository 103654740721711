import { Observable } from 'rxjs';
import { Component, HostListener, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';

import * as fromStore from 'src/app/_store/_reducers';
import { EventNature } from 'src/app/_shared/interface/events/event-nature.interface';

@Component({
  selector: 'app-event-legend',
  templateUrl: './event-legend.component.html',
  styleUrls: ['./event-legend.component.scss'],
})
export class EventLegendComponent implements OnInit {
  @Input() from: string;
  mpType$: Observable<number>;
  nature$: Observable<EventNature>;
  smallScreen = false;

  @HostListener('window:resize', ['$event'])
  onResize(): void {
    this.smallScreen = window.innerWidth < 992;
  }

  constructor(private store: Store<fromStore.State>) {}

  ngOnInit(): void {
    this.mpType$ = this.store.select((state) => state.user.mpType);
    this.nature$ = this.store.select((state) => state.charts.nature);
  }
}
