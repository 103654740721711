import { createAction, props } from '@ngrx/store';

import { RecommendationCount, IRecommendation } from 'src/app/_shared/interface/recommendation.interface';

export const getRecommendationCount = createAction(
  '[Recommendation] get recommendation count',
  props<{
    payload: {
      mpId: number;
      year?: number;
    };
  }>()
);

export const getRecommendationCountSuccess = createAction(
  '[Recommendation] get recommendation count success',
  props<{
    payload: { count: RecommendationCount; year?: number };
  }>()
);

export const getRecommendation = createAction(
  '[Recommendation] get recommendations',
  props<{
    payload: { mpId: number };
  }>()
);

export const getRecommendationSuccess = createAction(
  '[Recommendation] get recommendations success',
  props<{
    payload: { recommendations: IRecommendation[] };
  }>()
);

export const updateRecommendation = createAction(
  '[Recommendation] update recomendation',
  props<{ payload: { recommendation: IRecommendation } }>()
);

export const createRecommendation = createAction(
  '[Recommendation] create recommendation',
  props<{ payload: { recommendation: IRecommendation } }>()
);

export type RecommendationActionsUnion = ReturnType<
  | typeof getRecommendation
  | typeof getRecommendationSuccess
  | typeof getRecommendationCount
  | typeof getRecommendationCountSuccess
  | typeof createRecommendation
  | typeof updateRecommendation
>;
