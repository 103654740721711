import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { switchMap, map, take } from 'rxjs/operators';
import { Store } from '@ngrx/store';

import * as fromStore from 'src/app/_store/_reducers';
import { MaintenanceActions } from 'src/app/_store/_maintenance/actions';
import { MaintenanceRequestState } from 'src/app/_shared/interface/maintenance';
import { MaintenanceService } from 'src/app/_shared/services';

@Injectable()
export class MaintenanceEffects {
  getMaintenanceCommand$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MaintenanceActions.getMaintenanceCommand.type),
      switchMap(() => this.maintenanceService.getMaintenanceCommands()),
      map((commands) => MaintenanceActions.getMaintenanceCommandSuccess({ payload: { commands } }))
    )
  );

  getMaintenanceRequests$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MaintenanceActions.getMaintenanceRequests.type),
      switchMap((action) =>
        this.maintenanceService
          .getMaintenanceRequests(action.payload.mpId)
          .pipe(map((requests) => [requests, action.payload.mpId]))
      ),
      map(([requests, mpId]: [MaintenanceRequestState[], number]) =>
        MaintenanceActions.getMaintenanceRequestsSuccess({ payload: { requests, mpId } })
      )
    )
  );

  updateRequestState$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MaintenanceActions.updateRequestsState),
      switchMap((state) =>
        this.store.select(fromStore.getMaintenanceRequests).pipe(
          take(1),
          map((requests) => [requests, state.payload])
        )
      ),
      map(([requests, specificAction]: [MaintenanceRequestState[], any]) => {
        const indexItem = requests.findIndex(
          (item) => item.id === specificAction.request.id || item.id === specificAction.request.requestId
        );
        const newItem = {
          ...requests[indexItem],
          status: specificAction.request.status,
          statusId: specificAction.request.statusId,
          response: specificAction.request.response,
          statusChangedAt: new Date(),
        };
        return MaintenanceActions.updateRequestsStateSuccess({ payload: { request: newItem } });
      })
    )
  );

  constructor(
    private store: Store,
    private actions$: Actions<MaintenanceActions.MaintenanceActionsUnion>,
    private maintenanceService: MaintenanceService
  ) {}
}
