import { Moment } from 'moment';
import { createAction, props } from '@ngrx/store';

export const init = createAction(
  '[App] Init',
  props<{
    payload: {
      id: number;
      accountId: number;
      returnUrl: string;
      isLogin: boolean;
      urlParams?: { mpId: number; account: number };
    };
  }>()
);
export const login = createAction('[App] Login', props<{ payload: { returnUrl: string } }>());
export const loginSuccess = createAction('[App] Login Success');

export const changeSelection = createAction(
  '[App] Change Selection',
  props<{
    payload: {
      point: any;
      redirect: string;
      onBoarding: { isOnBoarding: boolean; timestamp: Moment };
      customDate?: string;
      forceChartUpdate?: boolean;
    };
  }>()
);

export type InitActionsUnion = ReturnType<typeof init | typeof login | typeof loginSuccess | typeof changeSelection>;
