@if (type === 'period') {
<div>
  <h4 class="period-title">{{ title }}</h4>
  <app-period-navigator
    [absoluteStartDate]="absoluteStartDate"
    [type]="'basic'"
    [haveFutur]="haveFutur"
    [havePast]="havePast"
    [date]="date"
    [rangeType]="rangeType"
  ></app-period-navigator>
</div>
} @else {
<span class="range-title" data-cy="range-selection-title">{{ title }}</span>
} @if (hintIsOpened && showHint) {
<app-hint-box
  [hint]="'rangeSelection'"
  [overlayOrigin]="overlayOrigin"
  [isOpenedInput]="hintIsOpened"
  (closeHint)="hintIsOpened = false"
  (openHint)="hintIsOpened = true"
  >{{ 'hint.rangeSelection' | translate }}
</app-hint-box>
}
<button
  class="datepicker-button"
  data-cy="date-picker"
  [ngStyle]="{ 'margin-top': type === 'range' ? 'unset' : '20px' }"
  mat-icon-button
  [matMenuTriggerFor]="rangeMenu"
  #menuTrigger="matMenuTrigger"
  (menuOpened)="openSelector($event)"
  #overlayOrigin="cdkOverlayOrigin"
  cdkOverlayOrigin
>
  <mat-icon svgIcon="calendar"></mat-icon>
</button>
<mat-menu #rangeMenu="matMenu" xPosition="before">
  <mat-tab-group
    (click)="$event.stopPropagation()"
    [selectedIndex]="selectedIndex"
    (selectedTabChange)="changeTab($event)"
    animationDuration="0ms"
    #tabGroup
  >
    <mat-tab label="period">
      <div>
        <app-period-navigator
          class="period-navigator-menu"
          [absoluteStartDate]="absoluteStartDate"
          [type]="'menu'"
          [haveFutur]="haveFutur"
          [havePast]="havePast"
          [date]="date"
          [rangeType]="rangeType"
        ></app-period-navigator>
        <mat-calendar
          #picker
          class="calendar-picker"
          [selected]="selected"
          [minDate]="absoluteStartDate"
          [maxDate]="maxDate"
          (selectedChange)="updateDate($event)"
          (yearSelected)="chosenYearHandler($event)"
          (monthSelected)="chosenMonthHandler($event)"
        ></mat-calendar>
      </div>
    </mat-tab>
    <mat-tab label="From to">
      <app-range-picker
        [selectedDateRange]="selectedDateRange"
        [error]="error"
        [rangeType]="rangeType"
        [timezone]="timezone"
        (applyDateRange)="applyDateRange($event)"
        (emitCancel)="menuTrigger.closeMenu()"
      ></app-range-picker>
    </mat-tab>
  </mat-tab-group>
</mat-menu>
