@if (bannersList.length > 0) {
  <div class="head-banner-container" #bannerContainer>
  @for (item of bannersList; track item.id) {
    <div class="custom-banner" data-cy="custom-banner" [class.hidden]="item.hidden">
      <mat-icon svgIcon="decagram"></mat-icon>
      <div>
        <span [innerHTML]="item.text | translate : { primaryAccExpiry: (primaryAccExpiry$ | async)?.split('T')?.[0] }"></span>
        <a *ngIf="item?.linkToActionEnabled" (click)="onClickAction(item.id)">{{ item?.linkToActionText | translate }}</a>
        <span *ngIf="item?.text2">{{ item?.text2 | translate }}</span>
      </div>
      <button mat-icon-button type="button" (click)="closeBanner(item.id)">
        <mat-icon class="close-button" [svgIcon]="'close'"></mat-icon>
      </button>
    </div>
  }
  </div>
}