import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'kToM',
})
export class KToMPipe implements PipeTransform {
  transform(value: number, ...args: any[]): string | number {
    if (!value || args.indexOf(true) !== -1) {
      return 'N/A';
    } else {
      if (Math.abs(value) / 1000 < 1) {
        return value + ' ' + args[0];
      } else {
        return (value / 1000).toFixed(3) + ' M' + args[0].substring(1);
      }
    }
  }
}
