import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { MaintenanceCommand, MaintenanceRequest, MaintenanceRequestState } from 'src/app/_shared/interface/maintenance';
import { DiligentApiService } from 'src/app/_shared/services/diligent-api.service';
import {
  ComponentWithOnDestroyObservable,
  untilComponentDestroyed,
} from 'src/app/_shared/classes/component-destroy.class';
import { SocketService } from 'src/app/_shared/services/socket.service';

@Injectable({
  providedIn: 'root',
})
export class MaintenanceService {
  constructor(private diligentService: DiligentApiService, private socketService: SocketService) {}

  getMaintenanceCommands(): Observable<MaintenanceCommand[]> {
    return this.diligentService.getMaintenanceCommands();
  }

  sendMaintenanceCommands(mpId: number, commandParam: FormData): Observable<any> {
    return this.diligentService.sendMaintenanceCommands(mpId, commandParam);
  }

  getMaintenanceRequests(mpId: number): Observable<MaintenanceRequestState[]> {
    return this.diligentService.getMaintenanceRequests(mpId);
  }

  createMaintenanceRequest(
    mpId: number,
    request: MaintenanceRequest,
    file?: File
  ): Observable<MaintenanceRequestState> {
    return this.diligentService.createMaintenanceRequest(mpId, request, file);
  }

  getSetupINI(mpId: number): Promise<any> {
    return this.diligentService.getSetupINI(mpId);
  }

  setupMqtt(component: ComponentWithOnDestroyObservable, mpId: number, requestId: number): Observable<any> {
    return this.socketService.getClient<MaintenanceRequestState>().pipe(
      switchMap((client) => {
        client.addTopic(`cloud/${mpId}/maintenance/status/${requestId}`);
        return client.observable;
      }),
      untilComponentDestroyed(component)
    );
  }

  isAvailableMqtt(component: ComponentWithOnDestroyObservable, mpId: number): Observable<any> {
    return this.socketService.getClient<MaintenanceRequestState>().pipe(
      switchMap((client) => {
        client.addTopic(`cloud/${mpId}/availability/status`);
        return client.observable;
      }),
      untilComponentDestroyed(component)
    );
  }

  setupIniAvailableMqtt(component: ComponentWithOnDestroyObservable, mpId: number): Observable<any> {
    return this.socketService.getClient<any>().pipe(
      switchMap((client) => {
        client.addTopic(`cloud/${mpId}/setup/new`);
        return client.observable;
      }),
      untilComponentDestroyed(component)
    );
  }
}
