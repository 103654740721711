import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { DiligentApiService } from 'src/app/_shared/services/diligent-api.service';

@Injectable({
  providedIn: 'root',
})
export class GeocoderService {
  constructor(private diligent: DiligentApiService) {}

  public getPlaces(sessionToken: string, languageCode: string, regionCode: string, input: string): Observable<any[]> {
    return this.diligent.getPlaces(sessionToken, languageCode, regionCode, input);
  }

  public getPlaceDetails(
    sessionToken: string,
    languageCode: string,
    regionCode: string,
    placeId: string,
    fields: string
  ): Observable<any> {
    return this.diligent.getPlaceDetails(sessionToken, languageCode, regionCode, placeId, fields);
  }
}
