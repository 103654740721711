import { switchMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { RolesActions } from 'src/app/_store/_roles/actions';
import { EnumationsService } from 'src/app/_shared/services/enumerations.service';

@Injectable()
export class RolesEffects {
  getRoles$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RolesActions.getRoles),
      switchMap(() => {
        return this.enumerationsService.getEnumerations(['userRoles']).pipe(
          switchMap((roles) => {
            return [RolesActions.getRolesSuccess({ payload: { userRoles: roles.userRoleByName } })];
          })
        );
      })
    )
  );

  constructor(
    private actions$: Actions<RolesActions.RolesActionsUnion>,
    private enumerationsService: EnumationsService
  ) {}
}
