import { createAction, props } from '@ngrx/store';

import { MeasurementPoint } from 'src/app/_shared/classes/MeasurementPoint';
import { ChannelDefinition } from 'src/app/_shared/interface/channel/channel-definition.interface';

export const setAllChannels = createAction(
  '[Charts] Set All Channels',
  props<{ payload: { mpId: string; mpTypeId: number } }>()
);

export const setMeterChannels = createAction(
  '[Charts] Set Meter Channels',
  props<{ payload: { mpId: string; mpTypeId: number } }>()
);
export const setTrendsChannels = createAction(
  '[Charts] Set Trends Channels',
  props<{ payload: { mpId: string; mpTypeId: number } }>()
);
export const setMeterChannelsSuccess = createAction(
  '[Charts] Set Meter Channels Success',
  props<{ payload: { meterChannels: ChannelDefinition } }>()
);
export const setTrendsChannelsSuccess = createAction(
  '[Charts] Set Trends Channels Success',
  props<{
    payload: {
      trendsChannels: ChannelDefinition;
      mp?: MeasurementPoint;
      returnUrl?: string;
    };
  }>()
);

export const initTrendsChannelsSuccess = createAction('[Chart] Init Trends Channels Success');

export const setChannelsFail = createAction('[Charts] Set Channels Fail', props<{ error: any }>());

export type ChannelsActionsUnion = ReturnType<
  | typeof setAllChannels
  | typeof setMeterChannels
  | typeof setTrendsChannels
  | typeof setTrendsChannelsSuccess
  | typeof setMeterChannelsSuccess
  | typeof setChannelsFail
  | typeof initTrendsChannelsSuccess
>;
