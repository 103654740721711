import { AbstractControl, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
export class CustomFormValidators {
  static regExpValidator(regexp: RegExp): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!control.value) return null;
      const isInvalidFormat = regexp.test(control.value);
      return isInvalidFormat ? { pattern: { message: 'This entry is invalid. Try something else.' } } : null;
    };
  }

  /**
   * @description
   * Validator that requires the control to forbid a specific pattern.
   *
   * @usageNotes
   *
   * ### Validate that the field does not contain a pattern that matches /^[p]\d{7}$/i
   *
   * ```typescript
   * const control = new FormControl('', CustomFormValidators.measurementPointNamePattern);
   *
   * console.log(control.errors); // {pattern: true}
   * ```
   *
   * @returns An error map with the `pattern` property
   * if the validation check fails, otherwise `null`.
   *
   * @see `updateValueAndValidity()`
   *
   */
  static measurementPointNamePattern(control: AbstractControl): ValidationErrors | null {
    const pattern = /^[p]\d{7}$/i;
    return CustomFormValidators.regExpValidator(pattern)(control);
  }

  static reportWriterParametersValidator(index: number, min?: number, max?: number): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value;
      if (!value) return null;

      const parentControlValue = control.parent?.controls?.[Object.keys(control.parent?.controls)[0]]?.value;

      if (parentControlValue === index) {
        let isValid = [];
        if (!isNaN(min)) {
          isValid.push(Validators.min(min));
        }

        if (!isNaN(max)) {
          isValid.push(Validators.max(max));
        }

        if (isValid.length > 0) {
          return Validators.compose(isValid)(control);
        }
      }

      return null;
    };
  }
}
