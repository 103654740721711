import { AfterViewInit, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { take, switchMap, catchError } from 'rxjs/operators';
import { of, Observable } from 'rxjs';

import { Store } from '@ngrx/store';
import { EventParameters } from 'src/app/_shared/interface/event-parameters.interface';
import { UserActions } from 'src/app/_store/_user/actions';
import * as fromStore from 'src/app/_store/_reducers';
import { SubscriptionService } from 'src/app/_shared/services/subscription.service';

@Component({
  selector: 'app-notification-modal-dialog',
  templateUrl: './notification-modal.component.html',
  styleUrls: ['./notification-modal.component.scss'],
})
export class NotificationModalComponent implements OnInit, AfterViewInit {
  settings = {
    title: 'Heads up!',
    message: '',
    material_ligature: 'info',
    buttons: [],
    createEvents: undefined,
    closePermanentlyCheckbox: false,
    dialogId: null,
    showRequestUpgrade: false,
    accountId: null,
  };

  isAdmin$: Observable<boolean>;
  requestToUpgradeSuccess: boolean = null;

  constructor(
    public dialogRef: MatDialogRef<NotificationModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private sanitizer: DomSanitizer,
    private store: Store<fromStore.State>,
    private subscriptionService: SubscriptionService
  ) {
    // now that we have a dialogRef, setup the default Ok button with a call back to close
    this.settings.buttons.push({
      caption: 'Ok',
      callback: () => {
        this.dialogRef.close(true);
      },
      material_ligature: '',
    });

    if (data.message) {
      data.message = this.sanitizer.bypassSecurityTrustHtml(data.message);
    }

    // overwrite defaults with data passed into the constructor
    this.settings = { ...this.settings, ...data };
  }

  ngAfterViewInit(): void {
    if (
      this.settings.createEvents &&
      this.settings.createEvents instanceof Array &&
      this.settings.createEvents.length > 0
    ) {
      this.settings.createEvents.forEach((event: EventParameters) => {
        const element = document.getElementById(event.elementId);
        element.addEventListener(event.eventType, event.event);
      });
    }
  }

  ngOnInit(): void {
    this.isAdmin$ = this.store.select(fromStore.getIsAdmin).pipe(take(1));
    setTimeout(() => {
      const elem = document.getElementsByClassName('notifications-button');
      (elem.item(elem.length - 1) as any).focus();
    }, 500);
  }

  onCheckboxChange(event: any): void {
    if (this.settings.dialogId) {
      this.store.dispatch(
        UserActions.updateUserPreferences({
          payload: { property: { dialogs: { [this.settings.dialogId]: event.checked } } },
        })
      );
    }
  }

  public requestUpgrade(): void {
    this.subscriptionService
      .requestToUpgradeSubscription(this.settings.accountId)
      .pipe(take(1))
      .subscribe((result) => {
        if (result.message) {
          this.requestToUpgradeSuccess = true;
        } else {
          this.requestToUpgradeSuccess = false;
        }
      });
  }
}
