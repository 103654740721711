<h1 mat-dialog-title>
  {{ 'login.reset-password.title' | translate }}
</h1>
<form (submit)="submitForm($event)" [formGroup]="resetForm" class="reset-form">
  <div mat-dialog-content>
    @if (from === 'profile') {
    <mat-form-field appearance="fill">
      <mat-label>{{ 'profile.current-password' | translate }}</mat-label>
      <input
        matInput
        [type]="maskCurrent ? 'password' : 'text'"
        formControlName="currentPassword"
        data-cy="currentPassword"
      />
      <mat-icon
        class="mask-toggle"
        matSuffix
        color="accent"
        (click)="maskCurrent = !maskCurrent"
        [svgIcon]="maskCurrent ? 'eye' : 'eye-off'"
      ></mat-icon>
      @if (newPassword.hasError('required')) {
      <mat-error>{{ 'global.errors.fieldRequired' | translate }}</mat-error>
      }
    </mat-form-field>
    }
    <mat-form-field appearance="fill">
      <mat-label>{{ 'profile.new-password' | translate }}</mat-label>
      <input matInput [type]="maskPassword ? 'password' : 'text'" formControlName="newPassword" data-cy="newPassword" />
      <mat-icon
        class="mask-toggle"
        matSuffix
        color="accent"
        (click)="maskPassword = !maskPassword"
        [svgIcon]="maskPassword ? 'eye' : 'eye-off'"
      ></mat-icon>
      @if (newPassword.hasError('pattern')) {
      <mat-error>{{ 'profile.password-rules' | translate }}</mat-error>
      } @if (newPassword.hasError('required')) {
      <mat-error>{{ 'global.errors.fieldRequired' | translate }}</mat-error>
      }
    </mat-form-field>
    <mat-form-field appearance="fill">
      <mat-label>{{ 'profile.confirm-password' | translate }}</mat-label>
      <input
        matInput
        [type]="maskConfirm ? 'password' : 'text'"
        formControlName="confirmPassword"
        data-cy="confirmPassword"
      />
      <mat-icon
        class="mask-toggle"
        matSuffix
        color="accent"
        (click)="maskConfirm = !maskConfirm"
        [svgIcon]="maskConfirm ? 'eye' : 'eye-off'"
      ></mat-icon>
      @if (confirmPassword.hasError('passwordMismatch')) {
      <mat-error>{{ 'profile.password-mismatch' | translate }}</mat-error>
      } @if (confirmPassword.hasError('required')) {
      <mat-error>{{ 'global.errors.fieldRequired' | translate }}</mat-error>
      }
    </mat-form-field>
  </div>
  <div mat-dialog-actions>
    @if (from === 'profile') {
    <button mat-flat-button type="button" (mousedown)="onCancel()">{{ 'global.cancel' | translate }}</button>
    }
    <button mat-flat-button color="accent" type="submit" data-cy="submit-password" [disabled]="resetForm.invalid">
      {{ 'global.OK' | translate }}
    </button>
  </div>
</form>
