import { Directive, OnDestroy, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { UrlTree } from '@angular/router';
import { Observable, Subscription } from 'rxjs';


@Directive()
export abstract class BaseStructuralDirective implements OnInit, OnDestroy {

  private sub: Subscription;
  private hasView: boolean;

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
  ) {}

  abstract validationCondition(): Observable<boolean | UrlTree>;

  ngOnInit(): void {
    this.sub = this.validationCondition().subscribe((condition: boolean | UrlTree) => {
      if (condition == true && !this.hasView) {
        this.viewContainer.createEmbeddedView(this.templateRef);
        this.hasView = true;
      } else if ((!condition  || condition instanceof UrlTree) && this.hasView) {
        this.viewContainer.clear();
        this.hasView = false;
      }
    });
  }

  ngOnDestroy(): void {
    this.sub?.unsubscribe();
  }
}