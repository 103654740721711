import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-display-image',
  templateUrl: './display-image.component.html',
  styleUrls: ['./display-image.component.scss'],
})
export class DisplayImageComponent {
  public imgURL: string | ArrayBuffer;

  constructor(
    private selfRef: MatDialogRef<DisplayImageComponent>,
    @Inject(MAT_DIALOG_DATA) private data: { url: string }
  ) {
    this.imgURL = this.data.url;
  }

  public closeImageViewer(): void {
    this.selfRef.close();
  }
}
