import { Injectable } from '@angular/core';

import { DiligentApiService } from 'src/app/_shared/services/diligent-api.service';

@Injectable()
export class PowerQualityMeasuresService {
  constructor(private diligent: DiligentApiService) {}

  async getPowerQualityMeasures(mpId: number): Promise<any> {
    return this.diligent.getPowerQualityMeasures(mpId);
  }
}
