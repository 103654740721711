import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { switchMap, map } from 'rxjs/operators';
import { forkJoin, of } from 'rxjs';

import { RecommendationsService } from 'src/app/_shared/services';
import { RecommendationActions } from 'src/app/_store/_recommendation/actions';

@Injectable()
export class RecommendationEffects {
  getRecommendationCount$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RecommendationActions.getRecommendationCount.type),
      switchMap((action) =>
        forkJoin([this.recommendationService.count(action.payload.mpId, action.payload.year), of(action.payload.year)])
      ),
      map(([count, year]) => RecommendationActions.getRecommendationCountSuccess({ payload: { count, year: year } }))
    )
  );

  getRecommendation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RecommendationActions.getRecommendation.type),
      switchMap((action) => this.recommendationService.getRecomendation(action.payload.mpId)),
      map((recommendations) => RecommendationActions.getRecommendationSuccess({ payload: { recommendations } }))
    )
  );

  constructor(
    private actions$: Actions<RecommendationActions.RecommendationActionsUnion>,
    private recommendationService: RecommendationsService
  ) {}
}
