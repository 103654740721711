import { MatDialogRef } from '@angular/material/dialog';

export function matDialogFocus(dialogRef: MatDialogRef<any>): void {
  const cdkOverlayWrappers = Array.from(
    document.getElementsByClassName('cdk-global-overlay-wrapper') as HTMLCollectionOf<HTMLElement>
  );

  for (let i = 0; i < cdkOverlayWrappers.length; i++) {
    const wrapper = cdkOverlayWrappers[i];

    if (wrapper.contains(dialogRef['_containerInstance']['_elementRef'].nativeElement)) {
      wrapper.style.zIndex = '5000';
      break;
    }
  }
}
