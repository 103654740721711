import { createAction, props } from '@ngrx/store';
import { Features, BannersFeature } from 'src/app/_shared/classes/features';

// Action to load /features on init
export const initFeatures = createAction('[App Init] Init Features');
export const getFeaturesSuccess = createAction('[App Init] Get Features Success', props<{ payload: Features }>());

/**
 * Function used to enable/disable banner in the store
 */
export const setFeatureBannerVisibility = createAction(
  '[Banners] Set banner visibility',
  props<{ payload: { [K in keyof BannersFeature]?: boolean } }>()
);

export type FeaturesActionsUnion = ReturnType<
  typeof initFeatures | typeof getFeaturesSuccess | typeof setFeatureBannerVisibility
>;
