// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

const diligent_endpoint_baseurl = 'https://staging.api.powerside.com';

export const environment = {
  production: false,
  pwa: false,
  psl: {
    diligent_endpoint_baseurl,
    diligent_endpoint: `${diligent_endpoint_baseurl}/v1`,
    token_refresh_threshold: 60 * 5,
    inactivity_timeout: 60 * 15,
    inactivity_warning_threshold: 60 * 1,
  },
  google: {
    reCaptchaSiteKey: '6LdvMswZAAAAAEnXVaqJUpw3xf1l84yUckbq73ZZ',
  },
  tinymce: 'c2xas82qyh3057wvef703mquprolmtyf50q995j96ekliceq',
  logErrors: true,
};

import 'zone.js/plugins/zone-error'; // Included with Angular CLI.
