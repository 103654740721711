export class CustomChartTypes {
  static get customChartTypes(): {[id: string]: string[]} {
    return {
      'energy-power.energy.active': ['activeEnergy'],
      'energy-power.energy.reactive': ['reactiveEnergy'],
      'energy-power.power.active': ['activePower'],
      'energy-power.power.reactive': ['reactivePower'],
      'energy-power.power.factor': ['powerFactor'],
      'power-quality.voltage-flux-lc.vrms-l-n': [
        'voltageL1N',
        'voltageL2N',
        'voltageL3N',
      ],
      'power-quality.voltage-flux-lc.vrms-l-l': [
        'voltageL1L2',
        'voltageL2L3',
        'voltageL3L1',
      ],
      'power-quality.voltage-flux-lc.neutral-earth-voltage': ['voltageNE'],
      'power-quality.voltage-flux-lc.flicker-pinst': ['flickerPinst'],
      'power-quality.voltage-flux-lc.flicker-pst': ['flickerPst'],
      'power-quality.voltage-flux-lc.flicker-plt': ['flickerPlt'],
      'power-quality.harmonic-distortion.thd': ['totalHarmonicDistortion'],
      'power-quality.harmonic-distortion.tdd': ['totalDemandDistortion'],
      'power-quality.harmonic-distortion.voltage-odd': ['voltageOddHarmonics'],
      'power-quality.harmonic-distortion.current-odd': ['currentOddHarmonics'],
      'power-quality.unbalance.voltage-negative-unbalance': ['negativeVoltage'],
      'power-quality.unbalance.current-negative-unbalance': ['negativeCurrent'],
      'power-quality.unbalance.voltage-zero-unbalance': [
        'voltageZeroSequenceUnbalance',
      ],
      'power-quality.unbalance.current-zero-unbalance': [
        'currentZeroSequenceUnbalance',
      ],
      'power-quality.currents.load-currents': [
        'currentL1',
        'currentL2',
        'currentL3',
      ],
      'power-quality.currents.neutral-current': ['neutralCurrent'],
      'power-quality.currents.ground-current': ['groundCurrent'],
      'power-quality.frequency.frequency': ['frequency'],
      'power-quality-advanced.harmonics.voltage-advanced': [
        'voltageAdvancedHarmonics',
      ],
      'power-quality-advanced.harmonics.current-advanced': [
        'currentAdvancedHarmonics',
      ],
      'power-quality-advanced.interharmonics.voltage-advanced': [
        'voltageAdvancedInterharmonics',
      ],
      'power-quality-advanced.interharmonics.current-advanced': [
        'currentAdvancedInterharmonics',
      ],
    };
  }
}
