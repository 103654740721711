import { EditorModule } from '@tinymce/tinymce-angular';
import { NgModule } from '@angular/core';

import { EditorDirective } from 'src/app/_shared/editor/editor.directive';
import { EditorImageUploadComponent } from './editor-image-upload/editor-image-upload.component';
import { SharedModule } from 'src/app/_shared/shared.module';
import { MatModule } from 'src/app/_shared/mat.module';

@NgModule({
  declarations: [EditorDirective, EditorImageUploadComponent],
  imports: [MatModule, SharedModule, EditorModule],
  exports: [EditorDirective, EditorModule],
})
export class TinyEditorModule {}
