<h1 mat-dialog-title>
  {{ 'login.forgot-password.title' | translate }}
</h1>
<form (submit)="submitForm($event)" [formGroup]="requestForm" >
  <div mat-dialog-content>
    <p>{{ 'login.forgot-password.copy' | translate }}</p>
    <mat-form-field appearance="fill">
      <mat-label>{{ 'login.email' | translate }}</mat-label>
      <input matInput type="email" data-cy='email' formControlName="email"/>
      @if (email.hasError('email')) {
        <mat-error>{{
          'login.forgot-password.email-error' | translate
        }}</mat-error>
      }
      @if (email.hasError('required')) {
        <mat-error>{{
          'global.errors.fieldRequired' | translate
        }}</mat-error>
      }
      @if (requestForm.get('email').errors?.invalid) {
        <mat-error>{{
          'login.forgot-password.reset-not-sent' | translate
        }}</mat-error>
      }
    </mat-form-field>
  </div>
  <div mat-dialog-actions>
    <button mat-flat-button type="button" (click)="onCancel()">
      {{ 'global.cancel' | translate }}
    </button>
    <button mat-flat-button color="accent" type="submit" data-cy='forgot-psw-submit' [disabled]="requestForm.invalid">
      {{ 'global.OK' | translate }}
    </button>
  </div>
</form>
