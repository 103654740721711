export abstract class TransactionTable {

  protected api: any;
  protected columnApi: any;
  invalid: boolean = false;

  modifiedItem = {};
  valueChanged: boolean = false;

  gridReady(event: any): void {
    this.api = event.api;
    this.columnApi = event.columnApi;
  }

  getRowStyle = (param): { [id: string]: string } => {
    if (this.modifiedItem[param.rowIndex]) {
      return { background: 'azure' };
    }
  };

  onCellValueChanged(event: any): void {
    this.valueChanged = true;
    this.modifiedItem[`${event.node.rowIndex}-${event.column.colId}`] = {data: event, isValid: this.validateCell(event)};
    this.invalid = !this.validateGrid();
    this.api.redrawRows({ rowNodes: [event.node] });
  }

  validateGrid(): boolean {
    for (const key of Object.keys(this.modifiedItem)) {
      if (!this.modifiedItem[key].isValid) return false;
    }
    return true;
  }

  abstract validateCell(param: any): boolean;
}