import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dashValue',
})
export class DashValuePipe implements PipeTransform {
  transform(value: string | number, ...args: any[]): string | number {
    if (!value || args.indexOf(true) !== -1) {
      return '-';
    } else {
      return value;
    }
  }
}
