<section>
  <h1 class="title">
    {{ title | translate }}
  </h1>
  <button mat-icon-button class="close-button" type="button" (click)="closeDialog()">
    <mat-icon [svgIcon]="'close'"></mat-icon>
  </button>
  <h4 class="account-info" data-cy="accountNameDeleteMember">
    {{ accountName }}
  </h4>
  <div class="confirm-message">
    @if (member) {
      <div>
        {{ 'manageMembers.deleteConfirm' | translate : { firstName: member.firstName, lastName: member.lastName } }}
      </div>
    }
    @if (mp && !unassociate) {
      <div>
        {{ 'fleet.deleteConfirm' | translate : { mp: mp.mpId } }}
      </div>
    }
    @if (mp && unassociate) {
      <div>
        {{ 'fleet.unassociateConfirm' | translate : { mp: mp.mpId } }}
      </div>
    }
    <div class="member-button">
      @if (!unassociate) {
        <button
          data-cy="delete-button"
          mat-flat-button
          color="accent"
          type="button"
          (click)="deleteMember()"
          >
          {{ 'global.delete' | translate }}
        </button>
      }
      @if (unassociate) {
        <button
          mat-flat-button
          color="accent"
          type="button"
          (click)="deleteMember()"
          data-cy="disassociate-confirm"
          >
          {{ 'global.disassociate' | translate }}
        </button>
      }
      <button mat-flat-button type="button" (click)="closeDialog()" data-cy="cancelDeleteMember">
        {{ 'global.cancel' | translate }}
      </button>
    </div>
  </div>
</section>
