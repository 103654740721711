import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'showFiltersName',
})
export class ShowFiltersNamePipe implements PipeTransform {
  constructor() {}

  transform(value: any, ...args: any[]): string {
    if (value) {
      return value.filter((type) => type.name !== 'selectAll').map((type) => ' ' + type.name);
    } else {
      return '';
    }
  }
}
