import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map } from 'rxjs';

import { MapActions } from 'src/app/_store/_map/actions';
import { UserActions } from 'src/app/_store/_user/actions';

@Injectable()
export class MapEffects {
  setZoom$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MapActions.setZoomFactor.type),
      map((action) => {
        return UserActions.updateUserPreferences({ payload: { property: { zoomFactor: action.payload.zoomFactor } } });
      })
    )
  );

  setCenter$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MapActions.setMapCenter.type),
      map((action) => {
        return UserActions.updateUserPreferences({ payload: { property: { mapCenter: action.payload.center } } });
      })
    )
  );

  constructor(private actions$: Actions<MapActions.MapActionsUnion>) {}
}
