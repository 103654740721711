import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { AgGridColumn } from 'ag-grid-angular';
import { Column, GridOptions } from 'ag-grid-community';
import { forkJoin, Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

import { TransactionTable } from 'src/app/_shared/ag/class/transactional-table';
import { MpParameters, ParameterValue } from 'src/app/_shared/interface/mp-parameter';
import { MeasurementPointsService } from 'src/app/_shared/services/measurement-points.service';
import * as fromUser from 'src/app/_store/_reducers';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-site-mp-parameters-table',
  templateUrl: './site-mp-parameters-table.component.html',
  styleUrls: ['./site-mp-parameters-table.component.scss'],
})
export class SiteMpParametersTableComponent extends TransactionTable implements OnInit, AfterViewInit {
  @Input() mpId: number;
  @Input() mpTypeId: number;
  @Input() viewerMode: boolean;
  @Input() status: string;
  @Input() defaultEditParameterName: string;

  private isShowEmptyValue: boolean = false;

  data: Observable<[string, ParameterValue][]>;
  gridOptions: GridOptions;

  defaultColDef: Partial<AgGridColumn> = {
    resizable: false,
    cellStyle: { border: 'none' },
    suppressNavigable: true,
  };

  columnDefs: Partial<AgGridColumn>[] = [
    {
      headerName: 'Title',
      sortable: true,
      filter: true,
      flex: 1,
      minWidth: 100,
      valueGetter: (value): string => value.data[1].title.en,
    },
    {
      headerName: 'Description',
      flex: 1,
      minWidth: 100,
      valueGetter: (value): string => value.data[1].description.en,
    },
    {
      colId: 'value',
      headerName: 'Value',
      width: 110,
      valueGetter: (value): string => (this.isShowEmptyValue && !this.canModify ? '' : value.data[1].value),
      valueSetter: (param): boolean => {
        if (param.data[1].editable) {
          param.data[1].value = param.newValue;
          return true;
        }
        return false;
      },
      cellClassRules: {
        'mp-parameters-invalid': (param): boolean => {
          return !this.validateCell(param);
        },
        'mp-parameters-disable': (param): boolean => {
          return !param.data[1].editable;
        },
      },
    },
    {
      headerName: 'Unit',
      width: 110,
      valueGetter: (value): string => value.data[1].unit,
    },
  ];

  context: {
    formGroup: FormGroup;
    createKey: (rowId: string, column: Column) => string;
  };

  getRowStyle = (param: any): any => {
    if (!param.data[1].editable) {
      return { background: 'grey' };
    }
  };

  get parameters(): MpParameters {
    if (!this.canModify) return null;
    const content = {};
    this.api.forEachNode(
      (rowNode) =>
        (content[rowNode.data[0]] = {
          value: rowNode.data[1].value,
        })
    );
    return {
      measurementPointId: this.mpId.toString(),
      content,
    };
  }

  canModify: boolean;

  constructor(
    private mpService: MeasurementPointsService,
    private store: Store<fromUser.State>,
    private translateService: TranslateService
  ) {
    super();
  }
  ngAfterViewInit(): void {
    if (this.defaultEditParameterName) {
      this.data.pipe(take(1)).subscribe((data) => {
        const index = data.findIndex((param) => param[0] === this.defaultEditParameterName);

        if (index !== -1) {
          this.api.startEditingCell({
            rowIndex: index,
            colKey: 'value',
          });
        }
      });
    }
  }

  ngOnInit(): void {
    const mpStatus: string = isNaN(parseInt(this.status))
      ? this.status
      : this.translateService.instant(`measurementPoint.status.${this.status}`); //status can be an id(8) or name(Commissioned)

    forkJoin([
      this.store.pipe(select(fromUser.getAccount)).pipe(take(1)),
      this.store.pipe(select(fromUser.getIsSysAdmin)).pipe(take(1)),
    ]).subscribe(([account, isSysAdmin]) => {
      if (
        //no view/edit for pending installation and archived states
        mpStatus === this.translateService.instant('measurementPoint.status.1') ||
        mpStatus === this.translateService.instant('measurementPoint.status.9')
      ) {
        this.isShowEmptyValue = true;
      }

      this.canModify =
        (isSysAdmin ||
          (((account.isPartner === 1 && this.mpTypeId !== 2) || (account.isPartner !== 1 && this.mpTypeId === 1)) &&
            !this.viewerMode)) &&
        !this.viewerMode &&
        !this.isShowEmptyValue;
      this.columnDefs[2].editable = this.canModify;
      this.columnDefs[2].suppressNavigable = !this.canModify;
      if (this.canModify) {
        this.columnDefs[2].cellStyle = null;
      }
      this.gridOptions = {
        rowClassRules: {
          'row-disabled': (param) => !this.canModify,
        },
      };
    });

    this.context = {
      formGroup: new FormGroup({}),
      createKey: (rowId: string, column: Column): string => {
        return `${rowId}${column.getColId()}`;
      },
    };

    this.data = this.mpService.getMpParameters(this.mpId).pipe(map((item) => Object.entries(item.content)));
  }

  validateCell(param: any): boolean {
    return RegExp(param.data[1].validationRegEx).test(param.data[1].value);
  }
}
