import { ErrorHandler, Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  handleError(error: any): void {
    if (environment.logErrors === true) {
      console.log('GlobalErrorHandler', error);
    }

    const chunkFailedMessage = /Loading chunk .* failed/;
    if (chunkFailedMessage.test(error.message)) {
      alert('An error has occurred: press ok to reload the page.');
      window.location.reload();
    }
  }
}
