import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { NotificationsService } from 'src/app/_shared/modules/notifications/shared/notifications.service';

@Injectable({
  providedIn: 'root',
})
export class UnauthorizedService {
  constructor(private notificationsService: NotificationsService, private router: Router) {}

  notifyWrongIdAccount(): Observable<void> {
    return this.notificationsService
      .alert(
        'Your log in account privileges do not allow you to view this information. You might be using multiple accounts. Please logout and login using the appropriate user account.'
      )
      .afterClosed()
      .pipe(
        map((x) => {
          this.router.navigate(['dashboard']);
          return x;
        })
      );
  }
}
