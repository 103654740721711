import { createAction, props } from '@ngrx/store';

import { DrivescanDashboard } from 'src/app/_shared/interface/drivescan/drivescan-dashboard.interface';
import { EventCountReducer } from 'src/app/_shared/interface/events/event-count-reducer.interface';
import { DriveFaultState } from 'src/app/_shared/interface/drivescan/drive-fault-code.interface';

export const startPollingValue = createAction(
  '[Drivescan-Dashboard] Start Polling Values',
  props<{ payload: { mpId: string; driveInfoModel: string } }>()
);

export const setValueSucces = createAction(
  '[Drivescan-Dashboard] Set Values Success',
  props<{ payload: { drivescanValue: DrivescanDashboard } }>()
);

export const setEventsSucces = createAction(
  '[Drivescan-Dashboard] Set Events Success',
  props<{ payload: { drivescanEvents: EventCountReducer } }>()
);

export const setEventsChannelsSucces = createAction(
  '[Drivescan-Dashboard] Set Events Channels Success',
  props<{ payload: { drivescanEventsChannels: DrivescanDashboard } }>()
);

export const setDriveFaultStateSucces = createAction(
  '[Drivescan-Dashboard] Set DriveFaultState Success',
  props<{ payload: { driveFaultState: DriveFaultState } }>()
);

export const setHeartbeatSucces = createAction(
  '[Drivescan-Dashboard] Set Heartbeat Success',
  props<{ payload: { heartbeat: any } }>()
);

export const keepPolling = createAction('[Drivescan-Dashboard] Keep calling API every min');

export const polling1Min = createAction('[Drivescan-Dashboard] Call API every minute');

export const polling10Min = createAction('[Drivescan-Dashboard] Call API every 10 minutes');

export const polling1MinEvents = createAction('[Drivescan-Dashboard] Call Events API every minute');

export const polling1MinEventsChannels = createAction('[Drivescan-Dashboard] Call Events Channels every minute');

export const polling1MinDriveFault = createAction('[Drivescan-Dashboard] Call DriveFault API every minute');

export const polling1MinHeartbeat = createAction('[Drivescan-Dashboard] Call Heartbeat API every minute');

export const setValueFail = createAction('[Drivescan-Dashboard] Set Values Fail', props<{ error: any }>());

export const destroyedDasboard = createAction('[Drivescan-Dashboard] Destroyed Dashboard');

export type DrivescanDashboardActionsUnion = ReturnType<
  | typeof startPollingValue
  | typeof setValueSucces
  | typeof setEventsSucces
  | typeof setEventsChannelsSucces
  | typeof setDriveFaultStateSucces
  | typeof setHeartbeatSucces
  | typeof keepPolling
  | typeof polling1Min
  | typeof polling10Min
  | typeof polling1MinEvents
  | typeof polling1MinEventsChannels
  | typeof polling1MinDriveFault
  | typeof polling1MinHeartbeat
  | typeof setValueFail
  | typeof destroyedDasboard
>;
