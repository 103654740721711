import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'DST',
})
export class DSTPipe implements PipeTransform {
  transform(value: string, ...args: any[]): any {
    if (value) {
      let momentValue = moment(value);
      if (args[1]) {
        momentValue = momentValue.tz(args[1]);
      }

      switch (args[0]) {
        case 'LTSS':
          return momentValue.format(moment().creationData().locale.longDateFormat('LTS').replace('ss', 'ss .SSS'));
        case 'LLT':
          return `${momentValue.format(moment().creationData().locale.longDateFormat('L'))} ${momentValue.format(
            moment().creationData().locale.longDateFormat('LT')
          )}`;
        case 'LLTS':
          return `${momentValue.format(moment().creationData().locale.longDateFormat('L'))} ${momentValue.format(
            moment().creationData().locale.longDateFormat('LTS')
          )}`;
        case 'LLTSYS':
          return `${momentValue.format(
            moment().creationData().locale.longDateFormat('L').replace('YYYY', 'YY')
          )} ${momentValue.format(moment().creationData().locale.longDateFormat('LT'))}`;
        default:
          break;
      }

      return momentValue.format(args[0]);
    }
  }
}
