import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { take } from 'rxjs';
import { MatTableDataSource } from '@angular/material/table';
import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { DiligentApiService } from 'src/app/_shared/services';

@Component({
  selector: 'app-mp-access',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './mp-access.component.html',
  styleUrl: './mp-access.component.scss',
})
export class MpAccessComponent implements OnInit {
  @Input() mpId: number;
  /**
   * List of access changes that will be saved onf form submit.
   * User Id is the key and the value is the boolean info if users has access
   */
  @Output() accessChangeEvent = new EventEmitter();

  accessChanges: { [key: string]: boolean } = {};

  loading: boolean;

  userAccessTable = {
    columnsDefinition: ['firstName', 'email', 'role', 'access'],
    dataSource: null,
    sortActive: 'firstName',
    sortDirection: 'asc',
  };

  paginatorData = {
    offset: 0,
    totalRecords: 0,
    pageSize: 10,
    pageSizeOptions: [10, 20, 40, 50, 100],
  };
  constructor(private diligentService: DiligentApiService, private changeDetection: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.loadUsers();
  }

  loadUsers(): void {
    this.loading = true;
    this.diligentService
      .getUsersAccessByMp(
        this.mpId,
        this.paginatorData.offset,
        this.paginatorData.pageSize,
        this.userAccessTable.sortActive,
        this.userAccessTable.sortDirection === 'asc'
      )
      .pipe(take(1))
      .subscribe((data) => {
        // Update table data source
        this.userAccessTable.dataSource = new MatTableDataSource(data.records);
        // Update paginator data
        this.paginatorData.totalRecords = data.totalRecordCount;
        // Force change detection for paginator and table to refresh properly
        this.changeDetection.detectChanges();
        this.loading = false;
      });
  }

  onPageChange(event: PageEvent): void {
    this.paginatorData.pageSize = event.pageSize;
    this.paginatorData.offset = event.pageIndex * this.paginatorData.pageSize;
    this.loadUsers();
  }
  onTableSort(event: Sort): void {
    if (event.direction !== '') {
      this.userAccessTable.sortActive = event.active;
      this.userAccessTable.sortDirection = event.direction;
      this.loadUsers();
    }
  }

  onCheckboxClick(userId: number, checked: boolean): void {
    this.accessChanges[userId] = checked;
    this.accessChangeEvent.emit(this.accessChanges);
  }
}
