import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';

import { ChannelsActions } from 'src/app/_store/_channels/actions';
import { MeasurementPointsService } from 'src/app/_shared/services/measurement-points.service';
import { UserActions } from 'src/app/_store/_user/actions';

@Injectable()
export class ChannelsEffects {
  setAllChannels$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ChannelsActions.setAllChannels.type),
      switchMap((action) => {
        return [
          ChannelsActions.setMeterChannels({
            payload: { mpId: action.payload.mpId, mpTypeId: action.payload.mpTypeId },
          }),
          ChannelsActions.setTrendsChannels({
            payload: { mpId: action.payload.mpId, mpTypeId: action.payload.mpTypeId },
          }),
        ];
      })
    )
  );

  setMeterChannels$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ChannelsActions.setMeterChannels.type),
      switchMap((action) => {
        return this.mpService.getChannelDefinition(action.payload.mpId, 'live', action.payload.mpTypeId).pipe(
          switchMap((channels) => {
            return [
              ChannelsActions.setMeterChannelsSuccess({
                payload: { meterChannels: channels.channels },
              }),
              UserActions.setQubescanTypeModel({
                payload: {
                  type: channels.powerConfiguration ? channels.powerConfiguration : null,
                  model: channels.pqubeModel ? channels.pqubeModel : null,
                },
              }),
            ];
          }),
          catchError((error) => of(ChannelsActions.setChannelsFail({ error })))
        );
      })
    )
  );

  setTrendsChannels$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ChannelsActions.setTrendsChannels.type),
      switchMap((action) => {
        return this.mpService.getChannelDefinition(action.payload.mpId, 'trend', action.payload.mpTypeId).pipe(
          switchMap((channels) => {
            return [
              ChannelsActions.setTrendsChannelsSuccess({
                payload: { trendsChannels: channels.channels },
              }),
              UserActions.setQubescanTypeModel({
                payload: {
                  type: channels.powerConfiguration ? channels.powerConfiguration : null,
                  model: channels.pqubeModel ? channels.pqubeModel : null,
                },
              }),
            ];
          }),
          catchError((error) => of(ChannelsActions.setChannelsFail({ error })))
        );
      })
    )
  );

  setTrendsChannelsSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ChannelsActions.setTrendsChannelsSuccess.type),
      switchMap((action) => {
        if (action.payload.mp) {
          return [
            UserActions.getMpSuccess({
              payload: {
                mp: action.payload.mp,
                returnUrl: action.payload.returnUrl,
                from: 'channel',
              },
            }),
          ];
        } else {
          return [ChannelsActions.initTrendsChannelsSuccess()];
        }
      })
    )
  );

  constructor(
    private actions$: Actions<ChannelsActions.ChannelsActionsUnion>,
    private mpService: MeasurementPointsService
  ) {}
}
